import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { type QueryOptionsType } from '@/generated/api/type/api';
import { type WoSelfAddressesGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  options?: QueryOptionsType<WoSelfAddressesGetRes>;
}

export function useFetchAddressSelection({ options }: Props) {
  const getAddresses = async () => {
    return await get('/api/commerce/v1/self/user-addresses').then(res => res.data);
  };

  return useQuery({
    queryKey: ['useFetchAddressSelection'],
    queryFn: getAddresses,
    staleTime: 0,
    ...options,
  });
}
