import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { AnalyticsProvider } from '@/providers/analytics-provider';
import { CookiesProvider } from '@/providers/cookies-provider';
import { NetworkProvider } from '@/providers/network-provider';
import { ReactQueryProvider } from '@/providers/react-query-provider';
import { RecoilProvider } from '@/providers/recoil-provider';
import { StyleProvider } from '@/providers/style-provider';
import reportWebVitals from '@/reportWebVitals';
import router from '@/route';

/**
 * 상시몰 help-sidebar-desktop.tsx
 */
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <RecoilProvider>
    <CookiesProvider>
      <ReactQueryProvider>
        <StyleProvider>
          <NetworkProvider>
            <AnalyticsProvider>
              <RouterProvider router={router} />
            </AnalyticsProvider>
          </NetworkProvider>
        </StyleProvider>
      </ReactQueryProvider>
    </CookiesProvider>
  </RecoilProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
