import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { WoReviewSpecGetRes } from '@/generated/api/type/data-contracts';

export function useFetchReviewSpecs() {
  const getList = async () => {
    return await get('/api/commerce/v1/specs/review').then(res => res.data as WoReviewSpecGetRes);
  };
  return useQuery({ queryFn: getList, queryKey: ['useFetchReviewSpecs'] });
}
