import { useNavigate } from 'react-router-dom';

import totalIc from '@/assets/ic/chevron-right.svg';

interface NavigateBtnProps {
  path: string;
}
export const NavigateBtn = ({ path }: NavigateBtnProps) => {
  const navigate = useNavigate();
  return (
    <div className="list-action">
      <button type="button" className="btn btn-md btn-outline" onClick={() => navigate(path)}>
        전체보기
        <img src={totalIc} alt="navigate" />
      </button>
    </div>
  );
};
