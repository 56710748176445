import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutationPasswordConfirm } from '@/apis/friendly-pharmacist/user/useMutationPasswordConfirm';
import { PATH } from '@/constants/path';

interface ResetPasswordFormProps {
  uuid: string;
  token: string;
  setAlertState: Dispatch<
    SetStateAction<{
      isAlertVisible: boolean;
      header?: string[];
      body: string[];
      buttons: [{ title: string; handleClick: () => void; style: string }];
    }>
  >;
  deleteSearchParams: () => void;
  closeAlert: () => void;
}

export const ResetPasswordForm = ({
  uuid,
  token,
  setAlertState,
  deleteSearchParams,
  closeAlert,
}: ResetPasswordFormProps) => {
  const navigate = useNavigate();
  const [hasPwdEng, setHasPwdEng] = useState(false);
  const [hasPwdNum, setHasPwdNum] = useState(false);
  const [isPwdLengthValid, setIsPwdLengthValid] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const { mutate: mutatePasswordConfirm } = useMutationPasswordConfirm();

  const handlePasswordValidation = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;
    switch (name) {
      case 'pwd':
        const hasEng = /[a-zA-Z]/.test(value);
        const hasNum = /[0-9]/.test(value);
        const hasLength = value.length >= 8 && value.length <= 20;
        setHasPwdEng(hasEng);
        setHasPwdNum(hasNum);
        setIsPwdLengthValid(hasLength);
        setPassword(value);
        break;
      case 'pwdConfirm':
        setPasswordConfirm(value);
        break;
      default:
        break;
    }
  };
  const handleClickPasswordConfirm = () => {
    password === passwordConfirm &&
      hasPwdEng &&
      hasPwdNum &&
      isPwdLengthValid &&
      mutatePasswordConfirm(
        { uuid, token, password },
        {
          onSuccess: () => {
            setAlertState({
              isAlertVisible: true,
              header: ['비밀번호 재설정 완료'],
              body: ['비밀번호가 재설정 되었어요.', '확인을 클릭하면 로그인페이지로 이동해요.'],
              buttons: [
                {
                  title: '확인',
                  handleClick: () => navigate(PATH.LOGIN, { replace: true }),
                  style: 'primary',
                },
              ],
            });
          },
          onError: (error: any) =>
            setAlertState({
              isAlertVisible: true,
              header: ['비밀번호 재설정 실패'],
              body: [error?.response?.data.message ?? '처음부터 다시 시도해주세요.'],
              buttons: [
                {
                  title: '확인',
                  handleClick: () => {
                    deleteSearchParams();
                    closeAlert();
                  },
                  style: 'primary',
                },
              ],
            }),
        },
      );
  };
  return (
    <div className="login-contents-wrap">
      <div className="input-group">
        <div className="form-label">
          비밀번호<sup>*</sup>
        </div>
        <input
          type="password"
          className="form-control"
          placeholder="비밀번호 입력"
          value={password}
          onChange={handlePasswordValidation}
          name="pwd"
        />
        <div className="form-text">
          <span className={`password-check${hasPwdEng ? ' is-valid' : ''}`}>영문</span>
          <span className={`password-check${hasPwdNum ? ' is-valid' : ''}`}>숫자</span>
          <span className={`password-check${isPwdLengthValid ? ' is-valid' : ''}`}>8-20자</span>
        </div>
      </div>
      <div className="input-group">
        <div className="form-label">
          비밀번호 확인<sup>*</sup>
        </div>
        <input
          type="password"
          className={`form-control${password !== passwordConfirm ? ' has-validation' : ''}`}
          placeholder="비밀번호 입력"
          value={passwordConfirm}
          onChange={handlePasswordValidation}
          name="pwdConfirm"
          onKeyDown={e => e.key === 'Enter' && handleClickPasswordConfirm()}
        />
        {password !== passwordConfirm && (
          <div className="form-text">비밀번호와 비밀번호 확인이 다릅니다.</div>
        )}
      </div>
      <div className="action-group">
        <button
          type="button"
          className="btn btn-md btn-filled btn-block"
          onClick={handleClickPasswordConfirm}
          disabled={password !== passwordConfirm || !hasPwdEng || !hasPwdNum || !isPwdLengthValid}
        >
          확인
        </button>
      </div>
    </div>
  );
};
