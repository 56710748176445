import { Link } from 'react-router-dom';

import smallDeleteIc from '@/assets/ic/x-sm.svg';
import { useProduct } from '@/hooks/use-product';
import { numberFormat } from '@/utils/formats';

/**
 * 상품 상세 내 모바일 옵션, 데스크탑 상단, 우측 모두 사용
 * ContextAPI(productContext) 사용하여 props는 받지 않음
 */
export const SelectedOptionItem = () => {
  const { productContext, productDispatch } = useProduct();

  return (
    <ul className="selected-options">
      {productContext?.selectedOptions?.length !== undefined &&
        productContext?.selectedOptions.map((option, idx) => (
          <li key={`${option?.option_name ?? ''}${idx}`}>
            <div className="row">
              <div>{option.option_name?.split(',')[0]}</div>
              <div className="title">{option.option_name?.split(',')[1]}</div>
              <Link
                className="btn-delete"
                to="#"
                onClick={e => {
                  e.preventDefault();
                  productDispatch?.removeOption({
                    optionIdx: option?.product_option_idx ?? 0,
                  });
                }}
              >
                <img src={smallDeleteIc} alt={`${option.option_name?.split(',')[0]} delete`} />
              </Link>
            </div>
            <div className="row">
              <div className="stepper">
                <input
                  className="form-control stepper-input"
                  type="number"
                  onChange={() => {}}
                  value={option.optionCount}
                  min="1"
                  max="10"
                />
                {/* <!-- value 값이 최소/최대가 되면 button에 disabled 클래스가 추가됨 --> */}
                <button
                  id="decrease"
                  className={`value-control decrease${option.optionCount === 1 ? ' disabled' : ''}`}
                  disabled={option.optionCount === 1}
                  onClick={() => {
                    productDispatch?.optionCounter(option, 'decrease');
                  }}
                />
                <button
                  id="increase"
                  className={`value-control increase${
                    option.optionCount === 10 ? ' disabled' : ''
                  }`}
                  onClick={() => {
                    productDispatch?.optionCounter(option, 'increase');
                  }}
                  disabled={option.optionCount === 10}
                />
              </div>
              <div className="price">
                {(option.discount_influencer_rate || option.discount_rate) && (
                  <span className="discount-percent">
                    {option.discount_influencer_rate || option.discount_rate}%
                  </span>
                )}
                {numberFormat(
                  option?.discounted_influencer_price ??
                    option?.discounted_price ??
                    option?.sale_price,
                )}
              </div>
              <div className="unit">원</div>
            </div>
          </li>
        ))}
    </ul>
  );
};
