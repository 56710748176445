import { ChangeEvent, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '@components/common/molecules/modal/index';

import { useMutationInquiryModify } from '@/apis/commerce/boards/useMutationInquiryModify';
import { useFetchBoardSpecs } from '@/apis/commerce/specs/useFetchBoardSpecs';
import cameraIc from '@/assets/ic/camera.svg';
import deleteIc from '@/assets/ic/x-sm.svg';
import { PATH } from '@/constants/path';
import { WoBoardThreadArticle } from '@/generated/api/type/data-contracts';

interface InquiryModifyModalProps {
  toggleModal: () => void;
  qnaBoardTypeCd: string;
  option?: string;
  qnaText: string;
  threadData: WoBoardThreadArticle;
  refetchList: () => void;
  isOpen: boolean;
}

export const InquiryModifyModal = ({
  toggleModal,
  qnaBoardTypeCd,
  option,
  qnaText,
  threadData,
  refetchList,
  isOpen,
}: InquiryModifyModalProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownSelected, setDropdownSelected] = useState(threadData?.qna_type_name);
  const [notiYn, setNotiYn] = useState(false);
  const [textBox, setTextBox] = useState(qnaText);
  const [inputFile, setInputFile] = useState<File | undefined>(undefined);
  const [imgUrl, setImgUrl] = useState(threadData?.image_urls?.[0] ?? '');
  const { data: specData } = useFetchBoardSpecs({ options: { staleTime: 1000 * 60 * 60 } });
  const qnaTypeCd =
    specData?.board_qna_type_codes?.find(item => item.qna_type_name === dropdownSelected)
      ?.qna_type_cd ?? '';
  const { mutate: mutateModifyInquiry } = useMutationInquiryModify();

  const modalTitle =
    qnaBoardTypeCd === 'order'
      ? '주문/배송 문의'
      : qnaBoardTypeCd === 'product'
      ? '상품 문의'
      : '1:1 문의';

  const toggleDropdown = () => {
    setIsDropdownOpen(pre => !pre);
  };
  const handleChangeTextBox = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.currentTarget.value.length <= 1000) {
      setTextBox(e.currentTarget.value);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        setInputFile(file);
        setImgUrl(URL.createObjectURL(file));
      }
      // else {
      //   alert('JPG 또는 PNG 파일만 업로드 가능합니다.');
      // }
    }
  };
  const handleRemoveImg = () => {
    setInputFile(undefined);
    setImgUrl('');
  };

  const previewUrl = inputFile ? URL.createObjectURL(inputFile) : threadData?.image_urls?.[0] ?? '';

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={modalTitle}
      btns={[
        {
          title: '수정',
          handleClick: () =>
            mutateModifyInquiry(
              {
                qnaBoardTypeCd,
                qnaIdx: threadData?.cs_qna_idx ?? 0,
                form: {
                  openYn: 'N',
                  qnaTypeCd: qnaTypeCd,
                  qnaText: textBox,
                  notiAnswerYn: notiYn ? 'Y' : 'N',
                  file: inputFile,
                  removeImgUrl: inputFile ? threadData?.image_urls?.[0] : '',
                },
              },
              {
                onSuccess: () => {
                  refetchList();
                  toggleModal();
                },
              },
            ),
          disabled: false,
        },
      ]}
    >
      <div className="row main">
        {/* <!-- NOTI: form-title 클래스 추가 --> */}
        {qnaBoardTypeCd === 'one_on_one' && (
          <div className="form-title">
            자주하는 질문에서 찾지 못하셨나요?
            <br />
            1:1 문의를 이용해 주세요.
          </div>
        )}
        {/* <!-- 문의 작성 --> */}
        <div className="dropdown">
          {/* <!-- 드롭다운을 눌렀을 때 menu-open 클래스 붙여 스타일 변경함 --> */}
          <button
            className={`btn btn-outline btn-md dropdown-toggle${
              isDropdownOpen ? ' menu-open' : ''
            }`}
            onClick={toggleDropdown}
          >
            {dropdownSelected}
          </button>
          {/* <!-- show 클래스를 붙여 드롭다운 메뉴를 표시함 --> */}
          <ul className={`dropdown-menu${isDropdownOpen ? ' show' : ''}`}>
            {specData?.board_qna_type_codes?.map(item => (
              <li key={`dropdown_${item?.qna_type_name}`}>
                <Link
                  className={`dropdown-item${
                    dropdownSelected === item?.qna_type_name ? ' active' : ''
                  }`}
                  to="#"
                  onClick={() => {
                    setDropdownSelected(item?.qna_type_name ?? '');
                    toggleDropdown();
                  }}
                >
                  {item?.qna_type_name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {/* <!-- 문의 내용 --> */}
        {qnaBoardTypeCd === 'one_on_one' && (
          <div className="input-group">
            <div className="form-label">
              문의 내용<sup>*</sup>
            </div>
          </div>
        )}
        <div className="input-group">
          <textarea
            className="form-control"
            placeholder="최소 10자 이상 작성"
            value={textBox}
            onChange={handleChangeTextBox}
          />
          <div className="view-words">
            <span className="value">{textBox.length}</span>/1000
          </div>
        </div>
        {/* 1:1 문의인경우에만 파일첨부 */}
        {qnaBoardTypeCd === 'one_on_one' && (
          <div className="input-group">
            {imgUrl ? (
              <div className="img-uploaded">
                <img src={previewUrl} alt="preview" />
                <Link className="btn-delete" to="#" onClick={handleRemoveImg}>
                  <img src={deleteIc} alt="delete" />
                </Link>
              </div>
            ) : (
              <button
                type="button"
                className="btn btn-md btn-outline btn-icon-only"
                onClick={triggerFileInput}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                <img src={cameraIc} alt="add" />
              </button>
            )}
          </div>
        )}
      </div>
      {qnaBoardTypeCd === 'one_on_one' && (
        <>
          <div className="row caution">
            <span className="with-icon">사진은 최대 1장만 가능합니다.</span>
          </div>
          <div className="row">
            <div className="input-group">
              <div className="form-label">
                휴대폰
                {/*
                // TODO
                <Link className="link" to={PATH.MY_INFO}>
                  정보수정 &gt;
                </Link> */}
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="제목 입력"
                // TODO: phone number
                value="010-1234-****"
                disabled
              />
              <label className="checkbox-group green-box sm-box">
                <input
                  type="checkbox"
                  name="test-2"
                  checked={notiYn}
                  onChange={() => setNotiYn(pre => !pre)}
                />
                <span className="label">답변시 알림톡 받기</span>
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
        </>
      )}
      <div className="row caution">
        <span className="with-icon">
          문의하신 내용에 대한 답변은 내 정보 &gt; <Link to={PATH.MY_INQUIRY}>나의 문의 관리</Link>
          에서 확인하실 수 있습니다.
        </span>
      </div>
    </Modal>
  );
};
