import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

export function useMutationRegisterSocial({ onSuccess }: { onSuccess?: (data: any) => void }) {
  const register = async ({
    email,
    snsId,
    passToken,
    agreements,
  }: {
    email: string;
    snsId: string;
    passToken: string;
    agreements: {
      sms: boolean;
      email: boolean;
      push: boolean;
    };
  }) => {
    const response = await post('/user/signup/social', {
      provider: 'kakao',
      email,
      sns_id: snsId,
      pass_token: passToken,
      agreements,
    });
    return { response: response.data, snsId };
  };

  return useMutation({
    mutationFn: register,
    onSuccess: data => {
      onSuccess && onSuccess(data);
    },
    onError: error => {
      console.log('Mutation failed:', error);
    },
  });
}
