import { Link, useNavigate } from 'react-router-dom';

import badgeBest from '@/assets/ic/badge-best.svg';
import badgeNew from '@/assets/ic/badge-new.svg';
import badgeRecent from '@/assets/ic/badge-recent.svg';
import { PATH } from '@/constants/path';
import { type WoProduct } from '@/generated/api/type/data-contracts';
import { numberFormat } from '@/utils/formats';

interface ProductItemProps {
  item: WoProduct;
}
export const ProductItem = ({ item }: ProductItemProps) => {
  const navigate = useNavigate();

  // badge 우선순위 new > best > recent
  const badgeImg =
    item?.new_yn === 'Y'
      ? badgeNew
      : item?.popularity_yn === 'Y'
      ? badgeBest
      : item?.user_sale_yn === 'Y'
      ? badgeRecent
      : '';

  const handleClickItem = (prdIdx: number) => {
    navigate(`${PATH.PRODUCT_DETAIL}/${prdIdx}`);
    window.scrollTo(0, 0);
  };

  return (
    <li>
      <Link
        to="#"
        onClick={() => {
          handleClickItem(item?.product_idx as number);
        }}
      >
        <div id="prod-item-default" className="product-item">
          <div className="thumbnail">
            <img alt="제품이미지" src={item?.main_image_url ?? ''} />
            {badgeImg.length > 0 && (
              <img className="thumbnail-badge" alt="제품이미지" src={badgeImg} />
            )}
            {item?.sold_out_yn === 'Y' && <div className="thumbnail-dim" />}
          </div>
          <div className="pricing">
            {item?.discount_rate !== null &&
              item?.discount_rate !== undefined &&
              item?.discount_rate > 0 && (
                <span className="discount-percent">{item?.discount_rate}%</span>
              )}

            <span>{numberFormat(item?.sale_price ?? 0)}</span>
            <span className="unit">원</span>
          </div>
          <p className="title">{item?.product_name ?? ''}</p>
          <div className="badge-group">
            {item?.shipping_fee !== undefined && item?.shipping_fee <= 0 && (
              <span className="badge">무료배송</span>
            )}
            <span className="badge">사은품</span>
          </div>
        </div>
      </Link>
    </li>
  );
};
