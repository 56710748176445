import { Fragment } from 'react/jsx-runtime';
import { Link } from 'react-router-dom';

import starIc from '@/assets/ic/star.svg';
import emptyStarIc from '@/assets/ic/star-gr.svg';
import deleteIc from '@/assets/ic/x-sm.svg';
import { PATH } from '@/constants/path';
import { WoReviewBySelf } from '@/generated/api/type/data-contracts';
import { dateToStringFormat } from '@/utils/formats';

interface ReviewItemProps {
  activeClassName: string;
  hasReviewed: boolean;
  review: WoReviewBySelf;
  activeReview: number;
  handleClickCreateReview: (prdId: number) => void;
  handleClickDeleteBtn: (refetch: () => void, reviewIdx: number) => void;
  toggleReview: (reviewItem: number) => void;
  refetchReviewData: () => void;
}
export const ReviewItem = ({
  activeClassName,
  hasReviewed,
  review,
  activeReview,
  handleClickCreateReview,
  handleClickDeleteBtn,
  toggleReview,
  refetchReviewData,
}: ReviewItemProps) => {
  return (
    <li className={`myReview-item ${activeClassName}`} key={`review_${review.review_idx}`}>
      <article className="myReview-wrap">
        {/* <!-- 리뷰 작성 기한 --> */}
        {hasReviewed ? (
          <div className="review-info">
            {/* <span className="state">500P</span> */}
            <span className="date mobile-date">
              {dateToStringFormat(new Date(review?.created_at ?? ''), 'dot')} 작성
            </span>
            {/* <span className="subtext">적립 포인트</span> */}
          </div>
        ) : (
          <div className="review-info">
            <span className="state">D-{review?.review_writable_days}</span>
            <span className="date">
              {dateToStringFormat(new Date(review?.review_writable_date ?? ''), 'dot')} 까지
            </span>
          </div>
        )}
        {/* <!-- 제품 상세 --> */}
        <Link
          className="product-item horizontal"
          to={`${PATH.PRODUCT_DETAIL}/${review?.product_idx}`}
        >
          <div className="thumbnail">
            <img src={review?.product_main_image_url} alt="제품이미지" />
          </div>
          <div className="info-wrap">
            <p className="store">{review?.brand_name}</p>
            <p className="title">{review?.product_name}</p>
            <p className="option">{review?.order_option_name}</p>
          </div>
        </Link>
        {hasReviewed ? (
          <>
            <article className="myReview-result-wrap">
              <div className="review-data">
                <div className="stars">
                  {new Array(5).fill(false).map((_, idx) => (
                    <img
                      src={idx < (review?.review_rating_score ?? 0) ? starIc : emptyStarIc}
                      alt="star"
                      key={`review_select_start${idx}`}
                    />
                  ))}
                  <span className="point">{review?.review_rating_score}점</span>
                </div>
                <div className="main-text">
                  {review?.review_text_auto?.split('\n').map((line, idx) => (
                    <Fragment key={`review_text_${idx}`}>
                      {line}
                      <br />
                    </Fragment>
                  ))}
                  <img src={review?.review_image_url} alt="review" />
                </div>
              </div>
            </article>
            <div className="review-action review-open">
              <button
                type="button"
                className="btn btn-sm btn-subtle btn-block"
                onClick={() => toggleReview(review?.review_idx ?? 0)}
              >
                리뷰{activeReview === review?.review_idx ? '닫기' : '보기'}
              </button>
              <span className="date desktop-date">
                {dateToStringFormat(new Date(review?.created_at ?? ''), 'dot')} 작성
              </span>
            </div>
            <div className="btn-delete">
              <button
                onClick={() => handleClickDeleteBtn(refetchReviewData, review?.review_idx ?? 0)}
              >
                <img src={deleteIc} alt="" />
              </button>
            </div>
          </>
        ) : (
          <div className="review-action">
            <button
              type="button"
              className="btn btn-sm btn-outline btn-block"
              onClick={() => handleClickCreateReview(review?.product_idx ?? 0)}
            >
              리뷰 작성
              {/* <span className="point">500P</span> */}
            </button>
          </div>
        )}
      </article>
    </li>
  );
};
