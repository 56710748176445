import { Modal } from '@components/common/molecules/modal/index';

import { WoOrderOption } from '@/generated/api/type/data-contracts';
import { numberFormat } from '@/utils/formats';

interface CancellationOptionListModalProps {
  optionList?: WoOrderOption[];
  selectedOptions: WoOrderOption[];
  selectedIDs: number[];
  toggleModal: () => void;
  handleSelectOption: (option: any) => void;
  handleButtonClick: () => void;
  isOpen: boolean;
}
export const CancellationOptionListModal = ({
  optionList,
  selectedOptions,
  handleButtonClick,
  selectedIDs,
  toggleModal,
  handleSelectOption,
  isOpen,
}: CancellationOptionListModalProps) => {
  return (
    <Modal
      title="상품 선택"
      toggleModal={toggleModal}
      btns={[
        {
          title: '확인',
          handleClick: handleButtonClick,
          disabled: selectedOptions.length === 0,
        },
      ]}
      isOpen={isOpen}
    >
      <div className="row product-select">
        {/* <!-- 상품 정보 --> */}
        {optionList?.map(option => {
          return (
            <div key={option?.order_option_idx ?? ''} className="product-item horizontal">
              <label className="checkbox-group green-box sm-box">
                <input
                  type="checkbox"
                  name="test-1"
                  onChange={() => handleSelectOption(option)}
                  checked={selectedIDs.includes(option?.order_option_idx ?? 0)}
                />
                <span className="checkmark"></span>
              </label>
              <div className="thumbnail">
                <img src={option?.main_image_url} alt="제품이미지" />
              </div>
              <div className="info-wrap">
                <p className="title">{option?.product_name}</p>
                <p className="option">{option?.order_option_name}</p>
                <div className="pricing-wrap">
                  <div className="pricing">
                    {numberFormat(option?.real_price)}
                    <span className="unit">원</span>
                  </div>
                  <div className="prev-price">
                    {numberFormat(option?.sale_price)}
                    <span className="unit">원</span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
