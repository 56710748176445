import { MainProductList } from '@components/domain/main/main-product-list';

import { useFetchBestTen } from '@/apis/commerce/products/useFetchBestTen';
import { useFetchUserRelatedProduct } from '@/apis/commerce/products/useFetchUserRelatedProduct';

export const MainTemplate = () => {
  // const { userInfo } = useLoginInfo();

  const { data: bestData, isFetched: isBestFetched } = useFetchBestTen();
  const { data: userRelatedProductData, isFetched: isUserRelatedProductDataFetched } =
    useFetchUserRelatedProduct({});

  return (
    <>
      {/* <MainSlides /> */}
      <div>
        {/* {isBestFetched && bestData !== undefined && (
          <ProductHorizontalList list={bestData?.products} type="horizontal" title="BEST 10!!" />
        )}
        {isUserRelatedProductDataFetched && userRelatedProductData?.products !== undefined && (
          <ProductHorizontalList
            list={userRelatedProductData?.products}
            type="horizontal"
            title="회원님을 위한 관련 제품"
          />
        )}
         <TypeB1 />
        <TypeB2 />
        <TypeC />
        <TypeD /> */}

        <MainProductList />
      </div>
    </>
  );
};
