import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

interface Props {
  reviewIdx: number;
  typeCd: string;
  text: string;
}

export function useMutationReportReview({ reviewIdx, typeCd, text }: Props) {
  const reportReview = async () => {
    await post(`/api/commerce/v1/reviews/${reviewIdx}/report-violation`, {
      violation_type_cd: typeCd,
      report_text: text,
    });
  };
  return useMutation({ mutationFn: reportReview });
}
