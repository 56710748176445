import { RefObject, useEffect, useRef } from 'react';

interface BodyInfoItem {
  counter: number;
  initialOverflow: CSSStyleDeclaration['overflow'];
  initialPosition: CSSStyleDeclaration['position'];
  initialTop: CSSStyleDeclaration['top'];
  initialLeft: CSSStyleDeclaration['left'];
  initialScrollY: number;
}

const isBrowser = typeof window !== 'undefined';
const isIosDevice =
  isBrowser &&
  window.navigator &&
  window.navigator.platform &&
  /iP(ad|hone|od)/.test(window.navigator.platform);

const getClosestBody = (
  el: Element | HTMLElement | HTMLIFrameElement | null,
): HTMLElement | null => {
  if (!el) {
    return null;
  } else if (el.tagName === 'BODY') {
    return el as HTMLElement;
  } else if (el.tagName === 'IFRAME') {
    const document = (el as HTMLIFrameElement).contentDocument;
    return document ? document.body : null;
  } else if (!(el as HTMLElement).offsetParent) {
    return null;
  }

  return getClosestBody((el as HTMLElement).offsetParent!);
};

const bodies: Map<HTMLElement, BodyInfoItem> = new Map();
const doc: Document | undefined = typeof document === 'object' ? document : undefined;

export const useLockBodyScroll = (
  locked: boolean,
  elementRef?: RefObject<HTMLElement | undefined>,
) => {
  const bodyRef = useRef(doc?.body);
  elementRef = elementRef || bodyRef;

  const lock = (body: HTMLElement) => {
    const bodyInfo = bodies.get(body);
    if (!bodyInfo) {
      const pageY = window.scrollY ? window.scrollY : 0;

      bodies.set(body, {
        counter: 1,
        initialOverflow: body.style.overflow,
        initialPosition: body.style.position,
        initialTop: body.style.top,
        initialLeft: body.style.left,
        initialScrollY: pageY,
      });

      if (isIosDevice) {
        body.style.position = 'fixed';
        //fixed로 변경할 경우 바디가 올라가므로 스크롤만큼
        //top을 이동시켜 fixed가 아닌 ui처럼 포지션을 맞춰줍니다.
        body.style.top = `-${pageY}px`;
        body.style.left = '0px';
      } else {
        body.style.overflow = 'hidden';
      }
    } else {
      bodies.set(body, {
        counter: bodyInfo.counter + 1,
        initialOverflow: bodyInfo.initialOverflow,
        initialPosition: bodyInfo.initialPosition,
        initialTop: bodyInfo.initialTop,
        initialLeft: bodyInfo.initialLeft,
        initialScrollY: bodyInfo.initialScrollY,
      });
    }
  };

  const unlock = (body: HTMLElement) => {
    const bodyInfo = bodies.get(body);
    if (bodyInfo) {
      if (bodyInfo.counter === 1) {
        bodies.delete(body);
        if (isIosDevice) {
          body.style.position = bodyInfo.initialPosition;
          body.style.top = bodyInfo.initialTop;
          body.style.left = bodyInfo.initialLeft;

          //기존 스크롤을 복구합니다.
          window.scrollTo(0, Number(bodyInfo.initialScrollY));
        } else {
          body.style.overflow = bodyInfo.initialOverflow;
        }
      } else {
        bodies.set(body, {
          counter: bodyInfo.counter - 1,
          initialOverflow: bodyInfo.initialOverflow,
          initialPosition: bodyInfo.initialPosition,
          initialTop: bodyInfo.initialTop,
          initialLeft: bodyInfo.initialLeft,
          initialScrollY: bodyInfo.initialScrollY,
        });
      }
    }
  };

  useEffect(() => {
    if (elementRef?.current) {
      const body = getClosestBody(elementRef?.current);
      if (!body) {
        return;
      }
      if (locked) {
        lock(body);
      } else {
        unlock(body);
      }
    }
  }, [locked, elementRef.current]);

  useEffect(() => {
    if (elementRef?.current) {
      const body = getClosestBody(elementRef?.current);
      if (!body) {
        return;
      }
    }
    return () => {
      if (elementRef?.current) {
        const body = getClosestBody(elementRef?.current);
        if (body) {
          unlock(body);
        }
      }
    };
  }, []);
};
