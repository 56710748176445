import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { QueryOptionsType } from '@/generated/api/type/api';
import { WoReviewsGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  productIdx: string;
  offset: number;
  limit: number;
  order: string;
  options?: QueryOptionsType<WoReviewsGetRes>;
}
export function useFetchProductReview({ offset, limit, productIdx, order }: Props) {
  const getProductReview = async () => {
    return await get(
      `/api/commerce/v1/products/${productIdx}/reviews?offset=${offset}&limit=${limit}&order_column=${order}`,
    ).then(res => res.data as WoReviewsGetRes);
  };

  return useQuery({
    queryKey: ['useFetchProductReview', offset, limit, productIdx, order],
    queryFn: getProductReview,
  });
}
