import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertModal } from '@components/common/molecules/modal/alert-modal';
import { Pagination } from '@components/common/molecules/pagination';

import { PATH } from '@/constants/path';
import { useGetOrders } from '@/generated/api/fn/order-controller/order-controller';
import useLoginInfo from '@/hooks/use-login-info';
import useOrderList from '@/hooks/use-order-list';
import { dateToStringFormat, numberFormat } from '@/utils/formats';

interface OrderListDesktopProps {
  limit: number;
  offset: number;
}
export const OrderListDesktop = ({ limit, offset }: OrderListDesktopProps) => {
  const { toTheFirstPage, beginDate, setValue, endDate, currentPage } = useOrderList();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { userToken } = useLoginInfo();

  const {
    data: orderListData,
    isFetched,
    error,
    refetch: refetchOrderList,
  } = useGetOrders(
    {
      created_at_begin: beginDate,
      created_at_end: endDate,
      limit,
      offset,
    },
    {
      request: {
        accessToken: userToken,
      },
      query: {
        enabled: !!userToken,
      },
    },
  );

  useEffect(() => {
    pathname !== PATH.MYPAGE && toTheFirstPage();
  }, [beginDate, endDate]);

  useEffect(() => {
    refetchOrderList();
  }, [currentPage]);

  const handleClickOrder = (id: string) => {
    navigate(`${PATH.ORDER_LIST}/${id}`);
  };

  const { logout } = useLoginInfo();
  const handleClickMoveToLoginPage = () => {
    logout();
  };

  if (error?.response?.status === 403 || error?.response?.status === 401) {
    return (
      <AlertModal
        isOpen={true}
        header={['권한오류']}
        body={['로그인 후 이용해주세요']}
        buttons={[
          {
            title: '로그인 하러가기',
            handleClick: handleClickMoveToLoginPage,
          },
        ]}
      />
    );
  }

  return (
    <>
      {isFetched && !orderListData?.orders?.length ? (
        <div className="empty-list">
          <div className="lines">
            <span>“주문 내역이 없어요”</span>
            <span className="line"></span>
          </div>
          <div className="sub-text">
            {limit > 3 ? '선택한 기간에 ' : '최근 3개월 내 '}주문하신 상품이 없어요.
          </div>
        </div>
      ) : (
        <>
          {limit > 3 && (
            <div className="product-list-filter filter-sm">
              <div className="result-value">총 {orderListData?.total_count}건</div>
            </div>
          )}
          <ul className="selected-payment-list">
            {orderListData?.orders?.map((order, idx) => (
              <li className="payment-item" key={order?.order_id ?? idx}>
                <article className="border-wrap">
                  {/* <!-- FIXME: pd-b-0 클래스를 붙여 패딩 제거함 --> */}
                  <div className="title-col pd-b-0">
                    <div className="title">
                      {dateToStringFormat(new Date(order?.created_at ?? ''), 'dot')}
                      <span>(총 {order?.order_option_cnt}건)</span>
                    </div>
                    <div
                      className="link-more"
                      onClick={() => handleClickOrder(String(order?.order_idx ?? ''))}
                    >
                      주문상세 &gt;
                    </div>
                  </div>
                  {/* <!-- 스토어 제품 리스트 --> */}
                  <ul className="selected-store-list">
                    {/* <!-- 제품 1 --> */}
                    <li className="store-item">
                      {/* <!-- 제품 상세 --> */}
                      <div className="product-item horizontal">
                        <div className="thumbnail">
                          <img src={order?.order_image_url} alt="제품이미지" />
                        </div>
                        <div className="info-wrap desktop-inline">
                          <div className="col">
                            <p className="title">{order?.order_name}</p>
                            {/* // TODO option 어쩔? */}
                            {/* <p className="option">01 프로바이오 에스엘비 60캡슐 x 2박스</p> */}
                          </div>
                          <div className="col all-inline">
                            <div className="inner-col">
                              <div className="label">상품금액</div>
                              <div className="pricing">
                                {numberFormat(order?.final_payment_amount)}
                                <span className="unit">원</span>
                              </div>
                            </div>
                            <div className="inner-col amount-col">
                              <div className="label">수량</div>
                              <div className="amount">
                                {order?.order_option_cnt}
                                <span className="unit">개</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </article>
              </li>
            ))}
          </ul>
          {limit > 3 && (
            <Pagination
              total={orderListData?.total_count ?? 0}
              limit={limit}
              setValue={setValue}
              currentPage={Number(currentPage)}
            />
          )}
        </>
      )}
    </>
  );
};
