import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

interface Props {
  reviewIdx: number;
}

export function useMutationDeleteReview() {
  const deleteReview = async ({ reviewIdx }: Props) => {
    return await post(`/api/commerce/v1/reviews/${reviewIdx}/delete`)
      .then(res => res.data)
      .catch(e => console.log(e));
  };

  return useMutation({ mutationFn: deleteReview });
}
