import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';
import { type MutationOptionsType } from '@/generated/api/type/api';
import { type WoKwordsBestGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  options?: MutationOptionsType<WoKwordsBestGetRes, unknown>;
}

export function useMutationDeleteSearchedKeywords({ options }: Props) {
  const deleteBestKeywords = async () => {
    return await post('/api/commerce/v1/self/input_kwords/delete').then(res => res.data);
  };

  return useMutation({
    mutationFn: deleteBestKeywords,
    ...options,
  });
}
