import { ErrorTemplate } from '@components/template/_error';

interface ErrorPageProps {
  type?: string;
}
const ErrorPage = ({ type }: ErrorPageProps) => {
  return <ErrorTemplate type={type} />;
};

export default ErrorPage;
