import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { type QueryOptionsType } from '@/generated/api/type/api';
import { type WoKwordsBestGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  options?: QueryOptionsType<WoKwordsBestGetRes>;
}

export function useFetchBestKeywords({ options }: Props) {
  const getBestKeywords = async () => {
    return await get('/api/commerce/v1/kwords/best').then(res => res.data);
  };

  return useQuery({
    queryKey: ['useFetchBestKeywords'],
    queryFn: getBestKeywords,
    ...options,
  });
}
