import useFetchCautions from '@/apis/commerce/cautions/useFetchCautions';

export const CartNotice = () => {
  const { data: cautionData } = useFetchCautions({ cautionTypeCd: 'cart' });

  return (
    <div className="cart-notice">
      <div className="title">유의사항</div>
      <ul className="list-group">
        {cautionData?.caution_articles?.map((article, index) => (
          <li key={index}>{article?.c_text}</li>
        ))}
      </ul>
    </div>
  );
};
