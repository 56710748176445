import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

export function useMutationAddressDelete() {
  const deleteAddress = async ({ addressIdx }: { addressIdx: string }) => {
    return await post(`/api/commerce/v1/self/user-addresses/${addressIdx}/delete`).then(
      res => res.data,
    );
  };

  return useMutation({ mutationFn: deleteAddress });
}
