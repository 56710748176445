import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';
import { type WoCartItemCreateForm } from '@/generated/api/type/data-contracts';

export const useMutationAddCart = () => {
  const addToCart = async (params: WoCartItemCreateForm[]) => {
    await post(`/api/commerce/v1/cart/items/create`, {
      cart_items: params,
    });
  };
  return useMutation({ mutationFn: addToCart });
};
