import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useInfinitelyFetchOrderList } from '@/apis/commerce/orders/useInfinitelyFetchOrderList';
import moreBtn from '@/assets/ic/chevron-down-bk.svg';
import { PATH } from '@/constants/path';
import useOrderList from '@/hooks/use-order-list';
import { dateToStringFormat, numberFormat } from '@/utils/formats';

interface OrderListMobileProps {
  limit: number;
  offset: number;
}
export const OrderListMobile = ({ limit, offset }: OrderListMobileProps) => {
  const navigate = useNavigate();
  const { beginDate, endDate } = useOrderList();

  const {
    data,
    fetchNextPage,
    refetch: refetchOrderList,
  } = useInfinitelyFetchOrderList({
    created_at_begin: beginDate,
    created_at_end: endDate,
    limit,
    offset,
    staleTime: 0,
  });

  const handleClickOrder = (id: string) => {
    navigate(`${PATH.ORDER_LIST}/${id}`);
  };

  useEffect(() => {
    refetchOrderList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beginDate, endDate]);

  return !data?.pages?.[0]?.data?.orders?.length ? (
    <div className="empty-list">
      <div className="lines">
        <span>“주문 내역이 없어요”</span>
        <span className="line"></span>
      </div>
      <div className="sub-text">
        {limit > 3 ? '선택한 기간에 ' : '최근 3개월 내 '}주문하신 상품이 없어요.
      </div>
    </div>
  ) : (
    <>
      {limit > 3 && (
        <div className="product-list-filter filter-sm">
          <div className="result-value">
            총 {data?.pages?.[0]?.total ?? 0}건
            <span className="date">
              ({dateToStringFormat(new Date(beginDate), 'dot')}-
              {dateToStringFormat(new Date(endDate), 'dot')})
            </span>
          </div>
        </div>
      )}
      <ul className="selected-payment-list">
        {/* <!-- 주문 1 --> */}
        {data?.pages?.map(page =>
          page?.data?.orders?.map(order => (
            <li className="payment-item" key={order?.order_id}>
              <article className="border-wrap">
                {/* <!-- 주문 수, 상세링크 --> */}
                {/* <!-- FIXME: pd-b-0 클래스를 붙여 패딩 제거함 --> */}
                <div className="title-col pd-b-0">
                  <div className="title">
                    {dateToStringFormat(new Date(order?.created_at ?? ''), 'dot')}
                    <span>(총 {order?.order_option_cnt}건)</span>
                  </div>
                  <div
                    className="link-more"
                    onClick={() => handleClickOrder(String(order?.order_idx ?? ''))}
                  >
                    주문상세 &gt;
                  </div>
                </div>
                {/* <!-- 스토어 제품 리스트 --> */}
                <ul className="selected-store-list">
                  {/* <!-- 제품 1 --> */}
                  <li className="store-item">
                    {/* <!-- 제품 상세 --> */}
                    <div className="product-item horizontal">
                      <div className="thumbnail">
                        <img src={order?.order_image_url} alt="제품이미지" />
                      </div>
                      <div className="info-wrap desktop-inline">
                        <div className="col">
                          <p className="title">{order?.order_name}</p>
                          {/* <p className="option">01 프로바이오 에스엘비 60캡슐 x 2박스</p> */}
                        </div>
                        <div className="col all-inline">
                          <div className="inner-col">
                            <div className="label">상품금액</div>
                            <div className="pricing">
                              {numberFormat(order?.final_payment_amount)}
                              <span className="unit">원</span>
                            </div>
                          </div>
                          <div className="inner-col amount-col">
                            <div className="label">수량</div>
                            <div className="amount">
                              {order?.order_option_cnt}
                              <span className="unit">개</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </article>
            </li>
          )),
        )}
      </ul>
      {data?.pages?.[(data?.pages?.length ?? 0) - 1].isLast ? (
        <></>
      ) : (
        <div id="load-pagenation">
          <div className="pagenation-group" id="pagenation">
            <button
              type="button"
              className="btn btn-md btn-block btn-outline pagenation-mobile"
              onClick={() => fetchNextPage()}
            >
              <img src={moreBtn} alt="more" />
              상품 더보기
            </button>
          </div>
        </div>
      )}
    </>
  );
};
