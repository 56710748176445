import { useState } from 'react';

import { useFetchFaqs } from '@/apis/commerce/boards/useFetchFaqs';

export const HelpFaqTemplate = () => {
  const [activeQuestion, setActiveQuestion] = useState<number | undefined>(undefined);
  const { data: faqData } = useFetchFaqs({ offset: 0, limit: 40 });

  const handleClickQuestion = (idx: number) =>
    idx === activeQuestion ? setActiveQuestion(undefined) : setActiveQuestion(idx);

  return (
    <main className="my-main">
      <section className="desktop-jumbotron section">
        <div className="section-title">
          <div className="label">자주하는 질문</div>
        </div>
      </section>
      <ul className="qna-group-list">
        {faqData?.faq_articles?.map((faq, faqIdx) => (
          <li
            className={`qna-group-item${faqIdx === activeQuestion ? ' active' : ''}`}
            key={`faq_${faqIdx}`}
            onClick={() => handleClickQuestion(faqIdx)}
          >
            <article className="qna-item">
              <div className="title-wrap">
                <span className="full-text">
                  [{faq?.qna_type_name}] {faq?.q_text}
                </span>
              </div>
              {/* <!-- 답변 --> */}
              <div className="answer-wrap">
                <div className="text">{faq?.a_text}</div>
              </div>
            </article>
          </li>
        ))}
      </ul>
      <div className="pagenation-group" id="pagenation"></div>
    </main>
  );
};
