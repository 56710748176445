import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import useFetchCautions from '@/apis/commerce/cautions/useFetchCautions';
import infoGrIc from '@/assets/ic/info-gr.svg';
import closeIc from '@/assets/ic/x-sm.svg';
import useMixpanel from '@/hooks/use-mixpanel';

interface ReviewNoticeProps {
  reviewWritable: boolean;
  modalOpen: (type: string) => void;
}
export const ReviewNotice = ({ reviewWritable, modalOpen }: ReviewNoticeProps) => {
  const { handleMixpanelEvent } = useMixpanel();
  const { id } = useParams();
  const { data: cautionData } = useFetchCautions({ cautionTypeCd: 'review' });

  const [isTooltipShow, setIsTooltipShow] = useState(false);
  const toggleTooltip = () => {
    setIsTooltipShow(!isTooltipShow);
  };
  const handleClickReviewWrite = () => {
    handleMixpanelEvent('click_review_write', { from: 'product', product_id: id });
    modalOpen('review');
  };
  return (
    <div className="notice">
      <button className="btn btn-sm btn-subtle">
        <img src={infoGrIc} alt="" onClick={toggleTooltip} />
        리뷰 운영 정책
      </button>
      {reviewWritable && (
        <button type="button" className="btn btn-sm btn-outline " onClick={handleClickReviewWrite}>
          리뷰 작성
        </button>
      )}

      {isTooltipShow && (
        <div className="tooltip" id="tooltip-review">
          <Link
            className="ic-close"
            to=""
            onClick={e => {
              e.preventDefault();
              toggleTooltip();
            }}
          >
            <img src={closeIc} alt="" />
          </Link>
          <div className="title">{cautionData?.caution_articles?.[0]?.caution_type_name}</div>
          <div className="contents">
            {cautionData?.caution_articles?.map((article, idx) => (
              <div key={idx}>
                {article.c_text}
                <br />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
