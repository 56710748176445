import { MyInquiryDesktop } from '@components/domain/mypage/inquiry/my-inquiry-desktop';
import { MyInquiryMobile } from '@components/domain/mypage/inquiry/my-inquiry-mobile';

import { isMobile } from '@/utils';

export const MyInquiryTemplate = () => {
  return (
    <main className="my-main">
      {isMobile ? <MyInquiryMobile /> : <MyInquiryDesktop limit={10} />}
    </main>
  );
};
