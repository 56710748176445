import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { QueryOptionsType } from '@/generated/api/type/api';
import { WoProductCheckReviewWritableGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  productIdx: string;
  options?: QueryOptionsType<WoProductCheckReviewWritableGetRes>;
}
export function useFetchReviewWritable({ productIdx }: Props) {
  const checkReviewWritable = async () => {
    return await get(`/api/commerce/v1/products/${productIdx}/check-review-writable`).then(
      res => res.data as WoProductCheckReviewWritableGetRes,
    );
  };

  return useQuery({
    queryKey: ['useFetchReviewWritable', productIdx],
    queryFn: checkReviewWritable,
  });
}
