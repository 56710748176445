import { ChangeEvent, Fragment, MouseEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AlertModal } from '@components/common/molecules/modal/alert-modal';

import { useMutationPreorderAddressUpdate } from '@/apis/commerce/preorders/useMutationPreorderAddressUpdate';
import { useFetchAddressSelection } from '@/apis/commerce/self/useFetchAddressSelection';
import { useMutationAddressDelete } from '@/apis/commerce/self/useMutationAddressDelete';
import { useMutationAddressModify } from '@/apis/commerce/self/useMutationAddressModify';
import plusImg from '@/assets/ic/plus-bk.svg';
import closeImg from '@/assets/ic/x-lg.svg';
import { WoUserAddress } from '@/generated/api/type/data-contracts';
import { useLockBodyScroll } from '@/hooks/use-lock-body-scroll';
import { useSearchUrl } from '@/hooks/use-search-url';

import { AddressEditor } from './address-editor';

interface DeliveryAddressModalProps {
  handleClickAddressModalToggle: () => void;
  from: string;
  addressId?: string;
  isOpen: boolean;
}
/**
 * 배송지 관련 모달 분기처리
 *      배송지 목록: list //배송지 선택
 *      배송지 등록/ 수정: editor //새 배송지 추가, 배송지 변경
 */
export const DeliveryAddressModal = ({
  handleClickAddressModalToggle,
  from,
  addressId,
  isOpen,
}: DeliveryAddressModalProps) => {
  //TODO: 공통모달컴포넌트로 수정되면 훅 삭제
  useLockBodyScroll(isOpen);

  const { getStateBySearchParams } = useSearchUrl();
  const orderId = getStateBySearchParams('id');
  const [currentState, setCurrentState] = useState('list');
  const [title, setTitle] = useState('배송지 선택');

  const { data: addressData, refetch: refetchAddress } = useFetchAddressSelection({});
  const { mutate: mutateSelectAddress } = useMutationPreorderAddressUpdate();
  const { mutate: mutateAddressDelete } = useMutationAddressDelete();
  const { mutate: mutateModifyAddress } = useMutationAddressModify();

  const [selectedAddressId, setSelectedAddressId] = useState(addressId ?? '');
  const [modifyingItem, setModifyingItem] = useState<WoUserAddress | undefined>();

  const handleChangeSelection = (e: ChangeEvent<HTMLInputElement>, address: WoUserAddress) => {
    setSelectedAddressId(address?.address_id ?? '');
  };

  const handleClickAddressAddition = () => {
    setCurrentState('editor');
  };

  const handleClickModify = (address: WoUserAddress) => {
    setCurrentState('editor');
    setTitle('배송지 변경');
    setModifyingItem(address);
  };

  const handleClickDelete = (address: WoUserAddress) => {
    mutateAddressDelete({ addressIdx: address?.address_id ?? '' }, { onSuccess: refetchAddress });
  };

  const selectedAddress = addressData?.user_addresses?.find(
    address => selectedAddressId === address?.address_id,
  );

  const handleChangeDeliveryAddress = () => {
    !selectedAddressId
      ? handleClickAddressModalToggle()
      : from === 'order'
      ? mutateSelectAddress(
          { preorderIdx: orderId ?? '', addressId: selectedAddressId ?? '' },
          {
            onSuccess: handleClickAddressModalToggle,
            onError: (err: any) => console.log(err.message),
          },
        )
      : mutateModifyAddress(
          {
            addressId: selectedAddressId ?? '',
            userAddress: { ...selectedAddress, default_yn: 'Y' },
          },
          { onSuccess: handleClickAddressModalToggle },
        );
  };

  const getAddressDataAgain = () => refetchAddress();
  const handleChangeTitle = (title: '새 배송지 추가' | '배송지 변경' | '주소검색') =>
    setTitle(title);

  const handleCloseModal = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    handleClickAddressModalToggle();
  };

  useEffect(() => {
    addressData && setSelectedAddressId(addressData?.user_addresses?.[0]?.address_id ?? '');
  }, [addressData]);

  return (!addressData ||
    !Object.keys(addressData).length ||
    !addressData?.user_addresses?.length) &&
    currentState !== 'editor' ? (
    <AlertModal
      header={['확인해주세요!']}
      body={[
        '등록된 배송지 정보가 없어요.',
        '원활한 상품 배송을 위해 배송받으실',
        '주소를 입력해 주세요.',
      ]}
      buttons={[{ title: '배송지 입력', handleClick: () => setCurrentState('editor') }]}
      isOpen={isOpen}
    />
  ) : (
    <div className="modal-background">
      <div className="modal-fluid modal-address">
        {/* <!-- 상단 네비게이션 --> */}
        <div className="modal-fluid-nav">
          <div className="title">{title}</div>
          <Link to="#" className="btn-close" onClick={handleCloseModal}>
            <img src={closeImg} alt="" />
          </Link>
        </div>
        {/* <!-- 모달 컨텐츠 --> */}
        {currentState === 'editor' ? (
          <AddressEditor
            getAddressDataAgain={getAddressDataAgain}
            handleChangeTitle={handleChangeTitle}
            setCurrentState={setCurrentState}
            modifyingItem={modifyingItem}
          />
        ) : (
          <>
            <div>
              <div className="modal-contents">
                <div className="row main">
                  <ul className="sort-list address-list">
                    {addressData?.user_addresses?.map((address, addressIdx) => {
                      return (
                        <Fragment key={address?.address_id}>
                          <li>
                            <label className="radio-group green-active-wrap address">
                              <input
                                type="radio"
                                checked={
                                  selectedAddressId
                                    ? selectedAddressId === address?.address_id
                                    : address?.default_yn === 'Y'
                                }
                                onChange={e => handleChangeSelection(e, address)}
                              />
                              <div className="label">
                                <div className="title">
                                  <div className="name">
                                    {address?.ship_recipient_name}
                                    <span className="option">({address?.ship_site_name})</span>
                                  </div>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-outline "
                                    onClick={() => handleClickModify(address)}
                                  >
                                    수정
                                  </button>
                                  {addressIdx !== 0 && (
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-outline "
                                      onClick={() => handleClickDelete(address)}
                                    >
                                      삭제
                                    </button>
                                  )}
                                </div>
                                <div className="info address">
                                  [{address?.ship_postal_code}]{' '}
                                  {`${address?.ship_address} ${address?.ship_detailed_address}`}
                                </div>
                                <div className="info">{address?.ship_contact1}</div>
                              </div>
                              <span className="checkmark"></span>
                            </label>
                          </li>
                          {addressIdx === 0 && (
                            <li className="btn-item">
                              <button
                                type="button"
                                className="btn btn-md btn-outline btn-block"
                                onClick={handleClickAddressAddition}
                              >
                                <img src={plusImg} alt="새 배송지 추가" />새 배송지 추가
                              </button>
                            </li>
                          )}
                        </Fragment>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="mobile-bottom-nav">
              <div className="btn-inline-group">
                <button
                  type="button"
                  className="btn btn-md btn-filled btn-block"
                  onClick={handleChangeDeliveryAddress}
                >
                  {from === 'order' ? '배송지 변경하기' : '기본 배송지로 설정하기'}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
