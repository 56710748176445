import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { getGetSelfQueryKey } from '@/generated/api/fn/self-controller/self-controller';
import { QueryOptionsType } from '@/generated/api/type/api';
import { WoSelfDetailGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  accessToken: string;
  options?: QueryOptionsType<WoSelfDetailGetRes>;
}

export function useFetchGetUserDetail({ accessToken, options }: Props) {
  const getUserDetail = async () => {
    const response = await get('/api/commerce/v1/self/detail');
    return response.data;
  };

  return useQuery({
    queryKey: [...getGetSelfQueryKey(), accessToken],
    queryFn: getUserDetail,
    staleTime: 0,
    gcTime: 0,
    ...options,
  });
}
