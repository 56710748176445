import { useNavigate } from 'react-router-dom';
import { Jumbotron } from '@components/common/molecules/jumbotron';
import { Toast } from '@components/common/molecules/toast';
import { AdsAgreement } from '@components/domain/mypage/info/ads-agreement';
import { UserEmail } from '@components/domain/mypage/info/user-email';
import { UserInfoItem } from '@components/domain/mypage/info/user-info-item';
import { UserNickname } from '@components/domain/mypage/info/user-nickname';

import { PATH } from '@/constants/path';
import useMyInfo from '@/hooks/use-my-info';
import { stringDateToStringFormat } from '@/utils/formats';

export const MyInfoTemplate = () => {
  const navigate = useNavigate();
  const {
    toastState,
    userInfo,
    modifyStatus,
    toggleToast,
    handleClickCheckbox,
    handleUpdateUserData,
    handleModifyNicknameBtnClick,
  } = useMyInfo();
  const handleWithdrawal = () => navigate(PATH.MY_WITHDRAWAL);

  return (
    <main className="my-main">
      <Jumbotron title="나의 정보수정" />
      {/* <!-- 나의 정보수정 리스트 --> */}
      <div className="section-contents">
        <ul className="myInfo-forms-group">
          <UserInfoItem name="이메일" value={userInfo?.user_info?.account_id ?? ''} />
          <UserInfoItem name="이름" value={userInfo?.user_info?.name ?? ''} />
          <UserNickname
            modifyStatus={modifyStatus}
            nickname={userInfo?.user_info?.nickname ?? ''}
            handleUpdateUserData={handleUpdateUserData}
            handleModifyNicknameBtnClick={handleModifyNicknameBtnClick}
          />
          <UserEmail
            modifyStatus={modifyStatus}
            email={userInfo?.user_info?.email ?? ''}
            handleUpdateUserData={handleUpdateUserData}
            handleModifyNicknameBtnClick={handleModifyNicknameBtnClick}
          />

          <UserInfoItem
            name="생년월일"
            value={stringDateToStringFormat(userInfo?.user_info?.birthdate ?? '')}
          />
          <UserInfoItem name="성별" value={userInfo?.user_info?.gender ?? ''} />
        </ul>
      </div>

      <AdsAgreement userInfo={userInfo} handleClickCheckbox={handleClickCheckbox} />
      {/* <!-- 회원 탈퇴 --> */}
      <div className="section-contents withdrawal">
        <button type="button" className="btn btn-sm btn-outline " onClick={handleWithdrawal}>
          회원 탈퇴하기
        </button>
      </div>
      {toastState.isShow && (
        <div>
          <Toast
            message={toastState?.message}
            subMsgs={toastState?.subMsgs}
            toastType={toastState?.toastType}
            handleClickClose={toggleToast}
            duration={2000}
          />
        </div>
      )}
    </main>
  );
};
