import { useEffect, useState } from 'react';

interface useNetworkProps {
  onChange?: (status: boolean) => void;
}

export const useNetwork = ({ onChange }: useNetworkProps) => {
  const [status, setStatus] = useState(navigator.onLine);

  const handleChange = () => {
    onChange && onChange(navigator.onLine);
    setStatus(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener('online', handleChange);
    window.addEventListener('offline', handleChange);
    return () => {
      window.removeEventListener('online', handleChange);
      window.removeEventListener('offline', handleChange);
    };
  }, []);
  return status;
};
