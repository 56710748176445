import toast from 'react-hot-toast';
import axios, { AxiosRequestConfig } from 'axios';
import { AUTH_TOKEN_KEY } from '@/constants/auth';

const token = sessionStorage.getItem(AUTH_TOKEN_KEY) ?? localStorage.getItem(AUTH_TOKEN_KEY);
export const httpClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL ?? ''}`,
  headers: { accept: 'application/json', Authorization: `Bearer ${token ?? ''}` },
  withCredentials:
    !window.location.host.includes('localhost') && !window.location.host.includes('alpha'),
});

httpClient.interceptors.request.use(
  config => {
    //TODO: SSR로 변경되면 사용처에서 API 요청때마다 토큰을 전달하게 변경해야되며 스토리지에 넣은 토큰을 쿠키로 변경해야됩니다.
    const token = sessionStorage.getItem(AUTH_TOKEN_KEY) ?? localStorage.getItem(AUTH_TOKEN_KEY);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Change baseURL if the request URL contains '/user/'
    const NEED_AUTH = ['/update', 'agree', 'info', 'confirm', 'withdrawal'];

    if (config.url && (config.url.includes('/user/') || config.url.includes('/okcert'))) {
      delete config.withCredentials;
      NEED_AUTH.filter(url => config.url?.includes(url)).length === 0 &&
        delete config.headers.Authorization;
      config.baseURL = `${
        // isProduction
        //   ? process.env.REACT_APP_AUTH_API_URL
        // : 'https://dev-api.friendly-pharmacist.com'
        process.env.REACT_APP_AUTH_API_URL
      }`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

httpClient.interceptors.response.use(
  response => response,
  async error => {
    /**
     * status code 401,402
     * 두곳의 API는 친한약사에서 전달받은 인증토큰으로 사용합니다.
     * 친한약사 API : 패스인증, 소셜인증, 계정 유효성검사, 계정가입, 로그인, 유저정보
     * 친한스토어 API : 유저정보
     * 401이나 402는 유효하지 않는 토큰으로 요청했을 때 전달됩니다.
     * 유효하지 않는 토큰이 있을 경우 스토리지 값을 제거해주고 인증 프로세스를 거치게 가이드합니다.
     */
    if (error.response && [401, 402].includes(error.response.status)) {
      const token = sessionStorage.getItem(AUTH_TOKEN_KEY) ?? localStorage.getItem(AUTH_TOKEN_KEY);
      if (token) {
        toast.error('로그인이 만료되었습니다.\n 다시 로그인 해주세요.');

        // 401 : 토큰 만료시 토큰 제거
        localStorage.removeItem(AUTH_TOKEN_KEY);
        sessionStorage.removeItem(AUTH_TOKEN_KEY);
      }
    }
    return Promise.reject(error);
  },
);

export const get = async (uri: string, config?: AxiosRequestConfig<any> | undefined) =>
  await httpClient.get(uri, config);

export const post = async (uri: string, data = {}, config?: any) =>
  await httpClient.post(uri, data as any, config);

export const put = async (uri: string, data = {}) => await httpClient.put(uri, data);
