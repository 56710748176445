import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

// 1. 비밀번호 초기화 이메일 전송 요청
export function useMutationPwdResetRequest() {
  const from = window.location.host.includes('localhost')
    ? 'dev-closure.store'
    : window.location.host.split('.friendly-pharmacist.com')[0];

  const request = async ({ email }: { email: string }) => {
    const response = post('/v1/user/password/reset/request', { email, from });
    return response;
  };
  return useMutation({ mutationFn: request });
}
