import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { useFetchServiceInspectionStatus } from '@/apis/commerce/settings/useFetchServiceInspectionStatus';
import _error from '@/pages/_error';

/**
 * 서비스 상태에 따른
 * TODO: 추후 웹소켓으로 변경할 필요 있음
 */
interface ServiceStatusProviderProps {
  children: ReactNode;
}
export const ServiceStatusMiddleware = ({ children }: ServiceStatusProviderProps) => {
  const location = useLocation();
  const { data: inspectionData } = useFetchServiceInspectionStatus(location);

  if (inspectionData?.is_under_maintenance) {
    return <_error type="inspection" />;
  }

  return <>{children}</>;
};
