import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

export function useMutationConnectCert() {
  const connectCert = async ({ tkn }: { tkn: string }) => {
    const response = await post('/auth/okcert/receive', { mdl_tkn: tkn });
    return response.data;
  };

  return useMutation({
    mutationFn: connectCert,
    onError: error => {
      console.log('useMutationConnectCert Error >> ', error);
    },
  });
}
