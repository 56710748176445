import React from 'react';
import { Link } from 'react-router-dom';

export const AgreementPrivacyTemplate = () => {
  return (
    <>
      <section className="desktop-jumbotron">
        <span className="text">개인정보처리방침</span>
      </section>

      <div className="jumbotron divider">
        <span className="sub-text">
          친한약사는 (주)파마브로스(이하 ‘회사’)에서 온라인에서 약사 상담과 맞춤 제품을 추천하는
          서비스(이하 ‘서비스’)입니다. 회사는 서비스 이용자의 개인정보를 소중하게 생각하는 바,
          「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 및 「개인정보 보호법」등에 따라 다음과
          같이 개인정보 처리방침을 수립하여 공개합니다.
        </span>
      </div>
      <article>
        <ul className="terms-list-group">
          <li className="list-item">
            <p className="title">제1조 (개인정보의 처리 목적)</p>
            <p>
              회사는 다음과 같은 목적으로 개인정보를 처리하며, 그 외 목적의 용도로는 이용하지
              않습니다. 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를
              받는 등 필요한 조치를 이행할 예정입니다
            </p>
            <ul className="number-list-group">
              <li className="list-item">
                회원가입, 이용자 식별, 본인확인, 연령 확인, 분쟁 조정을 위한 기록 보존, 비인가 사용
                방지, 회원탈퇴
              </li>
              <li className="list-item">
                법령 및 서비스 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를
                포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재 등
              </li>
              <li className="list-item">고객 문의 처리 등 회원 보호 및 서비스 운영</li>
              <li className="list-item">서비스의 제공 및 개선, 신규 서비스 개발</li>
              <li className="list-item">서비스 이용과 접속 빈도 분석, 서비스 이용에 대한 통계</li>
              <li className="list-item">
                서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재
              </li>
              <li className="list-item">새로운 정보의 소개 및 약관 개정 등의 고지사항 전달</li>
              <li className="list-item">이벤트 응모 및 당첨자 확인, 경품 제공</li>
            </ul>
          </li>

          <li className="list-item">
            <p className="title">제2조 (수집하는 개인정보의 항목 및 수집방법)</p>
            <p>
              회사는 서비스의 원활한 제공을 위하여 다음과 같은 이용자 개인정보를 수집하고 있습니다
            </p>
            <ul className="number-list-group">
              <li className="list-item">
                <p>수집 시점과 수집 항목</p>
                <ul className="dot-list-group">
                  <li className="list-item">
                    회원가입 시 : 이름, 이메일, 닉네임, 성별, 생년월일, 휴대폰 번호, 배송지
                  </li>
                  <li className="list-item">서비스 시작 시 : 상담 내용</li>
                </ul>
              </li>
              <li className="list-item">
                상담 서비스의 특성상 서비스 신청 전 또는 서비스 제공 과정에서 개인의 건강과 관련된
                정보가 수집됩니다.
              </li>
              <li className="list-item">
                서비스 이용 과정이나 사업 처리 과정에서 서비스이용기록, 접속로그, 쿠키, 접속 IP,
                결제 기록, 불량이용 기록이 생성되어 수집될 수 있습니다.
              </li>
            </ul>
          </li>

          <li className="list-item">
            <p className="title">제3조 (개인정보의 보유 및 파기)</p>
            <p>
              회사는 법령에 따른 개인정보 보유∙이용기간 또는 개인정보를 수집할시 이용자로부터
              동의받은 보유∙이용기간 내에서 개인정보를 처리∙보유합니다.
            </p>
            <ul className="number-list-group">
              <li className="list-item">
                <p>단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.</p>
                <ul className="number-list-group sm-list">
                  <li className="list-item">
                    <p className="sub-title">(1) 회사 내부방침에 의한 정보보유 사유</p>
                    <p>
                      회사는 회원의 탈퇴, 해지 요청 등에 의하여 서비스 이용이 종료된 이후에도 서비스
                      혼선 방지, 불량 회원의 부정한 이용의 재발 및 재가입 방지, 분쟁해결 및
                      수사기관의 요청에 따른 협조 등을 위하여 이용계약 해지일로부터 3년간 회원의
                      정보를 보관할 수 있습니다.
                    </p>
                    <table className="terms-table">
                      <thead>
                        <tr>
                          <th>근거 법령</th>
                          <th>보존 항목</th>
                          <th>보존 기간</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                          <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                          <td>3년</td>
                        </tr>
                        <tr>
                          <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                          <td>표시∙광고에 관한 기록</td>
                          <td>6개월</td>
                        </tr>
                        <tr>
                          <td>통신비밀보호법</td>
                          <td>웹사이트 방문 기록</td>
                          <td>3개월</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                  <li className="list-item">
                    <p className="sub-title">(2) 관련 법령에 의한 정보 보유 사유</p>
                    <p>
                      회사는 회원의 탈퇴, 해지 요청 등에 의하여 서비스 이용이 종료된 이후에도 서비스
                      혼선 방지, 불량 회원의 부정한 이용의 재발 및 재가입 방지, 분쟁해결 및
                      수사기관의 요청에 따른 협조 등을 위하여 이용계약 해지일로부터 3년간 회원의
                      정보를 보관할 수 있습니다.
                    </p>
                  </li>
                </ul>
              </li>
              <li className="list-item">
                회원의 데이터 삭제요청, 회원 탈퇴, 서비스 종료, 이용자에게 동의 받은 보유기간의 종료
                등 개인정보의 수집 및 이용목적이 달성된 개인정보는 재생이 불가능한 방법으로
                파기됩니다. 법령에서 보존의무를 부과한 정보에 대해서도 해당 기간 경과 후 지체없이
                재생이 불가능한 방법으로 파기합니다.
              </li>
              <li className="list-item">
                전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여
                삭제하며, 출력물 등은 분쇄하여 소각하는 방식 등으로 파기합니다.
              </li>
            </ul>
          </li>

          <li className="list-item">
            <p className="title">제 6조 (이용자의 권리와 의무)</p>
            <ul className="number-list-group">
              <li className="list-item">
                <p>이용자의 권리와 행사 방법</p>
                <ul className="number-list-group sm-list">
                  <li className="list-item">
                    (1) 이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수
                    있으며, 회사의 개인정보의 처리에 동의하지 않는 경우 동의를 거부 또는 철회하거나
                    가입해지(회원탈퇴)를 요청할 수 있습니다. 다만, 이 경우 서비스의 일부 또는 전부
                    이용이 어려울 수 있습니다.
                  </li>
                  <li className="list-item">
                    (2) 이용자의 개인정보 조회, 수정은 ‘개인정보변경'(또는 ‘회원정보수정’ 등)에서
                    조회 및 직접 정정이 가능합니다. 혹은 개인정보보호책임자에게 서면, 전화 또는
                    이메일로 연락 시, 지체 없이 조치하겠습니다
                  </li>
                </ul>
              </li>
              <li className="list-item">
                <p>이용자의 의무</p>
                <ul className="number-list-group sm-list">
                  <li className="list-item">
                    (1) 개인정보를 최신의 상태로 정확하게 입력해야 합니다. 이용자가 입력한 부정확한
                    정보로 인해 발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보를 도용하거나
                    허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li className="list-item">
            <p className="title">제 9조 (개인정보의 보호책임자)</p>
            <ul className="number-list-group">
              <li className="list-item">
                회사는 이용자의 개인정보보호를 가장 중요시하며, 회원의 개인정보가 훼손, 침해 또는
                누설되지 않도록 최선을 다하고 있습니다.
              </li>
              <li className="list-item">
                <p>
                  회사는 이용자의 개인정보 관련 문의사항 및 불만 처리 등을 위하여 개인정보
                  보호책임자를 지정하고 있습니다. 이용자는 회사의 서비스를 이용하며 발생하는 모든
                  개인정보보호 관련 민원을 개인정보보호책임자 혹은 담당부서로 신고하실 수 있습니다.
                </p>
                <div className="terms-box">
                  <p className="title">개인정보관리 책임부서 및 보호책임자</p>
                  <ul className="dot-list-group">
                    <li className="list-item">이름 : 유신홍</li>
                    <li className="list-item">직위 : Product Officer</li>
                    <li className="list-item">메일 : sean@pharma-bros.com</li>
                    <li className="list-item">전화 : 010-3705-7325</li>
                  </ul>
                </div>
              </li>
              <li className="list-item">
                <p>
                  기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기
                  바랍니다.
                </p>
                <ul className="dot-list-group">
                  <li className="list-item">
                    개인정보침해신고센터 (
                    <Link className="link" target="_blank" to="http://kisa.or.kr">
                      kisa.or.kr
                    </Link>
                    / 국번없이 118)
                  </li>
                  <li className="list-item">
                    개인정보분쟁조정위원회 (
                    <Link className="link" target="_blank" to="http://kopico.go.kr">
                      kopico.go.kr
                    </Link>
                    / 1833-6972)
                  </li>
                  <li className="list-item">
                    대검찰청 사이버범죄수사단 (
                    <Link className="link" target="_blank" to="http://spo.go.kr">
                      spo.go.kr
                    </Link>
                    / 국번없이 1301)
                  </li>
                  <li className="list-item">
                    경찰청 사이버안전국 (
                    <Link className="link" target="_blank" to="http://cyberbureau.police.go.kr">
                      cyberbureau.police.go.kr
                    </Link>
                    / 국번없이 182)
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className="list-item">
            <p className="title">제12조 (개인정보처리방침의 변경에 관한 사항)</p>
            <p>이 개인정보처리방침은 아래와 같이 변경되었으며 2023년 4월 11일부터 시행합니다.</p>
            <ul className="dot-list-group">
              <li className="list-item">공고일자 : 2024년 4월 25일</li>
              <li className="list-item">시행일자 : 2024년 4월 25일</li>
              <li className="list-item">개인정보처리방침 버전 1.2</li>
            </ul>
          </li>
        </ul>
      </article>
    </>
  );
};
