import { RenderDesktop } from '@components/domain/friendly/render-desktop';
import { RenderMobile } from '@components/domain/friendly/render-mobile';

export const FriendlyTemplate = () => {
  const isDesktop = window.innerWidth >= 1200; // pagination 이 달라 분기
  const limit = window.innerWidth >= 905 ? 20 : 12;
  const initParams = {
    type: 'group_buying',
    value: { orderColumn: 'sale_count', order: 'desc' },
    offset: 0,
    limit,
  };

  return (
    <section>
      {isDesktop ? (
        <RenderDesktop initParams={initParams} />
      ) : (
        <RenderMobile initParams={initParams} />
      )}
    </section>
  );
};
