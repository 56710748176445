import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';

export function useFetchBestTen() {
  const getBestTen = async () => {
    return await get('/api/commerce/v1/products/best10').then(res => res.data);
  };

  return useQuery({
    queryKey: ['useFetchBestTen'],
    queryFn: getBestTen,
  });
}
