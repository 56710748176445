import { Link } from 'react-router-dom';

import { MENU_LIST } from '@/constants/path';

interface MySidebarDesktopProps {
  pathname: string;
  handleClickAddressManage: () => void;
}
export const MySidebarDesktop = ({ pathname, handleClickAddressManage }: MySidebarDesktopProps) => {
  return (
    <div className="desktop-my-sidebar">
      <div className="title">마이</div>
      <ul className="my-group-list">
        {/* <!-- 구매정보 --> */}
        {MENU_LIST.map(area => (
          <li className="my-group-item" key={area?.title}>
            <div className="label">{area.title}</div>
            <ul className="link-group-list">
              {area.list.map(item => (
                <li key={item.title}>
                  <Link
                    className={`link-item${pathname.includes(item.path) ? ' active' : ''}`}
                    to={item.path}
                    onClick={() => item.path === '#' && handleClickAddressManage()}
                    state={{ from: 'my_lnb' }}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};
