import { useState } from 'react';

import { SORT_LIST } from '@/constants/sort';

interface SortValue {
  title: string;
  value: {
    orderColumn: string;
    order: string;
  };
}

interface SortProps {
  total: number;
  selected: string;
  handleClickSort: (value: SortValue) => void;
}

export const Sort = ({ total, handleClickSort, selected }: SortProps) => {
  const isMobile = window.innerWidth < 905; // 필터 modal 기준
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const onCloseModal = () => {
    setIsFilterOpen(false);
  };

  return (
    <div id="load-list-filter">
      <div className="product-list-filter" id="list-filter">
        <div className="result-value">전체 {total}개</div>
        <div className="mobile-list-sorting">
          <button
            type="button"
            className={`btn btn-subtle btn-sorting active`}
            onClick={() => {
              isMobile && setIsFilterOpen(true);
            }}
          >
            {selected}
          </button>
        </div>

        <ul className="desktop-list-sorting">
          {SORT_LIST.map(item => (
            <li key={`sort_${item.title}`}>
              {/* <!-- 선택된 값 active 클래스로 조정 --> */}
              <button
                type="button"
                className={`btn btn-subtle btn-sorting ${selected === item.title ? 'active' : ''}`}
                onClick={() => {
                  handleClickSort(item);
                }}
              >
                {item.title}
              </button>
            </li>
          ))}
        </ul>
      </div>
      {isFilterOpen && (
        <div>
          <div className="modal-background" onClick={onCloseModal}>
            <div className="modal-bottom" onClick={e => e.stopPropagation()}>
              <div className="handle"></div>
              <ul className="sort-list">
                {SORT_LIST.map(item => (
                  <li
                    key={`sort_${item.title}`}
                    onClick={() => {
                      handleClickSort(item);
                      onCloseModal();
                    }}
                  >
                    <label className="checkbox-group">
                      <input
                        type="radio"
                        name="test-1"
                        checked={selected === item.title}
                        readOnly
                      />
                      <span className="label">{item.title}</span>
                      <span className="checkmark"></span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
