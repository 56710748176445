/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * CommerceApi
 * #### HTTP Status Code
* 200; 정상
* 400; 처리 실패, 오류 코드 및 오류 메세지 확인
* 401; 인증 오류, 제출한 토큰을 통한 인증 실패
* 403; 권한 오류, 로그인을 통해 적절한 토큰 제출 필요
* 500; 내부 오류, 관리자 도움 요청


 * OpenAPI spec version: 1.0
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type { ErrorType } from '../../config/axios-instance';
import { axiosInstance } from '../../config/axios-instance';
import type {
  GetReviewsParams,
  WoEmptyRes,
  WoSelfAddressCreatePostReq,
  WoSelfAddressCreatePostRes,
  WoSelfAddressesGetRes,
  WoSelfAddressUpdatePostReq,
  WoSelfAddressUpdatePostRes,
  WoSelfDetailGetRes,
  WoSelfInputKwordCreatePostReq,
  WoSelfInputKwordCreatePostRes,
  WoSelfInputKwordDeleteMatchedPostReq,
  WoSelfInputKwordDeletePostRes,
  WoSelfInputKwordsGetRes,
  WoSelfOrderStatusGetRes,
  WoSelfProductsBest10GetRes,
  WoSelfReviewsGetRes,
  WoSelfSearchProductCreatePostReq,
  WoSelfSearchProductsGetRes,
  WoSelfSnsConsentUpdatePostReq,
  WoSelfSnsConsentUpdatePostRes,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * #### Description
* 사용자 주소 수정

#### Required Privilege
* 로그인된 사용자

 */
export const updateAddress = (
  addressId: string,
  woSelfAddressUpdatePostReq: WoSelfAddressUpdatePostReq,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<WoSelfAddressUpdatePostRes>(
    {
      url: `/api/commerce/v1/self/user-addresses/${addressId}/update`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      data: woSelfAddressUpdatePostReq,
    },
    options,
  );
};

export const getUpdateAddressMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAddress>>,
    TError,
    { addressId: string; data: WoSelfAddressUpdatePostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAddress>>,
  TError,
  { addressId: string; data: WoSelfAddressUpdatePostReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAddress>>,
    { addressId: string; data: WoSelfAddressUpdatePostReq }
  > = props => {
    const { addressId, data } = props ?? {};

    return updateAddress(addressId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAddressMutationResult = NonNullable<Awaited<ReturnType<typeof updateAddress>>>;
export type UpdateAddressMutationBody = WoSelfAddressUpdatePostReq;
export type UpdateAddressMutationError = ErrorType<unknown>;

export const useUpdateAddress = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAddress>>,
    TError,
    { addressId: string; data: WoSelfAddressUpdatePostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAddress>>,
  TError,
  { addressId: string; data: WoSelfAddressUpdatePostReq },
  TContext
> => {
  const mutationOptions = getUpdateAddressMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * #### Description
* 사용자 주소 삭제

#### Required Privilege
* 로그인된 사용자

 */
export const deleteAddress = (
  addressId: string,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<WoSelfAddressUpdatePostRes>(
    { url: `/api/commerce/v1/self/user-addresses/${addressId}/delete`, method: 'POST' },
    options,
  );
};

export const getDeleteAddressMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAddress>>,
    TError,
    { addressId: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAddress>>,
  TError,
  { addressId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAddress>>,
    { addressId: string }
  > = props => {
    const { addressId } = props ?? {};

    return deleteAddress(addressId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAddressMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAddress>>>;

export type DeleteAddressMutationError = ErrorType<unknown>;

export const useDeleteAddress = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAddress>>,
    TError,
    { addressId: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteAddress>>,
  TError,
  { addressId: string },
  TContext
> => {
  const mutationOptions = getDeleteAddressMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * #### Description
* 사용자 주소 등록

#### Required Privilege
* 로그인된 사용자

 */
export const createAddress = (
  woSelfAddressCreatePostReq: WoSelfAddressCreatePostReq,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<WoSelfAddressCreatePostRes>(
    {
      url: `/api/commerce/v1/self/user-addresses/create`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      data: woSelfAddressCreatePostReq,
    },
    options,
  );
};

export const getCreateAddressMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAddress>>,
    TError,
    { data: WoSelfAddressCreatePostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createAddress>>,
  TError,
  { data: WoSelfAddressCreatePostReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createAddress>>,
    { data: WoSelfAddressCreatePostReq }
  > = props => {
    const { data } = props ?? {};

    return createAddress(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateAddressMutationResult = NonNullable<Awaited<ReturnType<typeof createAddress>>>;
export type CreateAddressMutationBody = WoSelfAddressCreatePostReq;
export type CreateAddressMutationError = ErrorType<unknown>;

export const useCreateAddress = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAddress>>,
    TError,
    { data: WoSelfAddressCreatePostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createAddress>>,
  TError,
  { data: WoSelfAddressCreatePostReq },
  TContext
> => {
  const mutationOptions = getCreateAddressMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * #### Description
* 정보 수신 동의 수정

#### Required Privilege
* 로그인된 사용자

 */
export const updateUserSnsConsentYn = (
  woSelfSnsConsentUpdatePostReq: WoSelfSnsConsentUpdatePostReq,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<WoSelfSnsConsentUpdatePostRes>(
    {
      url: `/api/commerce/v1/self/sns_consent/update`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      data: woSelfSnsConsentUpdatePostReq,
    },
    options,
  );
};

export const getUpdateUserSnsConsentYnMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserSnsConsentYn>>,
    TError,
    { data: WoSelfSnsConsentUpdatePostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUserSnsConsentYn>>,
  TError,
  { data: WoSelfSnsConsentUpdatePostReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserSnsConsentYn>>,
    { data: WoSelfSnsConsentUpdatePostReq }
  > = props => {
    const { data } = props ?? {};

    return updateUserSnsConsentYn(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserSnsConsentYnMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUserSnsConsentYn>>
>;
export type UpdateUserSnsConsentYnMutationBody = WoSelfSnsConsentUpdatePostReq;
export type UpdateUserSnsConsentYnMutationError = ErrorType<unknown>;

export const useUpdateUserSnsConsentYn = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserSnsConsentYn>>,
    TError,
    { data: WoSelfSnsConsentUpdatePostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateUserSnsConsentYn>>,
  TError,
  { data: WoSelfSnsConsentUpdatePostReq },
  TContext
> => {
  const mutationOptions = getUpdateUserSnsConsentYnMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * #### Description
* 사용자 조회 상품 목록 삭제

#### Required Privilege
* 로그인 사용자

 */
export const deleteSearchProducts = (options?: SecondParameter<typeof axiosInstance>) => {
  return axiosInstance<WoEmptyRes>(
    { url: `/api/commerce/v1/self/search-products/delete`, method: 'POST' },
    options,
  );
};

export const getDeleteSearchProductsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSearchProducts>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSearchProducts>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSearchProducts>>,
    void
  > = () => {
    return deleteSearchProducts(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSearchProductsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSearchProducts>>
>;

export type DeleteSearchProductsMutationError = ErrorType<unknown>;

export const useDeleteSearchProducts = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSearchProducts>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteSearchProducts>>, TError, void, TContext> => {
  const mutationOptions = getDeleteSearchProductsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * #### Description
* 사용자 조회 상품 목록 생성

#### Required Privilege
* 로그인|비로그인 사용자

 */
export const createSearchProduct = (
  woSelfSearchProductCreatePostReq: WoSelfSearchProductCreatePostReq,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<WoEmptyRes>(
    {
      url: `/api/commerce/v1/self/search-products/create`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      data: woSelfSearchProductCreatePostReq,
    },
    options,
  );
};

export const getCreateSearchProductMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSearchProduct>>,
    TError,
    { data: WoSelfSearchProductCreatePostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createSearchProduct>>,
  TError,
  { data: WoSelfSearchProductCreatePostReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createSearchProduct>>,
    { data: WoSelfSearchProductCreatePostReq }
  > = props => {
    const { data } = props ?? {};

    return createSearchProduct(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateSearchProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof createSearchProduct>>
>;
export type CreateSearchProductMutationBody = WoSelfSearchProductCreatePostReq;
export type CreateSearchProductMutationError = ErrorType<unknown>;

export const useCreateSearchProduct = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSearchProduct>>,
    TError,
    { data: WoSelfSearchProductCreatePostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createSearchProduct>>,
  TError,
  { data: WoSelfSearchProductCreatePostReq },
  TContext
> => {
  const mutationOptions = getCreateSearchProductMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * #### Description
* 해당 유저의 모든 검색 키워드를 삭제

#### Required Privilege
* 로그인된 사용자

 */
export const deleteUserInputKeyword = (options?: SecondParameter<typeof axiosInstance>) => {
  return axiosInstance<WoSelfInputKwordDeletePostRes>(
    { url: `/api/commerce/v1/self/input_kwords/delete`, method: 'POST' },
    options,
  );
};

export const getDeleteUserInputKeywordMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserInputKeyword>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserInputKeyword>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserInputKeyword>>,
    void
  > = () => {
    return deleteUserInputKeyword(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserInputKeywordMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserInputKeyword>>
>;

export type DeleteUserInputKeywordMutationError = ErrorType<unknown>;

export const useDeleteUserInputKeyword = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserInputKeyword>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserInputKeyword>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getDeleteUserInputKeywordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * #### Description
* match와 일치하는 모든 검색 키워드를 삭제

#### Required Privilege
* 로그인된 사용자

 */
export const deleteUserInputKeywordMatched = (
  woSelfInputKwordDeleteMatchedPostReq: WoSelfInputKwordDeleteMatchedPostReq,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<WoSelfInputKwordDeletePostRes>(
    {
      url: `/api/commerce/v1/self/input_kwords/delete-matched`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      data: woSelfInputKwordDeleteMatchedPostReq,
    },
    options,
  );
};

export const getDeleteUserInputKeywordMatchedMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserInputKeywordMatched>>,
    TError,
    { data: WoSelfInputKwordDeleteMatchedPostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserInputKeywordMatched>>,
  TError,
  { data: WoSelfInputKwordDeleteMatchedPostReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserInputKeywordMatched>>,
    { data: WoSelfInputKwordDeleteMatchedPostReq }
  > = props => {
    const { data } = props ?? {};

    return deleteUserInputKeywordMatched(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserInputKeywordMatchedMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserInputKeywordMatched>>
>;
export type DeleteUserInputKeywordMatchedMutationBody = WoSelfInputKwordDeleteMatchedPostReq;
export type DeleteUserInputKeywordMatchedMutationError = ErrorType<unknown>;

export const useDeleteUserInputKeywordMatched = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserInputKeywordMatched>>,
    TError,
    { data: WoSelfInputKwordDeleteMatchedPostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserInputKeywordMatched>>,
  TError,
  { data: WoSelfInputKwordDeleteMatchedPostReq },
  TContext
> => {
  const mutationOptions = getDeleteUserInputKeywordMatchedMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * #### Description
* 검색 키워드 등록

#### Required Privilege
* 로그인된 사용자

 */
export const createUserInputKeyword = (
  woSelfInputKwordCreatePostReq: WoSelfInputKwordCreatePostReq,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<WoSelfInputKwordCreatePostRes>(
    {
      url: `/api/commerce/v1/self/input_kwords/create`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      data: woSelfInputKwordCreatePostReq,
    },
    options,
  );
};

export const getCreateUserInputKeywordMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUserInputKeyword>>,
    TError,
    { data: WoSelfInputKwordCreatePostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createUserInputKeyword>>,
  TError,
  { data: WoSelfInputKwordCreatePostReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createUserInputKeyword>>,
    { data: WoSelfInputKwordCreatePostReq }
  > = props => {
    const { data } = props ?? {};

    return createUserInputKeyword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateUserInputKeywordMutationResult = NonNullable<
  Awaited<ReturnType<typeof createUserInputKeyword>>
>;
export type CreateUserInputKeywordMutationBody = WoSelfInputKwordCreatePostReq;
export type CreateUserInputKeywordMutationError = ErrorType<unknown>;

export const useCreateUserInputKeyword = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUserInputKeyword>>,
    TError,
    { data: WoSelfInputKwordCreatePostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createUserInputKeyword>>,
  TError,
  { data: WoSelfInputKwordCreatePostReq },
  TContext
> => {
  const mutationOptions = getCreateUserInputKeywordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * #### Description
* 사용자 주소 조회

#### Required Privilege
* 로그인된 사용자

 */
export const getAddresses = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<WoSelfAddressesGetRes>(
    { url: `/api/commerce/v1/self/user-addresses`, method: 'GET', signal },
    options,
  );
};

export const getGetAddressesQueryKey = () => {
  return [`/api/commerce/v1/self/user-addresses`] as const;
};

export const getGetAddressesQueryOptions = <
  TData = Awaited<ReturnType<typeof getAddresses>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAddresses>>, TError, TData>>;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAddressesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAddresses>>> = ({ signal }) =>
    getAddresses(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAddresses>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAddressesQueryResult = NonNullable<Awaited<ReturnType<typeof getAddresses>>>;
export type GetAddressesQueryError = ErrorType<unknown>;

export function useGetAddresses<
  TData = Awaited<ReturnType<typeof getAddresses>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAddresses>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof getAddresses>>, TError, TData>,
      'initialData'
    >;
  request?: SecondParameter<typeof axiosInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAddresses<
  TData = Awaited<ReturnType<typeof getAddresses>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAddresses>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof getAddresses>>, TError, TData>,
      'initialData'
    >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAddresses<
  TData = Awaited<ReturnType<typeof getAddresses>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAddresses>>, TError, TData>>;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAddresses<
  TData = Awaited<ReturnType<typeof getAddresses>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAddresses>>, TError, TData>>;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAddressesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * #### Description
* 사용자 조회 상품 목록 조회

#### Required Privilege
* 로그인|비로그인 사용자

 */
export const getSearchProducts = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<WoSelfSearchProductsGetRes>(
    { url: `/api/commerce/v1/self/search-products`, method: 'GET', signal },
    options,
  );
};

export const getGetSearchProductsQueryKey = () => {
  return [`/api/commerce/v1/self/search-products`] as const;
};

export const getGetSearchProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof getSearchProducts>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchProducts>>, TError, TData>>;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSearchProductsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSearchProducts>>> = ({ signal }) =>
    getSearchProducts(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSearchProducts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSearchProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSearchProducts>>
>;
export type GetSearchProductsQueryError = ErrorType<unknown>;

export function useGetSearchProducts<
  TData = Awaited<ReturnType<typeof getSearchProducts>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchProducts>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof getSearchProducts>>, TError, TData>,
      'initialData'
    >;
  request?: SecondParameter<typeof axiosInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetSearchProducts<
  TData = Awaited<ReturnType<typeof getSearchProducts>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchProducts>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof getSearchProducts>>, TError, TData>,
      'initialData'
    >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetSearchProducts<
  TData = Awaited<ReturnType<typeof getSearchProducts>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchProducts>>, TError, TData>>;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetSearchProducts<
  TData = Awaited<ReturnType<typeof getSearchProducts>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchProducts>>, TError, TData>>;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetSearchProductsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * #### Description
* 사용자 작성 리뷰 조회

#### Required Privilege
* 로그인된 사용자

 */
export const getReviews = (
  params: GetReviewsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<WoSelfReviewsGetRes>(
    { url: `/api/commerce/v1/self/reviews`, method: 'GET', params, signal },
    options,
  );
};

export const getGetReviewsQueryKey = (params: GetReviewsParams) => {
  return [`/api/commerce/v1/self/reviews`, ...(params ? [params] : [])] as const;
};

export const getGetReviewsQueryOptions = <
  TData = Awaited<ReturnType<typeof getReviews>>,
  TError = ErrorType<unknown>,
>(
  params: GetReviewsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReviews>>, TError, TData>>;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReviewsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReviews>>> = ({ signal }) =>
    getReviews(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReviews>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReviewsQueryResult = NonNullable<Awaited<ReturnType<typeof getReviews>>>;
export type GetReviewsQueryError = ErrorType<unknown>;

export function useGetReviews<
  TData = Awaited<ReturnType<typeof getReviews>>,
  TError = ErrorType<unknown>,
>(
  params: GetReviewsParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReviews>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getReviews>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReviews<
  TData = Awaited<ReturnType<typeof getReviews>>,
  TError = ErrorType<unknown>,
>(
  params: GetReviewsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReviews>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getReviews>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReviews<
  TData = Awaited<ReturnType<typeof getReviews>>,
  TError = ErrorType<unknown>,
>(
  params: GetReviewsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReviews>>, TError, TData>>;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetReviews<
  TData = Awaited<ReturnType<typeof getReviews>>,
  TError = ErrorType<unknown>,
>(
  params: GetReviewsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReviews>>, TError, TData>>;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetReviewsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * #### Description
* best10 추천 상품 목록

#### Required Privilege
* 로그인된 사용자

 */
export const getUser = (options?: SecondParameter<typeof axiosInstance>, signal?: AbortSignal) => {
  return axiosInstance<WoSelfProductsBest10GetRes>(
    { url: `/api/commerce/v1/self/products/best10`, method: 'GET', signal },
    options,
  );
};

export const getGetUserQueryKey = () => {
  return [`/api/commerce/v1/self/products/best10`] as const;
};

export const getGetUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({ signal }) =>
    getUser(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>;
export type GetUserQueryError = ErrorType<unknown>;

export function useGetUser<
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>,
      'initialData'
    >;
  request?: SecondParameter<typeof axiosInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUser<
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>,
      'initialData'
    >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUser<
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetUser<
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUserQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * #### Description
* 장바구니 및 주문 상태 조회

#### Required Privilege
* 로그인된 사용자

 */
export const getOrderStatus = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<WoSelfOrderStatusGetRes>(
    { url: `/api/commerce/v1/self/order-status`, method: 'GET', signal },
    options,
  );
};

export const getGetOrderStatusQueryKey = () => {
  return [`/api/commerce/v1/self/order-status`] as const;
};

export const getGetOrderStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrderStatus>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrderStatus>>, TError, TData>>;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOrderStatusQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrderStatus>>> = ({ signal }) =>
    getOrderStatus(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrderStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrderStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getOrderStatus>>>;
export type GetOrderStatusQueryError = ErrorType<unknown>;

export function useGetOrderStatus<
  TData = Awaited<ReturnType<typeof getOrderStatus>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrderStatus>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof getOrderStatus>>, TError, TData>,
      'initialData'
    >;
  request?: SecondParameter<typeof axiosInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOrderStatus<
  TData = Awaited<ReturnType<typeof getOrderStatus>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrderStatus>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof getOrderStatus>>, TError, TData>,
      'initialData'
    >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOrderStatus<
  TData = Awaited<ReturnType<typeof getOrderStatus>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrderStatus>>, TError, TData>>;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetOrderStatus<
  TData = Awaited<ReturnType<typeof getOrderStatus>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrderStatus>>, TError, TData>>;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetOrderStatusQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * #### Description
* 검색 키워드 목록 조회

#### Required Privilege
* 로그인된 사용자

 */
export const getUserInputKwords = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<WoSelfInputKwordsGetRes>(
    { url: `/api/commerce/v1/self/input_kwords`, method: 'GET', signal },
    options,
  );
};

export const getGetUserInputKwordsQueryKey = () => {
  return [`/api/commerce/v1/self/input_kwords`] as const;
};

export const getGetUserInputKwordsQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserInputKwords>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserInputKwords>>, TError, TData>>;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserInputKwordsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserInputKwords>>> = ({ signal }) =>
    getUserInputKwords(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserInputKwords>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserInputKwordsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserInputKwords>>
>;
export type GetUserInputKwordsQueryError = ErrorType<unknown>;

export function useGetUserInputKwords<
  TData = Awaited<ReturnType<typeof getUserInputKwords>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserInputKwords>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof getUserInputKwords>>, TError, TData>,
      'initialData'
    >;
  request?: SecondParameter<typeof axiosInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserInputKwords<
  TData = Awaited<ReturnType<typeof getUserInputKwords>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserInputKwords>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof getUserInputKwords>>, TError, TData>,
      'initialData'
    >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserInputKwords<
  TData = Awaited<ReturnType<typeof getUserInputKwords>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserInputKwords>>, TError, TData>>;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetUserInputKwords<
  TData = Awaited<ReturnType<typeof getUserInputKwords>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserInputKwords>>, TError, TData>>;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUserInputKwordsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * #### Description
* 사용자 정보 조회

#### Required Privilege
* 게스트, 로그인

 */
export const getSelf = (options?: SecondParameter<typeof axiosInstance>, signal?: AbortSignal) => {
  return axiosInstance<WoSelfDetailGetRes>(
    { url: `/api/commerce/v1/self/detail`, method: 'GET', signal },
    options,
  );
};

export const getGetSelfQueryKey = () => {
  return [`/api/commerce/v1/self/detail`] as const;
};

export const getGetSelfQueryOptions = <
  TData = Awaited<ReturnType<typeof getSelf>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSelf>>, TError, TData>>;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSelfQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSelf>>> = ({ signal }) =>
    getSelf(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSelf>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSelfQueryResult = NonNullable<Awaited<ReturnType<typeof getSelf>>>;
export type GetSelfQueryError = ErrorType<unknown>;

export function useGetSelf<
  TData = Awaited<ReturnType<typeof getSelf>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSelf>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof getSelf>>, TError, TData>,
      'initialData'
    >;
  request?: SecondParameter<typeof axiosInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetSelf<
  TData = Awaited<ReturnType<typeof getSelf>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSelf>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof getSelf>>, TError, TData>,
      'initialData'
    >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetSelf<
  TData = Awaited<ReturnType<typeof getSelf>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSelf>>, TError, TData>>;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetSelf<
  TData = Awaited<ReturnType<typeof getSelf>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSelf>>, TError, TData>>;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetSelfQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
