import { useState } from 'react';
import { PrdListPaginationMobile } from '@components/domain/main/prd-list-pagination-mobile';
import { Sort } from '@components/domain/main/sort';

import { useInfinitelyFetchProductList } from '@/apis/commerce/products/useInfinitelyFetchProductList';
import { useSearchUrl } from '@/hooks/use-search-url';

interface RenderMobileProps {
  initParams: {
    type: string;
    value: { orderColumn: string; order: string };
    offset: number;
    limit: number;
  };
}
export const RenderMobile = ({ initParams }: RenderMobileProps) => {
  const { getStateBySearchParams } = useSearchUrl();
  const categoryId = getStateBySearchParams('category_id') || '';
  const ingredientId = getStateBySearchParams('ingredient_id') || '';
  const brandId = getStateBySearchParams('brand_id') || '';
  const limit = initParams.limit;
  const [order, setOrder] = useState({
    title: '판매인기순',
    value: { orderColumn: 'sale_count', order: 'asc' },
  });

  const {
    data: infinitePrdList,
    fetchNextPage,
    hasNextPage,
  } = useInfinitelyFetchProductList({
    initialPageParam: 0,
    limit,
    ingredientId,
    brandId,
    categoryId,
    value: order.value,
    collectionTypeCode: initParams.type,
  });
  const totalCount = infinitePrdList?.pages[0]?.total ?? 0;

  const handleChange = (value: {
    title: string;
    value: { orderColumn: string; order: string };
  }) => {
    setOrder(value);
  };

  const handleClickNextPage = async () => {
    await fetchNextPage();
  };

  return infinitePrdList?.pages === undefined ? (
    <></>
  ) : (
    <>
      <Sort total={totalCount} selected={order.title} handleClickSort={handleChange} />
      <PrdListPaginationMobile
        infinitePrdList={infinitePrdList}
        hasNextPage={hasNextPage}
        handleClickNextPage={handleClickNextPage}
      />
    </>
  );
};
