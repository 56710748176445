interface JumbotronProps {
  title: string;
}
export const Jumbotron = ({ title }: JumbotronProps) => {
  return (
    <section className="desktop-jumbotron section">
      <div className="section-title">
        <div className="label">{title}</div>
      </div>
    </section>
  );
};
