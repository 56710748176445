import { ChangeEvent, MouseEvent, useState } from 'react';

import { useFetchGetUserInfo } from '@/apis/friendly-pharmacist/user/useFetchGetUserInfo';
import { useMutationUserUpdate } from '@/apis/friendly-pharmacist/user/useMutationUserUpdate';
import useLoginInfo from '@/hooks/use-login-info';
import { ToastData } from '@/types/toast';
import { UpdateUserDataParams } from '@/types/user';
import { dateToStringFormat } from '@/utils/formats';
import { validation } from '@/utils/validations';

export default function useMyInfo() {
  const { userToken } = useLoginInfo();
  // 친한약사 API
  const { data: userInfo, refetch: refetchUserInfo } = useFetchGetUserInfo({
    accessToken: userToken,
    options: { enabled: !!userToken },
  });

  const [modifyStatus, setModifyStatus] = useState(''); // email, nickname
  const [values, setValues] = useState({
    nickname: userInfo?.user_info?.nickname || '',
    email: userInfo?.user_info?.email || '',
    phone: userInfo?.user_info?.phone || '',
  });
  const [isNicknameValid, setIsNicknameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const { mutate: mutateUpdateUser } = useMutationUserUpdate();

  const [toastState, setToastState] = useState<{
    isShow: boolean;
    message: string;
    subMsgs?: string[];
    toastType: string;
  }>({
    isShow: false,
    message: '',
    subMsgs: [''],
    toastType: '',
  });

  const toggleToast = () => setToastState(pre => ({ ...pre, isShow: !pre.isShow }));

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    if (
      name === 'nickname' &&
      !validation('emoji', value) &&
      !validation('special', value) &&
      value.length <= 10
    ) {
      setValues({ ...values, [name]: value.trim() });
      value.length >= 2 ? setIsNicknameValid(true) : setIsNicknameValid(false);
    } else if (name === 'email') {
      setValues({ ...values, [name]: value.trim() });
      setIsEmailValid(validation('email', value));
    }
  };

  const modifyStatusInit = () => setModifyStatus('');

  const handleModifyNicknameBtnClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    modifyStatus === '' ? setModifyStatus(e.currentTarget.name) : modifyStatusInit(); // 수정햐기 post api로 수정
  };

  const handleUpdateUserData = (
    data: UpdateUserDataParams,
    toastData?: ToastData,
    onSuccess?: () => void,
  ) => {
    modifyStatusInit();
    mutateUpdateUser(data, {
      onSuccess: async () => {
        if (onSuccess) {
          onSuccess();
        } else {
          await refetchUserInfo();
          toastData && setToastState({ ...toastData, isShow: true });
        }
      },
    });
  };

  const handleClickCheckbox = (e: MouseEvent<HTMLInputElement>) => {
    const { name, checked } = e.currentTarget;
    const agreedTo =
      name === 'all'
        ? '광고성 정보 수신'
        : name === 'sms'
        ? 'SMS 알림'
        : name === 'email'
        ? '이메일 알림'
        : '푸시 알림';
    const toastState = {
      message: '파마브로스(주) 광고성 정보 수신 안내',
      subMsgs: [
        `${dateToStringFormat(new Date(), 'dot')} ${agreedTo} 동의가`,
        `정상적으로 ${checked ? '처리' : '거부'}되었습니다`,
      ],
      toastType: 'marketing',
    };
    if (name === 'all') {
      handleUpdateUserData(
        {
          agreements: {
            sms: checked,
            email: checked,
            push: checked,
          },
        },
        toastState,
      );
    } else {
      const currentState = {
        sms: userInfo?.user_info?.agreements?.sms?.value ?? false,
        email: userInfo?.user_info?.agreements?.email?.value ?? false,
        push: userInfo?.user_info?.agreements?.push?.value ?? false,
      };
      handleUpdateUserData({ agreements: { ...currentState, [name]: checked } }, toastState);
    }
  };

  return {
    userInfo,
    modifyStatus,
    values,
    isEmailValid,
    isNicknameValid,
    toastState,
    setToastState,
    toggleToast,
    refetchUserInfo,
    handleModifyNicknameBtnClick,
    handleChange,
    handleUpdateUserData,
    handleClickCheckbox,
  };
}
