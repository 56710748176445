import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

export function useMutationCartCounter() {
  const updateCartCount = async ({
    optionIdx,
    quantity,
  }: {
    optionIdx: number;
    quantity: number;
  }) => {
    await post('/api/commerce/v1/cart/items/update', {
      update_items: [{ user_cart_option_idx: optionIdx, quantity }],
    });
  };
  return useMutation({ mutationFn: updateCartCount });
}
