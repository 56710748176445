export const TERMS_LIST = [
  { id: 1, name: 'terms', description: '[필수] 이용약관' },
  { id: 2, name: 'privacy', description: '[필수] 개인정보 처리방침' },
  { id: 3, name: 'adsTotal', description: '[선택] 광고성 정보 수신 동의' }, // SMS, email, push
  { id: 4, name: 'sms', description: 'SMS' },
  { id: 5, name: 'email', description: '이메일' },
  { id: 6, name: 'push', description: '푸시' },
];
export const ADS_TOTAL = 3;
export const ADS = [3, 4, 5, 6]; // 광고성 정보 수신 동의 묶음
export const REQUIRED_TERMS = [1, 2]; // 필수 약관
