import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AlertModal } from '@components/common/molecules/modal/alert-modal';
import { ResetPasswordForm } from '@components/domain/register/reset-password-form';
import { ResetPasswordRequestForm } from '@components/domain/register/reset-password-request-form';

import { useFetchTokenValidation } from '@/apis/friendly-pharmacist/user/useFetchTokenValidation';
import logo from '@/assets/ic/brand-logo-wt-integrate.svg';
import { useSearchUrl } from '@/hooks/use-search-url';

interface ResetPasswordTemplateProps {}
export const ResetPasswordTemplate = ({}: ResetPasswordTemplateProps) => {
  const [alertState, setAlertState] = useState<{
    isAlertVisible: boolean;
    header?: string[];
    body: string[];
    buttons: [{ title: string; handleClick: () => void; style: string }];
  }>({
    isAlertVisible: false,
    header: [''],
    body: [''],
    buttons: [{ title: '', handleClick: () => {}, style: 'primary' }],
  });
  const { getStateBySearchParams } = useSearchUrl();
  const uuid = getStateBySearchParams('uuid') ?? '';
  const token = getStateBySearchParams('token') ?? '';
  const {
    isError: isTokenNotValid,
    isFetched: isValidationFetched,
    refetch: refetchValidation,
  } = useFetchTokenValidation({
    uuid,
    token,
    options: { enabled: !!uuid && !!token },
  });
  const deleteSearchParams = () => {
    // deleteBySearchParams('uuid');
    // deleteBySearchParams('token');
    refetchValidation();
  };
  const closeAlert = () => {
    setAlertState({
      isAlertVisible: false,
      header: [''],
      body: [''],
      buttons: [{ title: '', handleClick: () => {}, style: 'primary' }],
    });
  };

  return (
    <>
      <nav id="nav-integrate" className="navbar blank">
        <Link className="navbar-brand" to="#">
          <img src={logo} alt="" />
        </Link>
      </nav>
      <div className="blank-container">
        <section className="jumbotron divider-centered">
          <span className="title">비밀번호 재설정</span>
          <span className="sub-text">
            친한스토어와 친한약사 통합계정 이용을 위해 <br />
            비밀번호를 바꿔주세요.
          </span>
        </section>
        {token && uuid && isValidationFetched && !isTokenNotValid ? (
          <ResetPasswordForm
            uuid={uuid}
            token={token}
            setAlertState={setAlertState}
            deleteSearchParams={deleteSearchParams}
            closeAlert={closeAlert}
          />
        ) : (
          <ResetPasswordRequestForm setAlertState={setAlertState} closeAlert={closeAlert} />
        )}
        {alertState.isAlertVisible && (
          <AlertModal
            isOpen={alertState.isAlertVisible}
            body={alertState.body}
            buttons={alertState.buttons}
          />
        )}
        <p className="blank-copyright">Copyright 2022. pharma-bros inc. all rights reserved.</p>
      </div>
    </>
  );
};
