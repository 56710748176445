import { PATH } from '@/constants/path';
import queryString from 'query-string';
import { useEffect } from 'react';
import Lottie from 'react-lottie';
import brandIc from '@/assets/ic/brand-logo-wt.svg';
import browserImg from '@/assets/img/browser.json';
import { Link } from 'react-router-dom';

/**
 *  AOS 인스타그램 웹뷰에서 pass 인증 완료 이후 redirect 안되는 이슈로
 *  AOS 로그인 이력 없는 유저는 외부 브라우저로 열도록 처리
 */
export const CallbackInstagramPage = () => {
  const { before_url } = queryString.parse(location.search);

  const { pathname, host } = window.location;
  const androidBrowserIntent = `intent://${host}${before_url}#Intent;scheme=https;end;`;
  useEffect(() => {
    // const redirectUrl = href.split(`${protocol}//`)[1];

    try {
      // Chrome으로 열기 시도
      window.location.href = androidBrowserIntent;
    } catch (e) {
      // 기본 브라우저로 열기
      console.error('Failed to open Chrome. Redirecting to default browser.');
      // alert(`catch:: ${(e as any)?.response?.data?.message}`);
      if (pathname === PATH.REGISTER) {
        alert('cancel! & register page');
        window.history.back();
      }
    }
  }, []);

  const openExternalBrowser = () => {
    window.location.href = androidBrowserIntent;
  };

  return (
    <>
      <nav id="nav-closure-error" className="navbar closure">
        <div className="fluid-main">
          <div className="contents-wrap">
            <Link className="navbar-brand" to="#" onClick={e => e.preventDefault()}>
              <img src={brandIc} alt="" />
            </Link>
          </div>
        </div>
      </nav>
      <div className="global-container closure">
        <div className="error-col">
          <div className="img">
            <Lottie
              options={{
                loop: true,
                // Autoplay: true,
                animationData: browserImg,
              }}
              width={100}
              height={100}
            />
          </div>

          <div className="title">페이지를 사용할 수 없어요</div>
          <div className="sub-title">
            안드로이드 사용자는 <br />
            인스타그램 브라우저 사용이 어려워요
            <br />
            크롬 또는 인터넷 브라우저에서 사용해 주세요.
          </div>
          <button
            type="button"
            className="btn btn-lg btn-outline btn-block"
            onClick={openExternalBrowser}
          >
            외부 브라우저 열기
          </button>
        </div>
      </div>
    </>
  );
};
