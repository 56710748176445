import { Jumbotron } from '@components/common/molecules/jumbotron';
import { ReviewListDesktop } from '@components/domain/mypage/review/review-list-desktop';
import { ReviewListMobile } from '@components/domain/mypage/review/review-list-mobile';

import useReviewList from '@/hooks/use-review-list';
import { isMobile } from '@/utils';

export const MyReviewTemplate = () => {
  const { initialValue } = useReviewList();
  return (
    <main className="my-main">
      <Jumbotron title="나의 리뷰" />
      <div className="section-contents noti-review">
        <ul>
          <li>리뷰는 구매일 기준 90일 까지 작성할 수 있습니다.</li>
          {/* <li>
            리뷰을 작성하시면 <span>500</span> 포인트를 지급해 드립니다. 포토 리뷰는{' '}
            <span>+500</span> 포인트 더 드립니다.
          </li> */}
        </ul>
      </div>
      <div className="section-contents">
        {isMobile ? <ReviewListMobile /> : <ReviewListDesktop limit={initialValue.limit} />}
      </div>
    </main>
  );
};
