import { FormEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { useFetchRecentlySearchedList } from '@/apis/commerce/self/useFetchRecentlySearchedList';
import { useMutationDeleteSearchedKeywords } from '@/apis/commerce/self/useMutationDeleteSearchedKeywords';
import { useMutationDeleteSingleKeyword } from '@/apis/commerce/self/useMutationDeleteSingleKeyword';
import searchIc from '@/assets/ic/search.svg';
import deleteIc from '@/assets/ic/x-sm-gr.svg';
import { PATH } from '@/constants/path';

export const SearchBox = () => {
  const navigate = useNavigate();
  const [searchWord, setSearchWord] = useState('');
  const [active, setActive] = useState(false);
  const searchRef = useRef<HTMLFormElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (searchRef.current && !searchRef.current.contains(e.target as Node)) {
      setActive(false);
    }
  };
  const toggleModal = () => setActive(pre => !pre);

  const { data: recentlySearchedData, refetch: refetchRecentlySearched } =
    useFetchRecentlySearchedList({
      options: {
        staleTime: 0,
      },
    });

  const { mutate: mutateDeleteSearchedList } = useMutationDeleteSearchedKeywords({
    options: { onSuccess: async () => await refetchRecentlySearched() },
  });

  const { mutate: mutateDeleteSingleKeyword } = useMutationDeleteSingleKeyword();

  const deleteAllRecentlySearched = () =>
    mutateDeleteSearchedList({}, { onError: err => console.log(err.message) });

  const handleClickDeleteSingleKeyword = (keyword: string) =>
    mutateDeleteSingleKeyword(keyword, { onSuccess: () => refetchRecentlySearched() });

  const handleChangeSearchInput = (e: FormEvent<HTMLInputElement>) => {
    setSearchWord(e.currentTarget.value);
  };
  const handleSearchAction = (keyword: string) => {
    navigate(`${PATH.SEARCH}/${keyword}`);
    toggleModal();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearchAction(searchWord);
    }
  };
  const handleClickKeyword = (keyword: string) => {
    navigate(`${PATH.SEARCH}/${keyword}`);
    toggleModal();
  };

  const isMobile = window.innerWidth < 905;

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    active && refetchRecentlySearched();
  }, [active]);
  return (
    <form className="forms-search" role="search" ref={searchRef} onClick={e => e.preventDefault()}>
      <input
        type="search"
        className="form-control search"
        placeholder="어떤 영양제를 찾으세요?"
        onChange={handleChangeSearchInput}
        value={searchWord}
        onKeyDown={handleKeyDown}
        onClick={() => setActive(true)}
        onFocus={() => setActive(true)}
      />
      <button
        className="search-submit"
        type="submit"
        onClick={() => {
          handleSearchAction(searchWord);
        }}
      >
        <img src={searchIc} alt="" />
      </button>
      {active && (
        <div className={`${isMobile ? 'mobile' : 'desktop'}-search-menu`}>
          {!isMobile && <div className="menu-title">최근 검색어</div>}
          {recentlySearchedData?.input_kwords !== undefined &&
          recentlySearchedData?.input_kwords?.length > 0 ? (
            <ul className="search-typing-list-group">
              {recentlySearchedData?.input_kwords?.map((keyword, wordIdx) => (
                <li className="list-item" key={`${wordIdx}_${keyword}`}>
                  <span className="title" onClick={() => handleClickKeyword(keyword)}>
                    {keyword}
                  </span>
                  <Link
                    className="btn-delete"
                    to="#"
                    onClick={() => handleClickDeleteSingleKeyword(keyword)}
                  >
                    <img src={deleteIc} alt="delete" />
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <div className="recent-search-none">최근 검색어가 없어요.</div>
          )}
          <div className="search-typing-delete-all">
            <Link className="action" to="#" onClick={deleteAllRecentlySearched}>
              전체삭제
            </Link>
            <Link className="action" to="#" onClick={() => setActive(false)}>
              닫기
            </Link>
          </div>
        </div>
      )}
    </form>
  );
};
