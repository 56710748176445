import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AlertModal } from '@components/common/molecules/modal/alert-modal';

import { useMutationInquiryCreate } from '@/apis/commerce/boards/useMutationInquiryCreate';
import { useFetchGetUserDetail } from '@/apis/commerce/self/useFetchGetUserDetail';
import { useFetchBoardSpecs } from '@/apis/commerce/specs/useFetchBoardSpecs';
import cameraIc from '@/assets/ic/camera.svg';
import deleteIc from '@/assets/ic/x-sm.svg';
import { PATH } from '@/constants/path';
import useLoginInfo from '@/hooks/use-login-info';
import { isMobile } from '@/utils';
import { phoneFormat } from '@/utils/formats';

export const HelpInquiryCreateTemplate = () => {
  const { userToken } = useLoginInfo();
  const { data: userDetailData } = useFetchGetUserDetail({
    accessToken: userToken,
  });
  const { data: specData } = useFetchBoardSpecs({ options: { staleTime: 1000 * 60 * 60 } });
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedType, setSelectedType] = useState(
    specData?.board_qna_type_codes?.[0]?.qna_type_name ?? '회원정보 관리',
  );
  const [content, setContent] = useState('');
  const [inputFile, setInputFile] = useState<File | undefined>(undefined);
  const [notiYn, setNotiYn] = useState(false);
  const optionRef = useRef<HTMLUListElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [alertOpen, setAlertOpen] = useState(false);
  // const [errorState, setErrorState] = useState({
  //   isOpen: false,
  //   status: ['ㅇ'],
  // });
  const navigate = useNavigate();

  const qnaTypeCd =
    specData?.board_qna_type_codes?.find(item => item.qna_type_name === selectedType)
      ?.qna_type_cd ?? '';

  const { mutate: mutateCreate } = useMutationInquiryCreate({ qnaBoardTypeCd: 'one_on_one' });

  const handleChangeContent = (e: FormEvent<HTMLTextAreaElement>) => {
    if (e.currentTarget.value.length <= 1000) {
      setContent(e.currentTarget.value);
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setInputFile(event.target.files[0]);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  const handleClickDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleSelectType = (type: string) => {
    setSelectedType(type);
    setOpenDropdown(false);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (optionRef.current && !optionRef.current.contains(e.target as Node)) {
      setOpenDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // const handleCloseErrorModal = () => {
  //   setErrorState({ ...errorState, isOpen: false });
  // };

  const handleClickSubmit = () => {
    content.length > 10 &&
      mutateCreate(
        {
          qnaTypeCd,
          qnaText: content,
          file: inputFile,
          openYn: 'N', // Y: 공개, N: 비공개 -> 1:1 게시판은 N으로 보냄
          notiAnswerYn: notiYn ? 'Y' : 'N',
        },
        {
          onSuccess: res => {
            res.status === 200 && setAlertOpen(true);
            console.log(res);
          },
          // onError: (err: any) => {
          //   debugger;
          //   setErrorState(pre => ({
          //     ...pre,
          //     isOpen: true,
          //     // status: [`${err?.status ?? ''} ${(err?.statusText as any) ?? ''}`],
          //   }));
          // },
        },
      );
  };

  const previewUrl = inputFile && URL.createObjectURL(inputFile);

  return (
    <main className="my-main">
      {alertOpen && (
        <AlertModal
          isOpen={alertOpen}
          header={['1:1 문의가', '등록되었어요.']}
          body={['지금 나의 문의 내역을', '확인하시겠어요?']}
          buttons={[
            {
              title: '이전으로',
              handleClick: () => navigate(-1),
            },
            {
              title: '문의내역',
              handleClick: () => navigate(PATH.MY_INQUIRY, { replace: true }),
            },
          ]}
        />
      )}
      {/* {errorState.isOpen && (
        <FriendlyAlert
          header={errorState?.status}
          body={['문의 등록에 실패했습니다.', '다시 시도해주세요.']}
          buttons={[
            {
              title: '확인',
              handleClick: () => handleCloseErrorModal(),
            },
          ]}
        />
      )} */}
      <section className="desktop-jumbotron section">
        <div className="section-title">
          <div className="label">1:1 문의</div>
        </div>
      </section>
      <div className="qa-form-contents">
        <div className="form-title">
          자주하는 질문에서 찾지 못하셨나요?
          <br />
          1:1 문의를 이용해 주세요.
        </div>
        {/* <!-- 문의 작성 --> */}
        <div className="dropdown">
          <button
            className={`btn btn-outline btn-md dropdown-toggle${openDropdown ? ' menu-open' : ''}`}
            type="button"
            onClick={handleClickDropdown}
          >
            {selectedType}
          </button>
          <ul className={`dropdown-menu${openDropdown ? ' show' : ''}`} ref={optionRef}>
            {specData?.board_qna_type_codes?.map((item, idx) => (
              <li key={idx} onClick={() => handleSelectType(item?.qna_type_name ?? '')}>
                <Link className="dropdown-item" to="#">
                  {item?.qna_type_name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {/* <!-- 문의 내용 --> */}
        <div className="input-group">
          <div className="form-label">
            문의 내용<sup>*</sup>
          </div>
        </div>
        <div className="input-group">
          <textarea
            className="form-control"
            placeholder="최소 10자 이상 작성"
            onChange={handleChangeContent}
          />
          <div className="view-words">
            <span className="value">{content.length}</span>/1000
          </div>
        </div>
        <div className="input-group">
          {inputFile ? (
            <div className="img-uploaded">
              <img src={previewUrl} alt="등록한 사진 미리보기" />
              <Link className="btn-delete" to="#" onClick={() => setInputFile(undefined)}>
                <img src={deleteIc} alt="delete" />
              </Link>
            </div>
          ) : (
            <button
              type="button"
              className="btn btn-md btn-outline btn-icon-only"
              onClick={triggerFileInput}
            >
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              <img src={cameraIc} alt="" />
            </button>
          )}
        </div>
        <span className="with-icon">사진은 최대 1장만 가능합니다.</span>
        <div className="row">
          <div className="input-group">
            <div className="form-label">
              연락처
              {/* <Link className="link" to="#">
                정보수정 &gt;
              </Link> */}
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="제목 입력"
              value={phoneFormat(userDetailData?.self_detail?.phone ?? '')}
              readOnly
              disabled
            />
            <label className="checkbox-group green-box sm-box">
              <input
                type="checkbox"
                name="test-2"
                checked={notiYn}
                onChange={() => setNotiYn(pre => !pre)}
              />
              <span className="label">답변시 알림톡 받기</span>
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
        <span className="with-icon">
          문의하신 내용에 대한 답변은 내 정보 &gt; <Link to="#">나의 문의 관리</Link>에서 확인하실
          수 있습니다.
        </span>
      </div>
      {/* <!-- 데스크탑에서만 나오는 버튼 --> */}
      <div className="centered-desktop-action">
        <button type="button" className="btn btn-lg btn-outline">
          취소하기
        </button>
        <button
          type="button"
          className="btn btn-lg btn-filled"
          onClick={handleClickSubmit}
          disabled={content.length < 10}
        >
          문의하기
        </button>
      </div>
      {isMobile && (
        <div className="btn-inline-group one-on-one">
          <button
            type="button"
            className="btn btn-md btn-filled btn-block"
            onClick={handleClickSubmit}
            disabled={content.length < 10}
          >
            문의하기
          </button>
        </div>
      )}
    </main>
  );
};
