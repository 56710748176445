import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { AUTH_TOKEN_KEY } from '@/constants/auth';
import { PATH } from '@/constants/path';
import { getSessionStorage } from '@/utils';

export default function useLoginInfo() {
  const navigate = useNavigate();
  const recentViewedPrds = getSessionStorage('rct_p') || null;
  const userToken =
    sessionStorage.getItem(AUTH_TOKEN_KEY) ?? localStorage.getItem(AUTH_TOKEN_KEY) ?? '';

  const isLogin = useMemo(() => {
    return !!userToken;
  }, [userToken]);

  const logout = () => {
    sessionStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem(AUTH_TOKEN_KEY);
    const referrerIndex = getSessionStorage('REFERRER');
    const navigateUrl = recentViewedPrds?.[0]
      ? `${PATH.PRODUCT_DETAIL}/${recentViewedPrds[0]}${
          referrerIndex ? `?referrerIdx=${referrerIndex}` : ''
        }`
      : PATH.LOGIN;
    navigate(navigateUrl);
  };

  return { isLogin, userToken, logout };
}
