import { useState } from 'react';

import { useFetchNotice } from '@/apis/commerce/notices/useFetchNotice';
import { dateToStringFormat } from '@/utils/formats';

export const HelpNoticeTemplate = () => {
  const [activeQuestion, setActiveQuestion] = useState<number | undefined>(undefined);

  const { data: NoticeData } = useFetchNotice({});

  const handleClickQuestion = (idx: number) =>
    idx === activeQuestion ? setActiveQuestion(undefined) : setActiveQuestion(idx);

  return (
    <main className="my-main">
      <section className="desktop-jumbotron section">
        <div className="section-title">
          <div className="label">공지사항</div>
        </div>
      </section>
      <ul className="qna-group-list">
        {NoticeData?.notices?.map((noti, faqIdx) => (
          <li
            className={`qna-group-item${faqIdx === activeQuestion ? ' active' : ''}`}
            key={`notice_${faqIdx}`}
            onClick={() => handleClickQuestion(faqIdx)}
          >
            <article className="qna-item">
              <div className="title-wrap">
                <span className="full-text">{noti?.notice_title}</span>
                <span className="date">
                  {dateToStringFormat(new Date(noti?.created_at ?? ''), 'dot')}
                </span>
              </div>
              <div className="answer-wrap">
                <div className="text">{noti?.notice_text}</div>
              </div>
            </article>
          </li>
        ))}
      </ul>
      <div className="pagenation-group" id="pagenation"></div>
    </main>
  );
};
