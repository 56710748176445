import { Link } from 'react-router-dom';
import { SearchBox } from '@/components/domain/search/search-box';
import { PATH } from '@/constants/path';
import mobileSearchIc from '@/assets/ic/search-wt.svg';
import truckIc from '@/assets/ic/truck-wt.svg';
import brandIc from '@/assets/ic/brand-logo-wt.svg';
import moToggleIc from '@/assets/ic/menu-toggle.svg';
import dtMypageIc from '@/assets/ic/mypage-wt.svg';
import cartIc from '@/assets/ic/product-box-wt.svg';

export const GnbGreenBar = ({
  isLogin,
  isExpanded,
  cartCount,
  orderCount,
  snbToggle,
  handleTracking,
}: {
  isLogin: boolean;
  isExpanded: boolean;
  cartCount: number;
  orderCount: number;
  snbToggle: () => void;
  handleTracking: (type: string) => void;
}) => {
  return (
    <div
      className={`fluid-main${isExpanded ? '' : ' desktop-bar'}`}
      onClick={e => e.stopPropagation()}
    >
      <div className="contents-wrap">
        {/* <!-- 전체 브랜드 아이콘 --> */}
        <Link className="navbar-brand" to={PATH.HOME} onClick={() => handleTracking('home')}>
          <img src={brandIc} alt="home" />
        </Link>
        <div className="navbar-collapse">
          {/* <!-- 데스크탑용 검색바 --> */}
          <SearchBox />
          {/* <!-- 모바일용 검색 아이콘 --> */}
          <Link
            className="nav-link link-mobile"
            to={PATH.SEARCH}
            onClick={() => handleTracking('search')}
          >
            <img src={mobileSearchIc} alt="search" />
          </Link>
          {isLogin && (
            <Link
              className="nav-link link-desktop"
              to={`${PATH.ORDER_LIST}`}
              onClick={() => handleTracking('order')}
            >
              <img src={truckIc} alt="tracking" />
              {orderCount > 0 && <span className="badge-amount">{orderCount}</span>}
              <span className="tooltiptext">주문내역</span>
            </Link>
          )}
          <Link
            className="nav-link"
            to={PATH.CART}
            state={{ from: 'gnb' }}
            onClick={() => handleTracking('cart')}
          >
            <img src={cartIc} alt="cart" />
            {cartCount > 0 && <span className="badge-amount">{cartCount}</span>}
            <span className="tooltiptext">장바구니</span>
          </Link>
          <Link
            className="nav-link link-desktop"
            to={isLogin ? PATH.MYPAGE : PATH.LOGIN}
            onClick={() => handleTracking('mypage')}
          >
            <img src={dtMypageIc} alt="mypage" />
            <span className="tooltiptext">마이</span>
          </Link>
          {/* <!-- 모바일 햄버거 --> */}
          <button className="navbar-toggler" type="button" onClick={snbToggle}>
            <span className="navbar-toggler-icon">
              <img src={moToggleIc} alt="side bar toggle" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};
