import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

export function useMutationPassValidationUpdate() {
  const updatePassToken = async ({ tkn }: { tkn: string }) => {
    const response = await post('/user/pass/validation/update', {
      pass_token: tkn,
    });
    return response.data;
  };

  return useMutation({
    mutationFn: updatePassToken,
  });
}
