import { ReactNode, useEffect } from 'react';
import { CookiesProvider as ReactCookieProvider } from 'react-cookie';

import { AUTH_TOKEN_KEY } from '@/constants/auth';

interface CookiesProviderProps {
  children: ReactNode;
}

export const CookiesProvider = ({ children }: CookiesProviderProps) => {
  //TODO: 쿠키관련된 로직만 추가해야되지만 스토리지 provider을 추가 생성하느니
  //TODO: 사라질 로직이라 잠시 작성해두고 이슈없을 시 삭제 합니다.
  const sessionStorageAccessToken = sessionStorage.getItem(AUTH_TOKEN_KEY);
  const localStorageAccessToken = localStorage.getItem(AUTH_TOKEN_KEY);
  const BEFORE_TOKEN_PATTERN = 'data:';
  useEffect(() => {
    if (sessionStorageAccessToken?.includes(BEFORE_TOKEN_PATTERN)) {
      sessionStorage.removeItem(AUTH_TOKEN_KEY);
    }
    if (localStorageAccessToken?.includes(BEFORE_TOKEN_PATTERN)) {
      localStorage.removeItem(AUTH_TOKEN_KEY);
    }
  }, [sessionStorageAccessToken, localStorageAccessToken]);

  return <ReactCookieProvider defaultSetOptions={{ path: '/' }}>{children}</ReactCookieProvider>;
};
