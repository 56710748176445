import { CartTemplate } from '@components/template/cart';

// import modalBannerImg from '@/assets/img/modal-banner.png';

/**
 * 장바구니 페이지 (/cart)
 */
export const CartPage = () => {
  return <CartTemplate />;
};
