import { Fragment } from 'react/jsx-runtime';
import { Link } from 'react-router-dom';
import { InquiryModifyModal } from '@components/common/molecules/modal/inquiry-modify-modal';

import { useInfinitelyFetchMyInquiry } from '@/apis/commerce/boards/useInfinitelyFetchMyInquiry';
import moreBtn from '@/assets/ic/chevron-down-bk.svg';
import useInquiryList from '@/hooks/use-inquiry-list';
import { dateToStringFormat } from '@/utils/formats';

export const MyInquiryMobile = () => {
  const {
    offset,
    currentTab,
    specData,
    threadData,
    modalState,
    activeQuestion,
    handleClickTab,
    handleClickQuestion,
    handleClickDelete,
    handleClickModify,
    toggleModifyModal,
  } = useInquiryList();

  const { data, refetch, fetchNextPage, isFetched } = useInfinitelyFetchMyInquiry({
    qnaBoardTypeCd: currentTab,
    offset,
    limit: 10,
  });

  const total = data?.pages?.[0]?.total ?? 0;

  return (
    <>
      {modalState.isShow && (
        <InquiryModifyModal
          isOpen={modalState.isShow}
          toggleModal={toggleModifyModal}
          qnaBoardTypeCd={currentTab}
          threadData={modalState.threadData}
          qnaText={modalState.text}
          refetchList={refetch}
        />
      )}
      <section className="desktop-jumbotron section">
        <div className="section-title">
          <div className="label">나의 문의</div>
        </div>
      </section>
      <div className="tabs sticky-top">
        {specData?.board_type_codes?.map(tab => (
          <Link
            className={`tab-item${tab?.board_type_cd === currentTab ? ' active' : ''}`}
            to="#"
            onClick={e => handleClickTab(e, tab?.board_type_cd ?? '')}
            key={tab?.board_type_cd}
          >
            {tab?.board_type_name}
            {/* // ? 일단 안나오게 <span className="number">10</span> */}
          </Link>
        ))}
      </div>
      {((isFetched && total) || 0) > 0 ? (
        <>
          <ul className="qna-group-list">
            {data?.pages?.map(inquiryData =>
              inquiryData?.data?.articles?.map(inquiry => (
                <li
                  className={`qna-group-item${
                    inquiry?.cs_qna_idx === activeQuestion ? ' active' : ''
                  }`}
                  key={`inquiry_${inquiry?.cs_qna_idx}`}
                  onClick={() => handleClickQuestion(inquiry?.cs_qna_idx ?? 0)}
                >
                  <article className="qna-item">
                    <div className="title-wrap">
                      <span
                        className={`badge-payment${inquiry?.resolve_yn === 'Y' ? '' : ' light'}`}
                      >
                        {inquiry?.resolve_yn === 'Y' ? '답변완료' : '검토중'}
                      </span>
                      <span className="title">{inquiry?.qna_type_name}</span>
                      <span className="date">
                        {dateToStringFormat(new Date(inquiry?.created_at as string), 'dot')}
                      </span>
                    </div>
                    <div className="question-wrap">
                      {inquiry?.qna_text?.split('\n').map((line, idx) => (
                        <Fragment key={`inquiry_text_${idx}`}>
                          {line}
                          <br />
                        </Fragment>
                      ))}
                      {threadData?.articles?.[0]?.image_urls?.[0] && (
                        <img src={threadData?.articles?.[0]?.image_urls?.[0]} alt="" />
                      )}
                      {inquiry?.resolve_yn === 'N' && (
                        <div className="btn-inline-group">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline btn-block"
                            onClick={() =>
                              threadData?.articles && handleClickModify(threadData?.articles?.[0])
                            }
                          >
                            수정
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline btn-block"
                            onClick={() =>
                              handleClickDelete({
                                qnaIdx: inquiry?.cs_qna_idx ?? 0,
                                onSuccess: refetch,
                              })
                            }
                          >
                            삭제
                          </button>
                        </div>
                      )}
                    </div>
                    {/* <!-- 답변 --> */}
                    {threadData?.articles &&
                      threadData?.articles?.length > 0 &&
                      threadData?.articles?.map(
                        thread =>
                          thread?.is_qa === 'A' &&
                          thread?.qna_text && (
                            <div
                              className="answer-wrap"
                              key={`orderOption_${thread?.ord_order_option_idx}&thread_${thread?.cs_qna_idx}`}
                            >
                              <div className="title">
                                <span className="done">답변완료</span>
                                <span className="date">
                                  {dateToStringFormat(
                                    new Date(thread?.created_at as string),
                                    'dot',
                                  )}
                                </span>
                              </div>
                              <div className="text">{thread?.qna_text}</div>
                            </div>
                          ),
                      )}
                  </article>
                </li>
              )),
            )}
          </ul>
          <div id="load-pagenation">
            <div className="pagenation-group" id="pagenation">
              <button
                type="button"
                className="btn btn-md btn-block btn-outline pagenation-mobile"
                onClick={() => fetchNextPage()}
                disabled={data?.pages?.[(data?.pages?.length ?? 0) - 1].isLast}
              >
                <img src={moreBtn} alt="more" />
                상품 더보기
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="empty-list cart">
          <div className="lines">
            <span>“작성된 문의글이 없어요”</span>
            <span className="line"></span>
          </div>
          <div className="sub-text">
            자주하는 질문에서 찾지 못하셨다면
            <br />
            궁금한 사항을 문의해 주세요.
          </div>
        </div>
      )}
    </>
  );
};
