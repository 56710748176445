import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { type QueryOptionsType } from '@/generated/api/type/api';
import { WoOrderSpecGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  options?: QueryOptionsType<WoOrderSpecGetRes>;
}

export function useFetchOrderSpecs({ options }: Props) {
  const getQnas = async () => {
    return await get('/api/commerce/v1/specs/order').then(res => res.data as WoOrderSpecGetRes);
  };

  return useQuery({
    queryKey: ['useFetchOrderSpecs'],
    queryFn: getQnas,
    staleTime: 0,
    ...options,
  });
}
