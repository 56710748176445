import { Link, useLocation } from 'react-router-dom';

import { PATH } from '@/constants/path';

export const HelpSidebarDesktop = () => {
  const { pathname } = useLocation();
  return (
    <div className="desktop-my-sidebar">
      <div className="title">고객센터</div>
      <ul className="my-group-list">
        <li className="my-group-item">
          <div
            id="link-faq"
            className={`label link-label${pathname.includes(PATH.HELP_FAQ) ? ' active' : ''}`}
          >
            <Link to={PATH.HELP_FAQ}>자주하는 질문</Link>
          </div>
        </li>
        <li className="my-group-item">
          <div
            id="link-one"
            className={`label link-label${
              pathname.includes(PATH.HELP_INQUIRY_CREATE) ? ' active' : ''
            }`}
          >
            <Link to={PATH.HELP_INQUIRY_CREATE}>1:1 문의</Link>
          </div>
        </li>
        <li className="my-group-item">
          <div
            id="link-notice"
            className={`label link-label${pathname.includes(PATH.HELP_NOTICE) ? ' active' : ''}`}
          >
            <Link to={PATH.HELP_NOTICE}>공지사항</Link>
          </div>
        </li>
      </ul>
    </div>
  );
};
