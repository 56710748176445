import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { type QueryOptionsType } from '@/generated/api/type/api';
import { type WoSelfInputKwordsGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  options?: QueryOptionsType<WoSelfInputKwordsGetRes>;
}

export function useFetchRecentlySearchedList({ options }: Props) {
  const getRecentlySearchedKeywords = async () => {
    return await get('/api/commerce/v1/self/input_kwords').then(res => res.data);
  };

  return useQuery({
    queryKey: [{ key: 'useFetchRecentlySearchedList' }],
    queryFn: getRecentlySearchedKeywords,
    ...options,
  });
}
