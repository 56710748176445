import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertModal } from '@components/common/molecules/modal/alert-modal';
import { BottomSheetModal } from '@components/domain/cart/bottom-sheet-modal';
import { CartNotice } from '@components/domain/cart/cart-notice';
import { SellerGroup } from '@components/domain/cart/seller-group';

import { useFetchCart } from '@/apis/commerce/cart/useFetchCart';
import useFetchCartAmount from '@/apis/commerce/cart/useFetchCartAmount';
import { useMutationCartCounter } from '@/apis/commerce/cart/useMutationCartCounter';
import { useMutationCartDelete } from '@/apis/commerce/cart/useMutationCartDelete';
import { useMutationOrderViaCart } from '@/apis/commerce/preorders/useMutationOrderViaCart';
import { useFetchGetUserDetail } from '@/apis/commerce/self/useFetchGetUserDetail';
import boxIc from '@/assets/ic/product-box.svg';
import { PATH } from '@/constants/path';
import { WoCartProductOption, WoCartSellerSection } from '@/generated/api/type/data-contracts';
import useLoginInfo from '@/hooks/use-login-info';
import useMixpanel from '@/hooks/use-mixpanel';
import { getSessionStorage } from '@/utils';
import { numberFormat } from '@/utils/formats';

export const CartTemplate = () => {
  const { isLogin, userToken } = useLoginInfo();
  const { handleMixpanelEvent } = useMixpanel();
  const navigate = useNavigate();
  const location = useLocation();
  const referrerIdx = Number(getSessionStorage('REFERRER') ?? 0);
  // 선택한 옵션의 index
  const [selected, setSelected] = useState<number[]>([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    title: [''],
    body: [''],
    btns: [{ title: '', handleClick: () => {} }],
  });
  // 셀러 그룹 토글
  const [inActiveSellers, setInActiveSellers] = useState<number[]>([]);
  // 모바일 주문서 Modal 토글
  const [isMoToggleOpen, setIsMOToggleOpen] = useState(false);

  const { data: cartData, refetch: refetchCart } = useFetchCart({
    accessToken: userToken,
  });

  const { data: cartAmountData, refetch: refetchCartAmountData } = useFetchCartAmount({
    optionIdxes: selected,
    options: { enabled: !!selected.length, staleTime: 0, gcTime: 0 },
  });

  const { data: userDetailData, refetch: refetchUserDetail } = useFetchGetUserDetail({
    accessToken: userToken,
  });

  const refetchData = async () => {
    await refetchCart();
    await refetchCartAmountData();
    await refetchUserDetail();
  };

  useEffect(() => {
    handleMixpanelEvent('view_cart', {
      from: location?.state?.from ?? '',
    });
  }, []);
  const getSellerTotalAmount = (sellerIdx: number) =>
    cartAmountData?.payment_amounts?.find(seller => seller?.seller_idx === sellerIdx)?.sale_price ??
    0;
  const getSellerShippingFee = (sellerIdx: number) =>
    cartAmountData?.payment_amounts?.find(seller => seller?.seller_idx === sellerIdx)
      ?.shipping_fee ?? 0;
  const getSellerDiscountPrice = (sellerIdx: number) =>
    cartAmountData?.payment_amounts?.find(seller => seller?.seller_idx === sellerIdx)
      ?.discount_price ?? 0;
  const getSellerOrderTotalPrice = (sellerIdx: number) =>
    (cartAmountData?.payment_amounts?.find(seller => seller?.seller_idx === sellerIdx)
      ?.order_tot_amount ?? 0) + getSellerShippingFee(sellerIdx);

  const totalAmount =
    cartAmountData?.payment_amounts?.reduce((acc, curr) => acc + (curr.sale_price ?? 0), 0) ?? 0;
  const totalShippingFee =
    cartAmountData?.payment_amounts?.reduce((acc, curr) => acc + (curr.shipping_fee ?? 0), 0) ?? 0;
  const totalDiscountePrice =
    cartAmountData?.payment_amounts?.reduce((acc, curr) => acc + (curr.discount_price ?? 0), 0) ??
    0;
  const totalPaymentAmount = totalAmount - totalDiscountePrice + totalShippingFee;

  const { mutate: mutateCounter } = useMutationCartCounter();
  const { mutate: mutateDelete } = useMutationCartDelete();
  const { mutate: mutateOrder } = useMutationOrderViaCart();

  const validArr = (arr?: any[]) => (arr ? arr.filter(item => item !== undefined) : []);

  const everyIdx = validArr(
    cartData?.seller_sections?.flatMap(seller =>
      seller?.product_sections?.flatMap(section =>
        section?.product_options?.map(option => option?.user_cart_option_idx),
      ),
    ),
  );

  const selectedOptions = validArr(
    cartData?.seller_sections?.flatMap(seller =>
      seller?.product_sections?.flatMap(section =>
        section?.product_options?.filter(option => selected.includes(option?.user_cart_option_idx)),
      ),
    ),
  );

  // option_tot_amount: 최종 할인 적용 금액의 도합 (real_price * quantity)
  const priceSum = (property: 'option_tot_amount' | 'sale_price', list: WoCartProductOption[]) =>
    list.reduce((acc, curr) => {
      return property === 'option_tot_amount'
        ? acc + curr[property]!
        : acc + curr[property]! * curr.quantity;
    }, 0);

  // 전체/ 셀러/ 개별 선택
  const handleSelection = ({
    e,
    idxes,
    type,
  }: {
    e: ChangeEvent<HTMLInputElement>;
    idxes?: number[];
    type?: string;
  }) => {
    e.stopPropagation();
    const isChecked = e.currentTarget.checked;
    switch (type) {
      case 'all':
        // 전체 선택 | 해제
        isChecked ? setSelected(everyIdx) : setSelected([]);
        break;
      default:
        // 그룹 , 개별 선택 | 해제
        isChecked && idxes
          ? setSelected(pre => [...pre, ...idxes.filter(idx => !selected.includes(idx))])
          : setSelected(pre => pre.filter(item => !idxes?.includes(item)));
    }
  };

  const handleToggleSeller = (sellerIdx: number) => {
    if (inActiveSellers.includes(sellerIdx)) {
      setInActiveSellers(pre => pre.filter(seller => seller !== sellerIdx));
    } else {
      setInActiveSellers(pre => [...pre, sellerIdx]);
    }
  };

  const handleClickCounter = (optionIdx: number, quantity: number) => {
    mutateCounter(
      { optionIdx, quantity },
      { onSuccess: () => refetchData(), onError: err => console.log(err.message) },
    );
  };

  const handleClickDelete = (optionIdx: number[] | []) => {
    mutateDelete(
      { optionIdx },
      { onSuccess: () => refetchData(), onError: err => console.log(err.message) },
    );
  };

  const shippingFeeSum: number[] = [];

  const handleOrderClick = (from: string, cartOptionIdxes: number[]) => {
    if (isLogin) {
      handleMixpanelEvent('click_order', { from });
      mutateOrder(
        { referrer_influencer_idx: referrerIdx, user_cart_option_idxes: cartOptionIdxes },
        {
          onSuccess: data => navigate(`${PATH.ORDER}?id=${data.preorder_idx}`, { state: { from } }),
          onError: err => console.log(err.message),
        },
      );
    } else {
      setAlert({
        isOpen: true,
        title: ['알림'],
        body: ['로그인 후 주문할 수 있습니다.'],
        btns: [
          {
            title: '로그인하러 가기',
            handleClick: () => {
              const beforeUrl = location.pathname + location.search;
              navigate(`${PATH.LOGIN}?before_url=${encodeURIComponent(beforeUrl)}`);
            },
          },
        ],
      });
    }
  };

  const toggleMobileModal = () => {
    setIsMOToggleOpen(pre => !pre);
  };

  const handleClickProduct = (idx: number) => {
    navigate(`${PATH.PRODUCT_DETAIL}/${idx}`);
  };

  return (
    <>
      {alert.isOpen && (
        <AlertModal
          isOpen={alert.isOpen}
          header={alert.title}
          body={alert.body}
          buttons={alert.btns}
          closeAlert={() => setAlert({ ...alert, isOpen: false })}
        />
      )}
      {/* <!-- 데스크탑에서만 나오는 점보트론 --> */}
      <section className="desktop-jumbotron">
        <img src={boxIc} alt="" />
        <span className="text">장바구니</span>
      </section>
      {!cartData?.seller_sections?.length ? (
        <div className="empty-list cart">
          <div className="lines">
            <span>“장바구니가 비었어요”</span>
            <span className="line"></span>
          </div>
          <div className="sub-text">
            {userDetailData?.self_detail?.nickname
              ? `${userDetailData?.self_detail?.nickname}`
              : '고객'}
            님의 장바구니가 비었어요.
            <br />
            요즘 관심있는 상품을 담아보세요!
          </div>
        </div>
      ) : (
        <div className="cart-list-wrap">
          {/* <!-- 전체 리스트 필터 --> */}
          <div className="select-filter">
            <label className="checkbox-group green-box sm-box">
              <input
                type="checkbox"
                name="test-1"
                onChange={e => handleSelection({ e, type: 'all' })}
              />
              <span className="label">전체선택</span>
              <span className="checkmark"></span>
            </label>
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-sm btn-outline"
                onClick={() => selected.length > 0 && handleClickDelete(selected)}
              >
                선택삭제
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline"
                onClick={() => handleClickDelete(everyIdx)}
              >
                장바구니 비우기
              </button>
            </div>
          </div>
          {/* <!-- 전체 리스트 --> */}
          <ul className="cart-group-list">
            {cartData?.seller_sections?.map((seller: WoCartSellerSection) => {
              const sellersOptions = seller?.product_sections?.flatMap(prd =>
                prd?.product_options?.flatMap(option => option),
              );
              const sellersOptionIdxes = validArr(
                sellersOptions?.map(option => option?.user_cart_option_idx),
              );

              const groupSelectedOptions = validArr(
                sellersOptions?.filter(option =>
                  selected.includes(option?.user_cart_option_idx as number),
                ),
              );
              const groupSelectedOptionIdxes = groupSelectedOptions?.map(
                option => option?.user_cart_option_idx,
              );
              const sellerSelectedTotalPrice = priceSum('option_tot_amount', groupSelectedOptions);

              // 배송비 null: 무료배송 / 무료배송금액 null : 배송비 받겠음 / 무료배송금액 1 : 계산
              const shippingFee = !seller.shipping_fee
                ? 0
                : (seller?.free_shipping_limit ?? 0) < sellerSelectedTotalPrice
                ? 0
                : seller.shipping_fee;

              if (shippingFee && sellerSelectedTotalPrice) {
                // 선택한 값이 있고 배송비 있으면 최종 배송비로 보냄
                shippingFeeSum.push(shippingFee);
              }

              return (
                <li className="cart-group-item" key={`${seller.seller_idx}${seller.company_name}`}>
                  <article className="cart-item">
                    <div
                      className={`store${
                        inActiveSellers.includes(seller.seller_idx) ? '' : ' active'
                      }`}
                      onClick={e => {
                        handleToggleSeller(seller.seller_idx);
                      }}
                    >
                      <label className="checkbox-group green-box sm-box">
                        <input
                          type="checkbox"
                          name="test-2"
                          checked={sellersOptionIdxes?.every(option => selected.includes(option))}
                          onChange={e => {
                            handleSelection({ e, type: 'group', idxes: sellersOptionIdxes });
                          }}
                        />
                        <span className="label">{seller.company_name}</span>
                        <span className="checkmark" />
                      </label>
                    </div>
                    <ul
                      className={`store-group-list${
                        inActiveSellers.includes(seller.seller_idx) ? '' : ' show'
                      }`}
                    >
                      <SellerGroup
                        seller={seller}
                        selected={selected}
                        handleSelection={handleSelection}
                        handleClickProduct={handleClickProduct}
                        handleClickCounter={handleClickCounter}
                        handleClickDelete={handleClickDelete}
                      />
                    </ul>
                    <ul
                      className={`pricing-group-list${
                        inActiveSellers.includes(seller.seller_idx) ? '' : ' show'
                      }`}
                    >
                      <li className="pricing-item default-price">
                        <div className="pricing-col">
                          <div className="label">선택상품금액</div>
                          <div className="price">
                            {numberFormat(getSellerTotalAmount(seller?.seller_idx) ?? 0)}
                            <span className="unit">원</span>
                          </div>
                        </div>
                        <div className="pricing-col">
                          <div className="label">
                            <span className="cal">+</span>총 배송비
                          </div>
                          <div className="price">
                            {numberFormat(getSellerShippingFee(seller?.seller_idx) ?? 0)}
                            <span className="unit">원</span>
                          </div>
                        </div>
                        <div className="pricing-col discount">
                          <div className="label">
                            <span className="cal">-</span>할인금액
                          </div>
                          <div className="price">
                            {numberFormat(getSellerDiscountPrice(seller?.seller_idx) ?? 0)}
                            <span className="unit">원</span>
                          </div>
                        </div>
                      </li>
                      <li className="pricing-item total-price">
                        <div className="pricing-col">
                          <div className="label">주문금액</div>
                          <div className="price">
                            {numberFormat(getSellerOrderTotalPrice(seller?.seller_idx) ?? 0)}
                            <span className="unit">원</span>
                          </div>
                        </div>
                        <div className="pricing-col btn-payment">
                          <button
                            type="button"
                            className="btn btn-md btn-filled btn-block"
                            disabled={groupSelectedOptions.length <= 0}
                            onClick={() =>
                              handleOrderClick('cart_seller', groupSelectedOptionIdxes)
                            }
                          >
                            {groupSelectedOptions.length}건 주문하기
                          </button>
                        </div>
                      </li>
                    </ul>
                  </article>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {/* <!-- 유의사항 --> */}
      <CartNotice />
      {/* <!-- 배너 --> */}
      {/* <div className="cart-banner-wrap">
        <img className="banner" src={modalBannerImg} alt="banner" />
      </div> */}
      {/*
      <!--// TODO: 추천영역 horizontal(2.5열)에 클래스 mobile-mg-r-0 추가하여 패딩값 없앰 -->
      <section className="mobile-mg-r-0">
        <div id="load-list-header-normal"></div>
        {/* <!-- Horizontal Product list -->
        <div id="load-horizontal-prd"></div>
      </section>
      */}
      {/* <!-- 장바구니 모바일 바텀 네비게이션 --> */}
      <div className="mobile-bottom-nav cart-nav">
        <div className="handle" onClick={toggleMobileModal} />
        {/* <!-- 총 금액 --> */}
        <div className="total-price">
          <div className="label">결제 예정금액 : {selected.length}건</div>
          <div className="discount-percent">{numberFormat(totalPaymentAmount)}</div>
          <div className="unit">원</div>
        </div>
        <div className="btn-inline-group">
          <button
            type="button"
            className="btn btn-md btn-filled btn-block"
            onClick={() => handleOrderClick('cart_bottom', selected)}
          >
            {numberFormat(totalPaymentAmount)}원 결제하기
          </button>
        </div>
      </div>
      {/* <!-- 장바구니 데스크탑 바텀 네비게이션 --> */}
      <div className="desktop-cart-bottom-nav">
        <div className="contents-wrap">
          <div className="col calc">
            <span className="label">선택상품금액</span>
            <span className="price">
              {numberFormat(totalAmount)}
              <span className="unit">원</span>
            </span>
            <span className="label">
              <span className="cal">-</span>할인금액
            </span>
            <span className="price">
              {numberFormat(totalDiscountePrice)}
              <span className="unit">원</span>
            </span>
            <span className="label">
              <span className="cal">+</span>총 배송비
            </span>
            <span className="price">
              {numberFormat(totalShippingFee)}
              <span className="unit">원</span>
            </span>
          </div>
          <div className="col total">
            <span className="label">결제 예정금액 : {selected.length}건</span>
            <button
              type="button"
              className="btn btn-lg btn-filled"
              onClick={() => handleOrderClick('cart_bottom', selected)}
            >
              {numberFormat(totalPaymentAmount)}원 결제하기
            </button>
          </div>
        </div>
      </div>
      {/* <!-- 장바구니 모바일 바텀 모달시트 --> */}
      {isMoToggleOpen && (
        <BottomSheetModal
          handleOrderClick={() => handleOrderClick('cart_bottom', selected)}
          toggleModal={toggleMobileModal}
          totalCount={selected.length}
          totalPrice={numberFormat(totalAmount)}
          discountPrice={numberFormat(totalDiscountePrice)}
          totalDiscountedPrice={numberFormat(totalPaymentAmount)}
          deliveryPrice={numberFormat(totalShippingFee)}
          selected={selected}
        />
      )}
    </>
  );
};
