import { OrderTemplate } from '@components/template/order';

/**
 * 주문 페이지 (/order)
 * flow: preorder(in Index or Index) -> order -> payment(tosspayments) -> success
 * 결제 실패지 본 페이지로 돌아옴 // TODO 후처리 필요
 * TODO: Change customerKey to real user's key
 */
export const OrderPage = () => {
  return <OrderTemplate />;
};
