import { ReactNode, useLayoutEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import { isProduction } from '@/utils';

interface TrackProviderProps {
  children: ReactNode;
}

export const MixpanelProvider = ({ children }: TrackProviderProps) => {
  const MIXPANEL_TOKEN = isProduction
    ? process.env.REACT_APP_MIXPANEL_LIVE_TOKEN
    : process.env.REACT_APP_MIXPANEL_DEV_TOKEN ?? '';

  useLayoutEffect(() => {
    if (!MIXPANEL_TOKEN) {
      console.error('Mixpanel token is missing or invalid. Initialization skipped.');
      return;
    }
    try {
      mixpanel.init(MIXPANEL_TOKEN, {
        debug: !isProduction,
        opt_out_tracking_by_default: false,
      });
    } catch (e) {
      console.error(e);
    }
  }, [MIXPANEL_TOKEN]);

  return children;
};
