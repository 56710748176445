import { useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { OptionDropdown } from '@components/domain/product/common/option-dropdown';
import { OrderBtnBox } from '@components/domain/product/common/order-btn-box';
import { Review } from '../common/review';
import { SelectedOptionItem } from '@components/domain/product/common/selected-option-item';

import { type WoDetailedProduct } from '@/generated/api/type/data-contracts';
import useMixpanel from '@/hooks/use-mixpanel';
import { numberFormat } from '@/utils/formats';

interface ProductContentProps {
  productDetail?: WoDetailedProduct;
  onSuccessAddingCart: (title: string, duration: number) => void;
  currentTab: string;
  handleClickTab: (tab: string) => void;
}
/**
 * 상품 상세 하단 탭 이하 부분
 */
export const ProductContent = ({
  productDetail,
  onSuccessAddingCart,
  currentTab,
  handleClickTab,
}: ProductContentProps) => {
  const { id } = useParams();
  const { handleMixpanelEvent } = useMixpanel();
  const tabRef = useRef<HTMLDivElement>(null);

  const onClickTab = (tab: string) => {
    handleMixpanelEvent('click_product_tab', { product_id: id, type: tab });
    handleClickTab(tab);
    window.scrollTo({ top: tabRef?.current?.offsetTop, behavior: 'smooth' });
  };

  return productDetail ? (
    <div className="product-detail-middle" ref={tabRef}>
      <div className="wide-col">
        <Tabs currentTab={currentTab} handleClickTab={onClickTab} />
        {currentTab === '상세설명' ? (
          <Detail productDetail={productDetail} />
        ) : currentTab === '정보' ? (
          <Info productDetail={productDetail} />
        ) : currentTab === '리뷰' ? (
          <Review />
        ) : (
          <Refund refundImg={productDetail?.refund_info_image_url ?? ''} />
        )}
      </div>
      <RightOptions productDetail={productDetail} onSuccessAddingCart={onSuccessAddingCart} />
    </div>
  ) : (
    <></>
  );
};

interface TabsProps {
  currentTab: string;
  handleClickTab: (tab: string) => void;
}

const DETAIL_TABS = ['상세설명', '정보', '리뷰', '반품/교환'];
/**
 * 상품 상세 탭 영역
 */
const Tabs = ({ currentTab, handleClickTab }: TabsProps) => {
  return (
    <div className="tabs sticky-top">
      {DETAIL_TABS.map(tab => {
        return (
          <Link
            to="#"
            className={`tab-item ${currentTab === tab ? 'active' : ''}`}
            key={tab}
            onClick={() => {
              handleClickTab(tab);
            }}
          >
            {tab}
          </Link>
        );
      })}
    </div>
  );
};

interface DetailProps {
  productDetail: WoDetailedProduct;
}

/**
 * 상품 상세설명 탭 영역
 */
const Detail = ({ productDetail }: DetailProps) => {
  const [contentHeight, setContentHeight] = useState('70rem');

  const handleClickMore = () => {
    setContentHeight('auto');
  };

  return (
    <div
      style={{ height: contentHeight, display: 'block' }}
      className="tabs-contents detail"
      id="content1"
    >
      {productDetail?.detail_image_html_yn === 'Y' ? (
        <div dangerouslySetInnerHTML={{ __html: productDetail?.detail_image_html_text ?? '' }} />
      ) : (
        productDetail?.detail_images?.map((img, idx) => (
          <img key={`detailImg_${idx}`} src={img} alt="상품상세" />
        ))
      )}

      {/* <!-- 페이지네이션 버튼으로 상세정보 높이값 auto로 조절 --> */}
      <div className="pagenation-group">
        {/* <!-- mobile 페이지네이션 버튼 --> */}
        {contentHeight === 'auto' ? (
          <></>
        ) : (
          <button
            type="button"
            className="btn btn-md btn-block btn-outline"
            onClick={handleClickMore}
          >
            상세설명 펼쳐보기
          </button>
        )}
      </div>
    </div>
  );
};

interface InfoProps {
  productDetail: WoDetailedProduct;
}
/**
 * 상품 고시정보 탭 영역
 */
const Info = ({ productDetail }: InfoProps) => {
  const [toggleArea, setToggleArea] = useState<{ basic: boolean; noti: boolean; seller: boolean }>({
    basic: false,
    noti: true,
    seller: false,
  });
  const handleToggleBtn = (key: 'basic' | 'noti' | 'seller') => {
    setToggleArea({
      ...toggleArea,
      [key]: !toggleArea[key],
    });
  };
  return (
    <div className="tabs-contents info" id="content2" style={{ display: 'block' }}>
      <div className="section-title">
        <div className="label">상품정보</div>
      </div>
      {(productDetail?.information_image_html_yn === 'Y' &&
        productDetail?.information_image_html_text) ||
        (productDetail?.information_images && productDetail?.information_images.length > 0 && (
          <div className="info-img-wrap">
            {productDetail?.information_image_html_yn === 'Y' ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: productDetail?.information_image_html_text ?? '',
                }}
              />
            ) : (
              productDetail?.information_images?.map((img, idx) => (
                <img key={`informationImg_${idx}`} src={img} alt="상품상세" />
              ))
            )}
          </div>
        ))}
      <div className="info-accordion-wrap">
        {/* <!-- 전체 아코디언 영역 --> */}
        <ul className="accordion-group">
          <li className={`list-item${toggleArea.basic ? ' active' : ''}`}>
            <div className="title" onClick={() => handleToggleBtn('basic')}>
              상품 기본정보
            </div>
            {/* <!-- 상세 테이블 정보 --> */}
            <ul className="panel-group">
              <li className="list-item">
                <div className="row">
                  <div className="col header">상품번호</div>
                  <div className="col">{productDetail?.product_idx}</div>
                </div>
                <div className="row">
                  <div className="col header">배송방법</div>
                  <div className="col">택배</div>
                </div>
              </li>
              <li className="list-item">
                <div className="row">
                  <div className="col header">배송비</div>
                  <div className="col">{`${
                    numberFormat(productDetail?.shipping_fee) ?? '무료'
                  } / 제주 ${numberFormat(
                    productDetail?.shipping_additional_fee_jeju,
                  )}원 / 제주 외 도서지역 ${numberFormat(
                    productDetail?.shipping_additional_fee_remote_area,
                  )}원`}</div>
                </div>
                <div className="row">
                  <div className="col header">상품상태</div>
                  <div className="col">{productDetail?.basic_info_product_status}</div>
                </div>
              </li>
              <li className="list-item">
                <div className="row">
                  <div className="col header">원산지</div>
                  <div className="col">{productDetail?.basic_info_origin}</div>
                </div>
                <div className="row">
                  <div className="col header">영수증 발행</div>
                  <div className="col">{productDetail?.basic_info_receipt_issue}</div>
                </div>
              </li>
              <li className="list-item">
                <div className="row">
                  <div className="col header">A/S 안내</div>
                  <div className="col">{productDetail?.cs_as_phone_number}</div>
                </div>
                <div className="row">
                  <div className="col header">운영시간</div>
                  <div className="col">{productDetail?.cs_hours}</div>
                </div>
              </li>
            </ul>
          </li>
          {/* <!-- 2. 상품 고시정보 --> */}
          <li className={`list-item${toggleArea.noti ? ' active' : ''}`}>
            <div className="title" onClick={() => handleToggleBtn('noti')}>
              상품 고시정보
            </div>
            {/* <!-- 상세 테이블 정보 --> */}
            <ul className="panel-group">
              <li className="list-item">
                <div className="row">
                  <div className="col header">제품명</div>
                  <div className="col">{productDetail?.noinfo_product_name ?? ''}</div>
                </div>
                <div className="row">
                  <div className="col header">식품의 유형</div>
                  <div className="col">{productDetail?.noinfo_food_type ?? ''}</div>
                </div>
              </li>
              <li className="list-item">
                <div className="row">
                  <div className="col header">생산자 및 소재지</div>
                  <div className="col">{productDetail?.noinfo_producer_location ?? ''}</div>
                </div>
                <div className="row">
                  <div className="col header">제조 연월일, 소비기한 또는 품질 유지기한</div>
                  <div className="col">{productDetail?.noinfo_manufacture_date ?? ''}</div>
                </div>
              </li>
              <li className="list-item">
                <div className="row">
                  <div className="col header">포장단위별 내용물의 용량(중량), 수량</div>
                  <div className="col">{productDetail?.noinfo_package_contents ?? ''}</div>
                </div>
                <div className="row">
                  <div className="col header">원재료명 및 함량</div>
                  <div className="col">{productDetail?.noinfo_ingredients ?? ''}</div>
                </div>
              </li>
              <li className="list-item">
                <div className="row">
                  <div className="col header">영양성분</div>
                  <div className="col">{productDetail?.noinfo_nutrition_info ?? ''}</div>
                </div>
                <div className="row">
                  <div className="col header">유전자변형식품에 해당</div>
                  <div className="col">{productDetail?.noinfo_gmo ?? ''}</div>
                </div>
              </li>
              <li className="list-item">
                <div className="row">
                  <div className="col header">소비자 안전을 위한 주의사항</div>
                  <div className="col">{productDetail?.noinfo_safety_notice}</div>
                </div>
                <div className="row">
                  <div className="col header">수익 식품에 해당</div>

                  <div className="col">
                    {productDetail?.noinfo_imported_yn === 'Y'
                      ? '수입식품안전관리특별법에 따른 수입신고를 필함'
                      : '해당없음'}
                  </div>
                </div>
              </li>
            </ul>
          </li>
          {/* <!-- 3. 판매자 정보 --> */}
          <li className={`list-item${toggleArea.seller ? ' active' : ''}`}>
            <div className="title" onClick={() => handleToggleBtn('seller')}>
              판매자 정보
            </div>
            {/* <!-- 상세 테이블 정보 --> */}
            <ul className="panel-group">
              <li className="list-item">
                <div className="row">
                  <div className="col header">상호명</div>
                  <div className="col">{productDetail?.company_name}</div>
                </div>
                <div className="row">
                  <div className="col header">대표자</div>
                  <div className="col">{productDetail?.representative_name}</div>
                </div>
              </li>
              <li className="list-item">
                <div className="row">
                  <div className="col header">고객센터</div>
                  <div className="col">{productDetail?.cs_phone_number}</div>
                </div>
                <div className="row">
                  <div className="col header">고객센터 운영시간</div>
                  <div className="col">{productDetail?.cs_hours}</div>
                </div>
              </li>
              <li className="list-item">
                <div className="row">
                  <div className="col header">소비자 상담</div>
                  <div className="col">{productDetail?.cs_complaint_phone_number}</div>
                </div>
                <div className="row">
                  <div className="col header">A/S 안내</div>
                  <div className="col">{productDetail?.cs_as_phone_number}</div>
                </div>
              </li>
              <li className="list-item">
                <div className="row">
                  <div className="col header">사업자등록번호</div>
                  <div className="col">{productDetail?.business_license_number}</div>
                </div>
                <div className="row">
                  <div className="col header">사업장 소재지</div>
                  <div className="col">{productDetail?.business_address}</div>
                </div>
              </li>
              <li className="list-item">
                <div className="row">
                  <div className="col header">대표 이메일</div>
                  <div className="col">{productDetail?.email_address}</div>
                </div>
                <div className="row">
                  <div className="col header">통신판매업번호</div>
                  <div className="col">{productDetail?.ecommerce_license_number}</div>
                </div>
              </li>
              <li className="list-item notice">
                판매자정보(전화번호, 이메일, 주소 등)는 해당 판매자의 명시적 동의 없이 영리 목적인
                마케팅, 광고 등 용도로 활용할 수 없습니다.
              </li>
              <li className="list-item notice">
                판매자의 명시적 동의 없이 판매자의 정보를 수집 및 활용하여 영리 목적인 마케팅, 광고
                등 용도로 활용하는 경우 정보통신망법 등 관련 법령에 의거하여 과태료 부과 및
                형사처벌의 대상이 될 수 있습니다.
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

interface RefundProps {
  refundImg: string;
}
const Refund = ({ refundImg }: RefundProps) => {
  return (
    <div className="tabs-contents products" id="content4" style={{ display: 'block' }}>
      <div className="section-title">
        <div className="label">교환/반품</div>
      </div>
      <div className="info-img">
        <img src={refundImg} alt="refund" />
      </div>
    </div>
  );
};

interface RightOptionsProps {
  productDetail: WoDetailedProduct;
  onSuccessAddingCart: (title: string, duration: number) => void;
}
/**
 * Desktop(1200px 이상) 에서 노출되는 우측 옵션선택 영역
 */
const RightOptions = ({ productDetail, onSuccessAddingCart }: RightOptionsProps) => {
  return (
    <div className="narrow-col">
      <div className="product-option-wrap">
        {/* <!-- 드롭다운 --> */}
        <OptionDropdown productDetail={productDetail} located="web_mid" />
        {/* <!-- FIXME: 옵션 리스트 클래스명 변경 --> */}
        <SelectedOptionItem />
        <OrderBtnBox
          isSoldOut={productDetail?.sold_out_yn === 'Y'}
          onSuccessAddingCart={onSuccessAddingCart}
          located="web_mid"
        />
      </div>
    </div>
  );
};
