import { useQuery } from '@tanstack/react-query';

import { QueryOptionsType } from '../../../generated/api/type/api';
import { WoCautionArticlesGetRes } from '../../../generated/api/type/data-contracts';
import { get } from '../../api';

interface Props {
  cautionTypeCd: string;
  options?: QueryOptionsType<WoCautionArticlesGetRes>;
}

export default function useFetchCautions({ cautionTypeCd, options }: Props) {
  const getCaution = async () => {
    const response = await get(`/api/commerce/v1/cautions/${cautionTypeCd}/articles`);
    return response.data;
  };
  return useQuery({
    queryKey: ['useFetchCautions', cautionTypeCd],
    queryFn: getCaution,
    ...options,
  });
}
