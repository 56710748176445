export const DISABLED_STATUS = ['C1', 'C2', 'C3', 'C4', 'R1', 'R2', 'R3', 'R4'];
export const DARK_STATUS = [
  '배송오류',
  '취소완료',
  '반품요청중',
  '반품진행중',
  '반품완료',
  '판매자취소',
  '주문취소',
  '교환진행중',
];

export const DELIVERY_MSGS = [
  '선택 안함',
  '문 앞에 놓아주세요',
  '부재 시 연락주세요',
  '배송 전 연락주세요',
  '직접입력',
];
