import { createBrowserRouter, redirect, type RouteObject } from 'react-router-dom';

import BaseLayout from '@/layouts/base-layout';
import { HelpLayout } from '@/layouts/help-layout';
import { MypageLayout } from '@/layouts/mypage-layout';
import { MainPage } from '@/pages';
import { NotFoundPage } from '@/pages/404';
import { AgreementPrivacyPage } from '@/pages/agreement/privacy';
import { AgreementTermsPage } from '@/pages/agreement/terms';
import { CallbackKakaoPage } from '@/pages/callback/kakao';
import { CallbackPassPage } from '@/pages/callback/pass';
import { CartPage } from '@/pages/cart';
import { FriendlyPage } from '@/pages/friendly';
import { HelpFaqPage } from '@/pages/help/faq';
import { HelpInquiryCreatePage } from '@/pages/help/inquiry/create';
import { HelpNoticePage } from '@/pages/help/notice';
import { LoginPage } from '@/pages/login';
import { ResetPasswordPage } from '@/pages/login/reset-password';
import { MypagePage } from '@/pages/mypage';
import { MyInfoPage } from '@/pages/mypage/info';
import { MyInquiryPage } from '@/pages/mypage/inquiry';
import { MypageOrdersListPage } from '@/pages/mypage/orders';
import { MypageOrdersDetailPage } from '@/pages/mypage/orders/[id]';
import { MyReviewPage } from '@/pages/mypage/review';
import { WithdrawalPage } from '@/pages/mypage/withdrawal';
import { OrderPage } from '@/pages/order';
import { OrderResultPage } from '@/pages/order/result';
import { PassSuccessPage } from '@/pages/pass/success';
import { ProductDetailPage } from '@/pages/product/[id]';
import { RegisterPage } from '@/pages/register';
import { SearchPage } from '@/pages/search/[keyword]';
import { ServiceStatusMiddleware } from '@/route/middleware/service-status-middleware';
import { PATH } from '@/constants/path';
import { AUTH_TOKEN_KEY, PRIVATE_PATHS } from '@/constants/auth';
import { ShowcasePage } from '@/pages/showcase';
import { getUA, isAndroid } from 'react-device-detect';
import { CallbackInstagramPage } from '@/pages/callback/instagram';

const router: RouteObject[] = [
  {
    path: PATH.RESET_PASSWORD,
    element: <ResetPasswordPage />,
  },
  {
    path: PATH.CALLBACK_PASS,
    element: <CallbackPassPage />,
  },
  {
    path: PATH.CALLBACK_KAKAO,
    element: <CallbackKakaoPage />,
  },
  {
    path: PATH.CALLBACK_INSTAGRAM,
    element: <CallbackInstagramPage />,
  },
  {
    path: '/',
    element: (
      <ServiceStatusMiddleware>
        <BaseLayout />
      </ServiceStatusMiddleware>
    ),
    loader: async ({ request }) => {
      const userAgent = getUA.toLowerCase();
      const isInstagramWebView = userAgent.includes('instagram');
      const url = new URL(request.url);
      const { pathname, search } = url;
      const userToken =
        sessionStorage.getItem(AUTH_TOKEN_KEY) ?? localStorage.getItem(AUTH_TOKEN_KEY) ?? '';
      const beforeUrl = pathname + search;
      if (isAndroid && isInstagramWebView && !userToken) {
        return redirect(`${PATH.CALLBACK_INSTAGRAM}?before_url=${encodeURIComponent(beforeUrl)}`);
      }
      if (PRIVATE_PATHS.find(path => pathname.includes(path))) {
        const userToken =
          sessionStorage.getItem(AUTH_TOKEN_KEY) ?? localStorage.getItem(AUTH_TOKEN_KEY) ?? '';

        if (!userToken) {
          const beforeUrl = pathname + search;
          return redirect(`${PATH.LOGIN}?before_url=${encodeURIComponent(beforeUrl)}`);
        }
      }
      return null;
    },
    children: [
      {
        path: PATH.HOME,
        element: <MainPage />,
      },
      {
        path: PATH.FRIENDLY,
        element: <FriendlyPage />,
      },
      {
        path: `${PATH.SHOWCASE}/:id`,
        element: <ShowcasePage />,
      },
      {
        path: `${PATH.PRODUCT_DETAIL}/:id`,
        element: <ProductDetailPage />,
      },
      {
        path: PATH.LOGIN,
        element: <LoginPage />,
        loader: async () => {
          const userToken =
            sessionStorage.getItem(AUTH_TOKEN_KEY) ?? localStorage.getItem(AUTH_TOKEN_KEY) ?? '';
          if (!!userToken) {
            return redirect('/');
          }
          return null;
        },
      },
      {
        path: PATH.PASS_SUCCESS,
        element: <PassSuccessPage />,
      },
      {
        path: PATH.REGISTER,
        element: <RegisterPage />,
        loader: async () => {
          const userToken =
            sessionStorage.getItem(AUTH_TOKEN_KEY) ?? localStorage.getItem(AUTH_TOKEN_KEY) ?? '';
          if (!!userToken) {
            return redirect('/');
          }
          return null;
        },
      },
      {
        path: PATH.SEARCH,
        element: <SearchPage />, //TODO: 현재는 :keyword 페이지와 동일하게 사용하나 새로운 페이지로 구성해야됨
      },
      {
        path: `${PATH.SEARCH}/:keyword`,
        element: <SearchPage />,
      },
      {
        path: PATH.CART,
        element: <CartPage />,
      },
      {
        path: PATH.ORDER,
        element: <OrderPage />,
      },
      {
        path: PATH.ORDER_COMPLETE,
        element: <OrderResultPage />,
      },
      {
        path: PATH.MYPAGE,
        element: <MypageLayout />,
        children: [
          {
            path: PATH.MYPAGE,
            element: <MypagePage />,
          },
          {
            path: PATH.MY_INFO,
            element: <MyInfoPage />,
          },
          {
            path: PATH.MY_WITHDRAWAL,
            element: <WithdrawalPage />,
          },
          // {
          //   path: '/mypage/point',
          //   element: <MyPage />,
          // },
          // {
          //   path: '/mypage/coupon',
          //   element: <MyPage />,
          // },
          {
            path: PATH.MY_INQUIRY,
            element: <MyInquiryPage />,
          },
          {
            path: PATH.MY_REVIEW,
            element: <MyReviewPage />,
          },
          {
            path: PATH.ORDER_LIST,
            element: <MypageOrdersListPage />,
          },
          {
            path: `${PATH.ORDER_LIST}/:id`,
            element: <MypageOrdersDetailPage />,
          },
        ],
      },
      {
        path: PATH.HELP,
        element: <HelpLayout />,
        children: [
          {
            path: PATH.HELP,
            loader: () => {
              return redirect(PATH.HELP_FAQ);
            },
          },
          {
            path: PATH.HELP_FAQ,
            element: <HelpFaqPage />,
          },
          {
            path: PATH.HELP_NOTICE,
            element: <HelpNoticePage />,
          },
          {
            path: PATH.HELP_INQUIRY_CREATE,
            element: <HelpInquiryCreatePage />,
          },
        ],
      },
      {
        path: PATH.AGREEMENT_PRIVACY,
        element: <AgreementPrivacyPage />,
      },
      {
        path: PATH.AGREEMENT_TERMS,
        element: <AgreementTermsPage />,
      },
      {
        path: '/*',
        element: <NotFoundPage />,
      },
    ],
  },
  {},
];

export default createBrowserRouter(router);
