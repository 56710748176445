import { get } from '@/apis/api';
import { useQuery } from '@tanstack/react-query';
import { type QueryOptionsType } from '@/generated/api/type/api';
import { type WoBannersGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  bannerType: 'top_header' | 'store_tab';
  options?: QueryOptionsType<WoBannersGetRes>;
}

export function useFetchBanners({ bannerType }: Props) {
  const getBanners = async () => {
    const response = await get(`/api/commerce/v1/banners?banner_type=${bannerType}`);
    return response.data as WoBannersGetRes;
  };

  return useQuery({
    queryKey: ['useFetchBanners', bannerType],
    queryFn: getBanners,
  });
}
