import { useContext } from 'react';
import {
  ProductDispatchContext,
  ProductStateContext,
} from '@components/domain/product/product-provider';

export const useProduct = () => {
  const productContext = useContext(ProductStateContext);
  const productDispatch = useContext(ProductDispatchContext);

  return { productContext, productDispatch };
};
