import { useEffect, useMemo } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import { AUTH_PASS_KEY, AUTH_REDIRECT_URL_KEY } from '@/constants/auth';
export const CallbackPassPage = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const redirectUrl = sessionStorage.getItem(AUTH_REDIRECT_URL_KEY);
  const mdl_tkn = useMemo(() => {
    const { mdl_tkn } = queryString.parse(search);
    return (mdl_tkn as string) || '';
  }, [search]);

  useEffect(() => {
    if (mdl_tkn) {
      sessionStorage.setItem(AUTH_PASS_KEY, mdl_tkn);
    }

    if (redirectUrl) {
      navigate(redirectUrl);
    }
  }, [redirectUrl, mdl_tkn, isMobile, isTablet]);
  return <></>;
};
