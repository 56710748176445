import { ReactNode, useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';

import { useNetwork } from '@/hooks/use-network';

interface NetworkProviderProps {
  children: ReactNode;
}

/**
 * CSR 구조에서는 네트워크가 끊겨도 스크립트로 로직 실행이 가능하기 때문에
 * 유저에게 네트워크 연결 유무를 공지합니다.
 * @param children
 * @constructor
 */
export const NetworkProvider = ({ children }: NetworkProviderProps) => {
  const status = useNetwork({});
  const isOnline = useMemo(() => {
    return status;
  }, [status]);

  useEffect(() => {
    if (!isOnline) {
      toast.error('네트워크 연결이 끊겼습니다.\n다시 연결해주세요.');
    }
  }, [isOnline]);

  return <>{children}</>;
};
