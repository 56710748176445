import { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';

import deleteIC from '@/assets/ic/x-sm.svg';
import { WoCartSellerSection } from '@/generated/api/type/data-contracts';
import { numberFormat } from '@/utils/formats';

interface SellerGroupProps {
  seller: WoCartSellerSection;
  selected: number[];
  handleSelection: ({ e, idxes }: { e: ChangeEvent<HTMLInputElement>; idxes: number[] }) => void;
  handleClickProduct: (productIdx: number) => void;
  handleClickCounter: (idx: number, quantity: number) => void;
  handleClickDelete: (idxes: number[]) => void;
}
export const SellerGroup = ({
  seller,
  selected,
  handleSelection,
  handleClickProduct,
  handleClickCounter,
  handleClickDelete,
}: SellerGroupProps) => {
  return (
    <>
      {seller?.product_sections?.map(product => {
        return product?.product_options?.map(option => {
          return (
            <li className="store-item" key={`${product.product_idx}${option.user_cart_option_idx}`}>
              <label className="checkbox-group green-box sm-box">
                <input
                  type="checkbox"
                  name="test-2"
                  checked={selected.includes(option.user_cart_option_idx)}
                  onChange={e => handleSelection({ e, idxes: [option.user_cart_option_idx] })}
                />
                <span className="label" />
                <span className="checkmark" />
              </label>
              {/* <!-- 제품 상세 --> */}
              <div className="product-item horizontal">
                <div className="thumbnail" onClick={() => handleClickProduct(product?.product_idx)}>
                  <img src={product?.main_image_url} alt="제품이미지" />
                </div>
                <div className="info-wrap">
                  <div className="col desktop-flex-col">
                    <p className="title" onClick={() => handleClickProduct(product?.product_idx)}>
                      {option?.option_name}
                    </p>
                    <p className="option">{option?.option_name}</p>
                    <div className="pricing-wrap">
                      <div className="pricing">
                        {numberFormat(option.real_price)}
                        <span className="unit">원</span>
                      </div>
                      <div className="prev-price">
                        {numberFormat(option.sale_price)}
                        <span className="unit">원</span>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="stepper">
                      <input
                        className="form-control stepper-input"
                        type="number"
                        id="stepper-input"
                        value={option?.quantity}
                        onChange={() => {}}
                        min="1"
                        max="10"
                        step="1"
                      />
                      <button
                        id="decrease"
                        className={`value-control decrease${
                          option?.quantity === 1 ? ' disabled' : ''
                        }`}
                        onClick={() =>
                          option?.quantity !== 1 &&
                          handleClickCounter(option.user_cart_option_idx, option?.quantity - 1)
                        }
                      />
                      <button
                        id="increase"
                        className={`value-control increase${
                          option?.quantity === 10 ? ' disabled' : ''
                        }`}
                        onClick={() =>
                          option?.quantity !== 10 &&
                          handleClickCounter(option.user_cart_option_idx, option?.quantity + 1)
                        }
                      />
                    </div>
                  </div>
                  <div className="col desktop-col">
                    <div className="label">상품금액</div>
                    <div className="pricing">
                      {numberFormat(option?.option_tot_amount)}
                      <span className="unit">원</span>
                    </div>
                  </div>
                  <div className="col desktop-col">
                    <div className="label">배송비</div>
                    <div className="pricing">
                      {!seller?.shipping_fee ? (
                        <span className="unit">무료배송</span>
                      ) : (
                        <>
                          {numberFormat(seller?.shipping_fee ?? 0)}
                          <span className="unit">원</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Link
                className="btn-delete"
                to="#"
                onClick={e => {
                  e.preventDefault();
                  handleClickDelete([option?.user_cart_option_idx]);
                }}
              >
                <img src={deleteIC} alt="" />
              </Link>
            </li>
          );
        });
      })}
    </>
  );
};
