import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { type QueryOptionsType } from '@/generated/api/type/api';
import { type WoCartItemsGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  accessToken?: string;
  options?: QueryOptionsType<WoCartItemsGetRes>;
}

export function useFetchCart({ accessToken, options }: Props) {
  const getCartList = async () => {
    return await get('/api/commerce/v1/cart/items').then(res => res.data);
  };

  return useQuery({
    queryKey: ['useFetchCart', accessToken],
    queryFn: getCartList,
    staleTime: 0,
    gcTime: 0,
    ...options,
  });
}
