import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import closeIc from '@/assets/ic/x-lg.svg';
import { useLockBodyScroll } from '@/hooks/use-lock-body-scroll';

interface ModalProps {
  children: ReactNode;
  toggleModal: () => void;
  title: string;
  btns: { title: string; handleClick: () => void; disabled?: boolean }[];
  isOpen: boolean;
}
/**
 * 공통 모달
 * TODO: 사용처에서 isOpen을 전달하게끔 수정해야된다.
 * TODO: 모달컴포넌트 내부에서 활성화여부를 알아야 인터랙션 등등 공통화된 모달 로직을 수행할 수 있다.
 * TODO: Props ex => isOpen, onClose() ... props 수정해야 됨
 * 현재는 사용처에서 조건부 렌더링으로 모달을 활성화하게끔 되어있음
 *
 */
export const Modal = ({ children, toggleModal, title, btns, isOpen }: ModalProps) => {
  useLockBodyScroll(isOpen);

  return (
    <div className="modal-background">
      <div className="modal-fluid">
        <div className="modal-fluid-nav">
          <div className="title">{title}</div>
          <Link to="#" className="btn-close" onClick={toggleModal}>
            <img src={closeIc} alt="modal close" />
          </Link>
        </div>
        <div className="modal-contents">{children}</div>
        <div className="mobile-bottom-nav">
          <div className="btn-inline-group">
            {btns.map((btn, idx) => (
              <button
                key={`modal_btn_${idx}`}
                type="button"
                className={`btn btn-md btn-filled btn-block${btn.disabled ? ' disabled' : ''}`}
                onClick={btn.handleClick}
                disabled={btn.disabled}
              >
                {btn.title}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
