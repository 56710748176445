import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

interface Props {
  type: 'cancel' | 'exchange' | 'refund';
  orderIdx: string;
  cancelReasonCd: string;
  requestText: string;
  orderOptions: number[];
}

const getPath = (type: 'cancel' | 'exchange' | 'refund') =>
  type === 'cancel' ? '/payment/cancel' : type === 'exchange' ? '/begin-exchange' : '/begin-refund';

/**
 *  주문 취소/ 교환/ 반품 요청 공통 mutation
 */
export function useMutationOrderCancel() {
  const cancelOrder = async ({
    type,
    orderIdx,
    cancelReasonCd,
    requestText,
    orderOptions,
  }: Props) => {
    const res = await post(`/api/commerce/v1/orders/${orderIdx}${getPath(type)}`, {
      order_request_reason_cd: cancelReasonCd,
      order_option_idxes: orderOptions,
      request_text: requestText,
    });
    return res.data;
  };
  return useMutation({ mutationFn: cancelOrder });
}
