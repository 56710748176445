import { FC, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useLocation } from 'react-router-dom';
import { MainTopRolling } from '@/components/domain/main/main-top-rolling';

import { useScrollTop } from '@/hooks/use-scroll-top';
import { useSearchUrl } from '@/hooks/use-search-url';
import { Footer } from '@/layouts/base-layout/footer';
import { ClosureGnb } from '@/layouts/base-layout/header/closure-gnb';
import { Gnb } from '@/layouts/base-layout/header/gnb';
import { ErrorFallback } from '@/layouts/error-fallback';
import { setSessionStorage } from '@/utils';
import { PATH } from '@/constants/path';

const BaseLayout: FC = () => {
  const isClosure = process.env.REACT_APP_PATH === 'closure';
  const location = useLocation();
  const { getStateBySearchParams } = useSearchUrl();
  const referrerIdx = getStateBySearchParams('referrerIdx');
  const { pathname } = useLocation();

  useScrollTop();

  useEffect(() => {
    referrerIdx && setSessionStorage('REFERRER', referrerIdx ?? '');
  }, [referrerIdx]);

  const globalClassName = () => {
    if (pathname === PATH.HOME && !isClosure) return 'home';
    if (pathname.includes('/product/') || pathname.includes('/closure/')) return 'with-bottom-nav';
    if (pathname.includes('/cart')) return 'with-cart-bottom-nav';
    if ([PATH.FRIENDLY].includes(pathname)) return 'with-navbar-expand';
    return '';
  };
  const isMypage = pathname.includes(PATH.MYPAGE);
  const isHelp = pathname.includes(PATH.HELP);
  const noShowFooter =
    pathname.includes('login') || pathname.includes('register') || pathname.includes('withdrawal');

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {!isClosure ? <Gnb /> : <ClosureGnb />}
      {!isClosure && location.pathname === PATH.HOME && <MainTopRolling />}
      <div
        className={`${isMypage || isHelp ? 'my' : 'global'}-container ${globalClassName()}${
          isClosure ? ' closure' : ''
        }`}
      >
        <Outlet />
      </div>
      {!noShowFooter && <Footer />}
    </ErrorBoundary>
  );
};

export default BaseLayout;
