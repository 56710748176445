import { AxiosResponse } from 'axios';
import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';
import { AxiosError } from '@/generated/api/type/api';

interface Props {
  bCsQnaIdx?: number;
  productIdx?: number;
  orderOptionIdx?: number;
  qnaTypeCd: string;
  orderRequestReasonCd?: string;
  qnaText: string;
  openYn: 'Y' | 'N';
  sellerIdx?: number;
  notiAnswerYn: 'Y' | 'N';
  file?: File;
}

export const useMutationInquiryCreate = ({ qnaBoardTypeCd }: { qnaBoardTypeCd: string }) => {
  const createInquiry = async ({
    bCsQnaIdx,
    productIdx,
    orderOptionIdx,
    qnaTypeCd,
    orderRequestReasonCd,
    qnaText,
    openYn,
    sellerIdx,
    notiAnswerYn,
    file,
  }: Props) => {
    const formData = new FormData();
    file && formData.append('files', file);
    bCsQnaIdx && formData.append('b_cs_qna_idx', String(bCsQnaIdx));
    productIdx && formData.append('product_idx', String(productIdx));
    orderOptionIdx && formData.append('order_option_idx', String(orderOptionIdx));
    orderRequestReasonCd && formData.append('order_request_reason_cd', orderRequestReasonCd);
    sellerIdx && formData.append('seller_idx', String(sellerIdx));
    formData.append('qna_type_cd', qnaTypeCd);
    formData.append('qna_text', qnaText);
    formData.append('noti_answer_yn', notiAnswerYn || 'N');
    formData.append('open_yn', openYn); // Y: 공개, N: 비공개 -> 1:1 게시판은 N으로 보냄
    formData.append('resolve_yn', 'N'); // admin에서 답변달 때 Y로 보냄

    return await post(`/api/commerce/v1/boards/${qnaBoardTypeCd}/articles/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(res => res)
      .catch(err => err?.response as AxiosResponse<AxiosError>);
  };
  return useMutation({ mutationFn: createInquiry });
};
