import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';

import { useMutationPwdResetRequest } from '@/apis/friendly-pharmacist/user/useMutationPwdResetRequest';

interface ResetPasswordRequestFormProps {
  setAlertState: Dispatch<
    SetStateAction<{
      isAlertVisible: boolean;
      header?: string[];
      body: string[];
      buttons: [{ title: string; handleClick: () => void; style: string }];
    }>
  >;
  closeAlert: () => void;
}
export const ResetPasswordRequestForm = ({
  setAlertState,
  closeAlert,
}: ResetPasswordRequestFormProps) => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [email, setEmail] = useState('');

  const handlePasswordValidation = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;
    switch (name) {
      case 'email':
        setEmail(value);
        break;
      default:
        break;
    }
  };
  const { mutate: mutateResetRequest, isError } = useMutationPwdResetRequest();

  const handlePwdResetBtnClick = () => {
    setBtnDisabled(true);
    !btnDisabled &&
      mutateResetRequest(
        { email },
        {
          onSuccess: () => {
            setAlertState({
              isAlertVisible: true,
              body: [`${email} 으로`, '비밀번호 재설정 메일을 발송했어요.'],
              buttons: [
                {
                  title: '확인',
                  handleClick: closeAlert,
                  style: 'primary',
                },
              ],
            });
            setBtnDisabled(true);
          },
          onError: (error: any) => {
            setAlertState({
              isAlertVisible: true,
              body: [error?.response?.data.message ?? '다시 시도해주세요.'],
              buttons: [
                {
                  title: '확인',
                  handleClick: closeAlert,
                  style: 'primary',
                },
              ],
            });
            setBtnDisabled(false);
          },
        },
      );
  };
  return (
    <div className="login-contents-wrap">
      <div className="input-group">
        <div className="form-label">이메일</div>
        <input
          type="text"
          className={`form-control ${isError ? 'has-validation' : ''}`}
          placeholder="가입한 이메일 주소"
          name="email"
          value={email}
          onChange={handlePasswordValidation}
          onKeyDown={e => e.key === 'Enter' && handlePwdResetBtnClick()}
        />
        {isError && <div className="form-text">이메일에 해당하는 사용자를 찾을 수 없습니다.</div>}
      </div>
      <div className="action-group">
        <button
          type="button"
          className="btn btn-md btn-filled btn-block"
          onClick={handlePwdResetBtnClick}
          disabled={btnDisabled}
        >
          비밀번호 재설정
        </button>
      </div>
    </div>
  );
};
