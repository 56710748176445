import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';
import { WoOrderBeginPostRes } from '@/generated/api/type/data-contracts';

export const useMutationOrder = () => {
  const order = async (preorder_idx: string) => {
    return await post('/api/commerce/v1/orders/begin', { preorder_idx }).then(
      ({ data }: { data: WoOrderBeginPostRes }) => data,
    );
  };

  return useMutation({ mutationFn: order });
};
