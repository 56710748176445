import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { QueryOptionsType } from '@/generated/api/type/api';
import { WoProductsSearchGetRes } from '@/generated/api/type/data-contracts';

interface RequestParam {
  keyword: string;
  offset: number;
  limit: number;
  orders: { orderColumn: string; order: string };
  options?: QueryOptionsType<WoProductsSearchGetRes>;
}

export function useFetchSearch({ keyword, offset, limit, orders, options }: RequestParam) {
  const getSearch = async () => {
    return await get(
      `/api/commerce/v1/products/search?input_kword=${keyword}&offset=${offset}&limit=${limit}&order_column=${orders.orderColumn}&order=${orders.order}`,
    ).then(res => res.data as WoProductsSearchGetRes);
  };

  return useQuery({
    queryKey: ['useFetchSearch', [keyword, offset, limit, orders]],
    queryFn: getSearch,
    ...options,
  });
}
