import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { HelpSidebarDesktop } from '@/layouts/help-layout/help-sidebar/help-sidebar-desktop';

export const HelpLayout: FC = () => {
  return (
    <>
      <HelpSidebarDesktop />
      <Outlet />
    </>
  );
};
