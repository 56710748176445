import { ChangeEvent, useState } from 'react';
import { Modal } from '@components/common/molecules/modal/index';

import { useMutationReportReview } from '@/apis/commerce/reviews/useMutationReportReview';
import { useFetchReviewSpecs } from '@/apis/commerce/specs/useFetchReviewSpecs';
import { returnVoid } from '@/utils';

interface ReportModalProps {
  reviewIdx: number;
  toggleModal: (success?: boolean, toastOpen?: boolean, message?: string) => void;
  isOpen: boolean;
}

export const ReportModal = ({ reviewIdx, toggleModal, isOpen }: ReportModalProps) => {
  const [selected, setSelected] = useState('');
  const [textbox, setTextbox] = useState('');
  const { data: reportData } = useFetchReviewSpecs();
  const { mutate: mutateReport } = useMutationReportReview({
    reviewIdx,
    typeCd: selected,
    text: textbox,
  });
  const handleChangeTextBox = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextbox(e.currentTarget.value);
  };
  const handleChangeSelect = (type: string) => {
    setSelected(type);
  };

  const handleClickReport = () => {
    mutateReport(returnVoid(), {
      onSuccess: () => {
        toggleModal(true, true, '신고되었어요.');
      },
    });
  };

  return (
    <Modal
      toggleModal={toggleModal}
      title="신고"
      btns={[
        {
          title: '신고하기',
          handleClick: () => handleClickReport(),
          disabled: selected === '9' && textbox.length < 10,
        },
      ]}
      isOpen={isOpen}
    >
      <div className="row main">
        <ul className="sort-list">
          {reportData?.review_violation_type_codes?.map(reason => (
            <li>
              <label className="radio-group">
                <input
                  type="radio"
                  name="test-2"
                  checked={selected === reason?.violation_type_cd}
                  onChange={() => handleChangeSelect(reason?.violation_type_cd ?? '')}
                />
                <span className="label">{reason?.violation_type_name}</span>
                <span className="checkmark"></span>
              </label>
            </li>
          ))}
        </ul>
        {selected === '9' && (
          <div className="input-group">
            <textarea
              className="form-control"
              placeholder="최소 10자 이상 작성"
              onChange={handleChangeTextBox}
            />
            <div className="view-words">
              <span className="value">{textbox.length}</span>/1000
            </div>
          </div>
        )}
      </div>
      <div className="row caution">
        <span className="with-icon">
          고객님이 신고해 주신 내용은 관리자 검토 후 내부 리뷰 운영 정책에 맞춰 조치됩니다.
        </span>
      </div>
    </Modal>
  );
};
