import { useQuery } from '@tanstack/react-query';

import { GET_USER_INFO_KEY, getUserInfo } from '@/apis/friendly-pharmacist/user/user';
import { QueryOptionsType } from '@/generated/api/type/api';

export interface UserInfoRes {
  user_info: {
    id: string;
    account_id?: string;
    account_sns_type?: string;
    created_at?: string;
    nickname?: string;
    phone?: string;
    email?: string;
    name?: string;
    gender: string;
    has_health_info: boolean;
    birthdate: string;
    filter_option: {
      gender: string;
      age_group: string;
      age_group_supplement: string;
    };
    agreements: {
      sms: {
        value: boolean;
        updated_at: string;
      };
      email: {
        value: boolean;
        updated_at: string;
      };
      push: {
        value: boolean;
        updated_at: string;
      };
    };
  };
  connect_accounts: [
    {
      sns_type: string;
      email: string;
    },
  ];
}

interface Props {
  accessToken: string;
  options?: QueryOptionsType<UserInfoRes>;
}

/**
 * 친한약사 api: 유저 정보 조회
 * @returns {UserInfoRes}
 */
export function useFetchGetUserInfo({ accessToken, options }: Props) {
  const getUserInfoFn = async () => {
    const res = await getUserInfo({ accessToken });
    return res?.data as UserInfoRes;
  };

  return useQuery({
    queryKey: [GET_USER_INFO_KEY, accessToken],
    queryFn: () => getUserInfoFn(),
    gcTime: 0,
    staleTime: 0,
    ...options,
  });
}
