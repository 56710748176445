import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

interface PrdListPaginationDesktopProps {
  total: number;
  limit: number;
  setOffset: Dispatch<SetStateAction<number>>;
}
export const PrdListPaginationDesktop = ({
  setOffset,
  limit,
  total,
}: PrdListPaginationDesktopProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageGroup, setPageGroup] = useState(0);
  const totalPages = Math.ceil(total / limit);
  const pages = Array(totalPages)
    .fill(false)
    .map((_, idx) => idx + 1);

  useEffect(() => {
    setCurrentPage(1);
  }, [limit]);

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
    setOffset((page - 1) * limit);
  };

  const handleNextGroup = () => {
    if ((pageGroup + 1) * 10 < totalPages) {
      setPageGroup(pageGroup + 1);
      handlePageClick((pageGroup + 1) * 10 + 1);
    } else {
      handlePageClick(totalPages);
    }
  };

  const handlePrevGroup = () => {
    if (pageGroup > 0) {
      setPageGroup(pageGroup - 1);
      handlePageClick(pageGroup * 10);
    } else {
      handlePageClick(1);
    }
  };
  return (
    totalPages > 1 && (
      <div id="load-pagenation">
        <div className="pagenation-group" id="pagenation">
          <ul className="pagenation">
            <li>
              <Link to="#" className="btn btn-outline btn-rounded" onClick={handlePrevGroup} />
            </li>
            {pages.slice(pageGroup * 10, (pageGroup + 1) * 10).map(page => (
              <li key={`main_product_page_${page}`}>
                <Link
                  to="#"
                  className={`page ${page === currentPage ? 'active' : ''}`}
                  onClick={e => {
                    e.preventDefault();
                    handlePageClick(page);
                  }}
                >
                  {page}
                </Link>
              </li>
            ))}
            <li>
              <Link
                to="#"
                className="btn btn-outline btn-rounded right"
                onClick={handleNextGroup}
              />
            </li>
          </ul>
        </div>
      </div>
    )
  );
};
