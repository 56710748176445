import { useEffect } from 'react';
import { AlertModal } from '@components/common/molecules/modal/alert-modal';
import { Pagination } from '@components/common/molecules/pagination';
import { Toast } from '@components/common/molecules/toast';

import { useFetchMyReviews } from '@/apis/commerce/self/useFetchMyReviews';
import { ReviewModal } from '@/components/domain/review/review-modal';
import useReviewList from '@/hooks/use-review-list';
import { numberFormat } from '@/utils/formats';

import { ReviewItem } from './review-item';

interface ReviewListDesktopProps {
  limit?: number;
}
export const ReviewListDesktop = ({ limit }: ReviewListDesktopProps) => {
  const {
    initialValue,
    offset,
    activeReview,
    currentPage,
    toastState,
    alertState,
    reviewCreateModal,
    closeModal,
    setValue,
    toggleToast,
    handleClickCreateReview,
    handleClickDeleteBtn,
    handleClickShowReview,
  } = useReviewList();

  const { data: reviewData, refetch: refetchReviewData } = useFetchMyReviews({
    offset,
    limit: limit ?? initialValue.limit,
    options: { staleTime: 0 },
  });

  useEffect(() => {
    refetchReviewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // TODO: api 호출 오래걸려서 로딩바 필요할듯
  return (
    <>
      {alertState.isShow && (
        <AlertModal
          isOpen={alertState.isShow}
          header={alertState.header}
          body={alertState.body}
          buttons={alertState.buttons}
        />
      )}
      {toastState.isShow && <Toast message={toastState.message} handleClickClose={toggleToast} />}
      {reviewCreateModal !== 0 && (
        <ReviewModal
          isOpen={true}
          toggleModal={closeModal}
          prdId={String(reviewCreateModal)}
          createSuccess={refetchReviewData}
        />
      )}
      {(reviewData?.total_count ?? 0) > 0 && offset > 3 && (
        <div className="product-list-filter filter-sm">
          <div className="result-value">리뷰 {numberFormat(reviewData?.total_count)}</div>
          <ul className="desktop-list-sorting">
            <li>
              <button type="button" className={`btn btn-subtle btn-sorting${' active'}`}>
                전체
              </button>
            </li>
            <li>
              <button type="button" className="btn btn-subtle btn-sorting">
                작성
              </button>
            </li>
            <li>
              <button type="button" className="btn btn-subtle btn-sorting">
                미작성
              </button>
            </li>
          </ul>
        </div>
      )}
      {!reviewData?.total_count && !reviewData?.reviews?.length ? (
        <div className="empty-list">
          <div className="lines">
            <span>"첫 리뷰를 남겨주세요"</span>
            <span className="line"></span>
          </div>
          <div className="sub-text">
            아직 작성된 리뷰가 없습니다.
            <br />
            다른 고객님의 선택에 도움을 주세요.
          </div>
        </div>
      ) : (
        reviewData?.reviews?.length && (
          <ul className="myReview-list">
            {reviewData.reviews.map(review => (
              <ReviewItem
                key={`review_${review?.review_idx}`}
                activeClassName={review?.review_idx === activeReview ? 'active' : 'close'}
                hasReviewed={!!review?.review_text_auto}
                review={review}
                activeReview={activeReview}
                handleClickCreateReview={handleClickCreateReview}
                handleClickDeleteBtn={handleClickDeleteBtn}
                toggleReview={handleClickShowReview}
                refetchReviewData={refetchReviewData}
              />
            ))}
          </ul>
        )
      )}
      {limit && limit > 3 && (reviewData?.total_count ?? 0) > 10 && (
        <Pagination
          total={reviewData?.total_count ?? 0}
          limit={limit}
          setValue={setValue}
          currentPage={Number(currentPage)}
        />
      )}
    </>
  );
};
