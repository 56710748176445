import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { QueryOptionsType } from '@/generated/api/type/api';

interface Props {
  uuid: string;
  token: string;
  options?: QueryOptionsType<{}>;
}

// 2. 비밀번호 초기화를 위한 토큰 정보 검증
export function useFetchTokenValidation({ token, uuid }: Props) {
  const request = async () => {
    const response = get(`/v1/user/password/reset/token-validation?uuid=${uuid}&token=${token}`);
    return response;
  };
  return useQuery({
    queryKey: ['useFetchTokenValidation'],
    queryFn: request,
  });
}
