import { get } from '@/apis/api';
import { useQuery } from '@tanstack/react-query';

interface Props {
  csShowcaseIdx: number;
}

export default function useFetchShowcase({ csShowcaseIdx }: Props) {
  const getShowcase = async () => {
    return await get(`/api/commerce/v1/showcases/${csShowcaseIdx}/details`).then(res => res.data);
  };

  return useQuery({
    queryKey: ['useFetchShowcase'],
    queryFn: getShowcase,
    staleTime: 0,
    gcTime: 0,
  });
}
