import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

interface Props {
  qnaBoardTypeCd: string;
  qnaIdx: number;
}

export function useMutationInquiryDelete() {
  const deleteInquiry = async ({ qnaBoardTypeCd, qnaIdx }: Props) => {
    const response = await post(
      `/api/commerce/v1/boards/${qnaBoardTypeCd}/articles/${qnaIdx}/delete`,
      {},
    );
    return response.data;
  };

  return useMutation({ mutationFn: deleteInquiry });
}
