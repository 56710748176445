import { useSearchParams } from 'react-router-dom';

/**
 *  @interface SearchStateResult
 *  @type {Object}
 *  @property {getStateBySearchParams} Function 전달받은 key로 URLSearchParams 조회
 *  @property {setStateBySearchParams} Function 현재 상태를 URLSearchParams로 저장
 */

interface SearchStateResult {
  getStateBySearchParams: (key: string) => string | null;
  setStateBySearchParams: (newParams: SetCardProps[], state?: any) => void;
  deleteBySearchParams: (key: string) => void;
}

interface SetCardProps {
  key: string;
  value: string;
  state?: any;
}
/**
 * @return {SearchStateResult}  URLSearchParams에서 저장된 상태값 읽는 함수
 */
export const useSearchUrl = (): SearchStateResult => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setStateBySearchParams = (newParams: SetCardProps[], state?: any) => {
    let addCount = 0;

    newParams.forEach(param => {
      searchParams.set(param.key, param.value);
      ++addCount;
    });

    if (addCount > 0) {
      setSearchParams(searchParams, {
        replace: true,
        state: { disabledScrollTo: 'Y', ...state },
      });
    }
  };

  const getStateBySearchParams = (key: string) => {
    return searchParams.get(key);
  };

  const deleteBySearchParams = (key: string) => {
    searchParams.delete(key);
    setSearchParams(searchParams, {
      replace: true,
      state: { disabledScrollTo: 'Y' },
    });
  };

  return {
    getStateBySearchParams,
    setStateBySearchParams,
    deleteBySearchParams,
  };
};
