import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';
import { WoPreorderCreateViaCartPostReq } from '@/generated/api/type/data-contracts';

/**
 * 카트에서 '주문하기'
 * @param param0 ㅋ
 * @returns
 */
export function useMutationOrderViaCart() {
  const orderCart = async ({
    referrer_user_idx = 0,
    referrer_influencer_idx = 0,
    user_cart_option_idxes,
  }: WoPreorderCreateViaCartPostReq) => {
    const { data } = await post('/api/commerce/v1/preorders/create-via-cart', {
      referrer_user_idx: referrer_user_idx ?? null,
      referrer_influencer_idx: referrer_influencer_idx ?? null,
      user_cart_option_idxes,
    });
    return data;
  };
  return useMutation({ mutationFn: orderCart });
}
