import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import qs from 'qs';

const paramsSerializer = (params: any) => qs.stringify(params);

export const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  paramsSerializer,
});

export const AXIOS_INSTANCE_FP = Axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL,
  paramsSerializer,
});

interface Options {
  accessToken?: string;
  headers?: Record<string, any>;
}

/**
 * optinos 라는 파라미터를 정의했지만
 * 자동생성 될 때 request라고 orval에서 변경됨
 *
 * @param config
 * @param options
 */
export const axiosInstance = async <T>(
  config: AxiosRequestConfig,
  options?: Options,
): Promise<T> => {
  config.headers = {
    ...config.headers,
    ...options?.headers,
    ...(options?.accessToken && { Authorization: `Bearer ${options?.accessToken}` }),
  };

  const { data } = await AXIOS_INSTANCE(config);
  return data;
};

export const axiosInstanceToFp = async <T>(
  config: AxiosRequestConfig,
  options?: Options,
): Promise<T> => {
  config.headers = {
    ...config.headers,
    ...options?.headers,
    ...(options?.accessToken && { Authorization: `Bearer ${options?.accessToken}` }),
  };

  const { data } = await AXIOS_INSTANCE_FP(config);
  return data;
};

export default AXIOS_INSTANCE;

export type ErrorType<Error> = AxiosError<Error>;
export type AwaitedInput<T> = PromiseLike<T> | T;
export type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;
export type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];
