import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';
import { WoUserAddressInputForm } from '@/generated/api/type/data-contracts';

interface Props {
  addressId: string;
  userAddress: WoUserAddressInputForm;
}

export const useMutationAddressModify = () => {
  const modifyAddress = async ({ addressId, userAddress }: Props) => {
    return await post(`/api/commerce/v1/self/user-addresses/${addressId}/update`, {
      user_address: userAddress,
    }).then(res => res.data);
  };

  return useMutation({ mutationFn: modifyAddress });
};
