import { RegisterTemplate } from '@components/template/register';

interface RegisterPageProps {}

/**
- 회원가입 페이지 (/register)
 */

export const RegisterPage = ({}: RegisterPageProps) => {
  return <RegisterTemplate />;
};
