import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { type QueryOptionsType } from '@/generated/api/type/api';
import { type WoSelfProductsBest10GetRes } from '@/generated/api/type/data-contracts';

interface Props {
  options?: QueryOptionsType<WoSelfProductsBest10GetRes>;
  handleError?: () => void;
}
export function useFetchUserRelatedProduct({ options }: Props) {
  const getUserRelatedProductList = async () => {
    return await get(`/api/commerce/v1/self/products/best10`).then(res => res.data);
  };

  return useQuery({
    queryKey: ['useFetchUserRelatedProduct'],
    queryFn: getUserRelatedProductList,
    ...options,
  });
}
