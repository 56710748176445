import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { AlertModal } from '@components/common/molecules/modal/alert-modal';
import { ExistAccount } from '@components/common/organism/auth/exist-account';
import { LoadingEntire } from '@components/common/organism/auth/loading-entire';
import { LoginForm } from '@components/domain/login/login-form';
import { RegisterTermsModal } from '@components/domain/register/register-terms-modal';

import kakaoIc from '@/assets/ic/kakao-simple.svg';
import { PATH } from '@/constants/path';
import useAuth from '@/hooks/use-auth';
import { useAuthCallback } from '@/hooks/use-auth-callback';
import useMixpanel from '@/hooks/use-mixpanel';
import { getSessionStorage } from '@/utils';

interface LoginTemplateProps {}
export const LoginTemplate = ({}: LoginTemplateProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    isLoading,
    keepLoginState,
    registerType,
    existingAccount,
    isTermsModalOpen,
    alertState,
    loginValue,
    connectCert,
    handleLoginWithEmail,
    handleChangeValue,
    handleCloseAlert,
    kakaoLogin,
    onSuccessKakaoLoginToApiRequest,
    handleCloseTermsModal,
    handlePASSCertification,
    handleKeepLoginState,
  } = useAuth();
  /**
   * 카카오, 패스 인증을 하고 콜백페이지에서 전달된 성공데이터를 가지고
   * 로직을 수행한다.
   */
  useAuthCallback({
    onSuccessKakaoLoginToApiRequest,
    connectCert,
  });

  const { handleMixpanelEvent } = useMixpanel();
  useEffect(() => {
    const isDirect =
      !!getSessionStorage('prd_opts') && location?.state?.from?.includes(PATH.PRODUCT_DETAIL);
    handleMixpanelEvent('view_login_store', { from: isDirect ? 'direct' : '' });
  }, []);

  const handleMoveToRegister = () => {
    const { before_url } = queryString.parse(location.search);
    const url = `${PATH.REGISTER}?before_url=${before_url ? (before_url as string) : ''}`;
    navigate(url);
  };

  if (registerType === 'exist' && existingAccount) {
    return <ExistAccount existingAccount={existingAccount} />;
  }

  return (
    <>
      <section className="desktop-jumbotron">
        <span className="text">로그인</span>
      </section>
      {/* <!-- 모바일, 데스크탑 공통 점보트론 --> */}
      <div className="jumbotron">
        <span className="sub-text">
          정보 입력없이 빠르게!
          <br />
          간편하게 친한스토어를 이용하세요!
        </span>
      </div>
      <div className="login-contents-wrap">
        <div className="action-group">
          <button className="login-action kakao" onClick={kakaoLogin}>
            <img src={kakaoIc} alt="" /> 카카오로 시작하기
          </button>
        </div>
        <div className="action-divider">
          <span className="label">또는</span>
        </div>
        <div className="login-forms-contents">
          {/* 이메일로 로그인 */}
          <LoginForm
            keepLoginState={keepLoginState}
            loginValue={loginValue}
            handleLoginWithEmail={handleLoginWithEmail}
            handleChangeValue={handleChangeValue}
            handleKeepLoginState={handleKeepLoginState}
          />
          <div className="link-group">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => navigate(PATH.RESET_PASSWORD)}
            >
              비밀번호 찾기
            </button>
            <span className="divider"></span>
            <button type="button" className="btn btn-link" onClick={handleMoveToRegister}>
              회원가입
            </button>
          </div>
        </div>
      </div>
      {isTermsModalOpen && (
        <RegisterTermsModal
          isOpen={isTermsModalOpen}
          closeModal={handleCloseTermsModal}
          handlePASSCertification={handlePASSCertification}
        />
      )}
      {isLoading && <LoadingEntire />}
      {alertState.isShow && (
        <AlertModal
          isOpen={alertState.isShow}
          body={alertState.message}
          buttons={[{ title: '확인', handleClick: () => {} }]}
          closeAlert={handleCloseAlert}
        />
      )}
    </>
  );
};
