import { useNavigate } from 'react-router-dom';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useFetchBanners } from '@/apis/commerce/banners/useFetchBanners';
import { PATH } from '@/constants/path';
import 'swiper/css/pagination';
import 'swiper/css';

export const MainTopRolling = () => {
  const navigate = useNavigate();
  const { data: bannerData, isFetched } = useFetchBanners({ bannerType: 'top_header' });
  if (!isFetched || bannerData === undefined) return <></>;
  const handleClickBanner = (showcaseIdx?: number, targetUrl?: string) => {
    showcaseIdx ? navigate(`${PATH.SHOWCASE}/${showcaseIdx}`) : navigate(targetUrl ?? '/');
    return;
  };
  return (
    <div className="home-promotion-banner">
      <Swiper
        slidesPerView={'auto'}
        className="swiper-container"
        loop={true}
        centeredSlides={true}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        // navigation={true}
        slideToClickedSlide={true}
        modules={[Pagination, Navigation, Autoplay]}
      >
        {bannerData?.banners?.map((banner, idx: number) => (
          <SwiperSlide
            key={`bannerImg_${idx}`}
            onClick={() => handleClickBanner(banner?.cs_showcase_idx, banner?.target_url)}
          >
            <img src={banner?.banner_image_url} alt="" />
            {(banner?.text_title_line1 || banner?.text_supporting) && (
              <>
                <div className="contents">
                  <div className="gradient"></div>
                </div>
                <div className="text-wrap">
                  <div className="title-col">
                    <div className="title">{banner?.text_title_line1}</div>
                    <div className="title">
                      {banner?.text_title_line2}
                      <span className="line"></span>
                    </div>
                  </div>
                  <div className="sub-title">{banner?.text_supporting}</div>
                </div>
              </>
            )}
          </SwiperSlide>
        ))}
        <div className="arrow-pagenation">
          <button className="btn btn-outline btn-rounded swiper-button-prev" />
          <button className="btn btn-outline btn-rounded right swiper-button-next" />
        </div>
      </Swiper>
    </div>
  );
};
