/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * CommerceApi
 * #### HTTP Status Code
* 200; 정상
* 400; 처리 실패, 오류 코드 및 오류 메세지 확인
* 401; 인증 오류, 제출한 토큰을 통한 인증 실패
* 403; 권한 오류, 로그인을 통해 적절한 토큰 제출 필요
* 500; 내부 오류, 관리자 도움 요청


 * OpenAPI spec version: 1.0
 */
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type { ErrorType } from '../../config/axios-instance';
import { axiosInstance } from '../../config/axios-instance';
import type {
  GetOrdersParams,
  WoOrderBeginExchangePostReq,
  WoOrderBeginExchangePostRes,
  WoOrderBeginPostReq,
  WoOrderBeginPostRes,
  WoOrderBeginRefundPostReq,
  WoOrderBeginRefundPostRes,
  WoOrderCompletePostReq,
  WoOrderCompletePostRes,
  WoOrderOptionsGetRes,
  WoOrderPaymentCancelPostReq,
  WoOrderPaymentCancelPostRes,
  WoOrderPaymentConfirmFailPostReq,
  WoOrderPaymentConfirmFailPostRes,
  WoOrderPaymentConfirmSuccessPostReq,
  WoOrderPaymentConfirmSuccessPostRes,
  WoOrdersGetRes,
  WoOrderStatusGetRes,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * #### Description
* order_request_reason_cd는 /specs/order 에서 확인

#### Required Privilege
* 로그인된 사용자

#### 변경 이력
* 기존 cancel_reason은 request_text로 대체

 */
export const cancelPayment = (
  orderIdx: number,
  woOrderPaymentCancelPostReq: WoOrderPaymentCancelPostReq,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<WoOrderPaymentCancelPostRes>(
    {
      url: `/api/commerce/v1/orders/${orderIdx}/payment/cancel`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      data: woOrderPaymentCancelPostReq,
    },
    options,
  );
};

export const getCancelPaymentMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelPayment>>,
    TError,
    { orderIdx: number; data: WoOrderPaymentCancelPostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof cancelPayment>>,
  TError,
  { orderIdx: number; data: WoOrderPaymentCancelPostReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelPayment>>,
    { orderIdx: number; data: WoOrderPaymentCancelPostReq }
  > = props => {
    const { orderIdx, data } = props ?? {};

    return cancelPayment(orderIdx, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelPaymentMutationResult = NonNullable<Awaited<ReturnType<typeof cancelPayment>>>;
export type CancelPaymentMutationBody = WoOrderPaymentCancelPostReq;
export type CancelPaymentMutationError = ErrorType<unknown>;

export const useCancelPayment = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelPayment>>,
    TError,
    { orderIdx: number; data: WoOrderPaymentCancelPostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof cancelPayment>>,
  TError,
  { orderIdx: number; data: WoOrderPaymentCancelPostReq },
  TContext
> => {
  const mutationOptions = getCancelPaymentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * #### Description
* 구매 확정

#### Required Privilege
* 로그인된 사용자

 */
export const completeOrder = (
  orderIdx: number,
  woOrderCompletePostReq: WoOrderCompletePostReq,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<WoOrderCompletePostRes>(
    {
      url: `/api/commerce/v1/orders/${orderIdx}/complete`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      data: woOrderCompletePostReq,
    },
    options,
  );
};

export const getCompleteOrderMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof completeOrder>>,
    TError,
    { orderIdx: number; data: WoOrderCompletePostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof completeOrder>>,
  TError,
  { orderIdx: number; data: WoOrderCompletePostReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof completeOrder>>,
    { orderIdx: number; data: WoOrderCompletePostReq }
  > = props => {
    const { orderIdx, data } = props ?? {};

    return completeOrder(orderIdx, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompleteOrderMutationResult = NonNullable<Awaited<ReturnType<typeof completeOrder>>>;
export type CompleteOrderMutationBody = WoOrderCompletePostReq;
export type CompleteOrderMutationError = ErrorType<unknown>;

export const useCompleteOrder = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof completeOrder>>,
    TError,
    { orderIdx: number; data: WoOrderCompletePostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof completeOrder>>,
  TError,
  { orderIdx: number; data: WoOrderCompletePostReq },
  TContext
> => {
  const mutationOptions = getCompleteOrderMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * #### Description
* 환불 요청 시작

#### Required Privilege
* 로그인된 사용자

 */
export const beginRefund = (
  orderIdx: number,
  woOrderBeginRefundPostReq: WoOrderBeginRefundPostReq,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<WoOrderBeginRefundPostRes>(
    {
      url: `/api/commerce/v1/orders/${orderIdx}/begin-refund`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      data: woOrderBeginRefundPostReq,
    },
    options,
  );
};

export const getBeginRefundMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof beginRefund>>,
    TError,
    { orderIdx: number; data: WoOrderBeginRefundPostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof beginRefund>>,
  TError,
  { orderIdx: number; data: WoOrderBeginRefundPostReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof beginRefund>>,
    { orderIdx: number; data: WoOrderBeginRefundPostReq }
  > = props => {
    const { orderIdx, data } = props ?? {};

    return beginRefund(orderIdx, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BeginRefundMutationResult = NonNullable<Awaited<ReturnType<typeof beginRefund>>>;
export type BeginRefundMutationBody = WoOrderBeginRefundPostReq;
export type BeginRefundMutationError = ErrorType<unknown>;

export const useBeginRefund = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof beginRefund>>,
    TError,
    { orderIdx: number; data: WoOrderBeginRefundPostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof beginRefund>>,
  TError,
  { orderIdx: number; data: WoOrderBeginRefundPostReq },
  TContext
> => {
  const mutationOptions = getBeginRefundMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * #### Description
* 교환 요청 시작

#### Required Privilege
* 로그인된 사용자

 */
export const beginExchange = (
  orderIdx: number,
  woOrderBeginExchangePostReq: WoOrderBeginExchangePostReq,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<WoOrderBeginExchangePostRes>(
    {
      url: `/api/commerce/v1/orders/${orderIdx}/begin-exchange`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      data: woOrderBeginExchangePostReq,
    },
    options,
  );
};

export const getBeginExchangeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof beginExchange>>,
    TError,
    { orderIdx: number; data: WoOrderBeginExchangePostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof beginExchange>>,
  TError,
  { orderIdx: number; data: WoOrderBeginExchangePostReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof beginExchange>>,
    { orderIdx: number; data: WoOrderBeginExchangePostReq }
  > = props => {
    const { orderIdx, data } = props ?? {};

    return beginExchange(orderIdx, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BeginExchangeMutationResult = NonNullable<Awaited<ReturnType<typeof beginExchange>>>;
export type BeginExchangeMutationBody = WoOrderBeginExchangePostReq;
export type BeginExchangeMutationError = ErrorType<unknown>;

export const useBeginExchange = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof beginExchange>>,
    TError,
    { orderIdx: number; data: WoOrderBeginExchangePostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof beginExchange>>,
  TError,
  { orderIdx: number; data: WoOrderBeginExchangePostReq },
  TContext
> => {
  const mutationOptions = getBeginExchangeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * #### Description
* toss 결제 요청 성공 기록 및 결제 승인 요청

#### Required Privilege
* 로그인된 사용자

 */
export const confirmPaymentSuccess = (
  woOrderPaymentConfirmSuccessPostReq: WoOrderPaymentConfirmSuccessPostReq,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<WoOrderPaymentConfirmSuccessPostRes>(
    {
      url: `/api/commerce/v1/orders/confirm-success`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      data: woOrderPaymentConfirmSuccessPostReq,
    },
    options,
  );
};

export const getConfirmPaymentSuccessMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmPaymentSuccess>>,
    TError,
    { data: WoOrderPaymentConfirmSuccessPostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof confirmPaymentSuccess>>,
  TError,
  { data: WoOrderPaymentConfirmSuccessPostReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof confirmPaymentSuccess>>,
    { data: WoOrderPaymentConfirmSuccessPostReq }
  > = props => {
    const { data } = props ?? {};

    return confirmPaymentSuccess(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfirmPaymentSuccessMutationResult = NonNullable<
  Awaited<ReturnType<typeof confirmPaymentSuccess>>
>;
export type ConfirmPaymentSuccessMutationBody = WoOrderPaymentConfirmSuccessPostReq;
export type ConfirmPaymentSuccessMutationError = ErrorType<unknown>;

export const useConfirmPaymentSuccess = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmPaymentSuccess>>,
    TError,
    { data: WoOrderPaymentConfirmSuccessPostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof confirmPaymentSuccess>>,
  TError,
  { data: WoOrderPaymentConfirmSuccessPostReq },
  TContext
> => {
  const mutationOptions = getConfirmPaymentSuccessMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * #### Description
* toss 결제 요청 실패 기록

#### Required Privilege
* 로그인된 사용자

 */
export const confirmPaymentFail = (
  woOrderPaymentConfirmFailPostReq: WoOrderPaymentConfirmFailPostReq,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<WoOrderPaymentConfirmFailPostRes>(
    {
      url: `/api/commerce/v1/orders/confirm-fail`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      data: woOrderPaymentConfirmFailPostReq,
    },
    options,
  );
};

export const getConfirmPaymentFailMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmPaymentFail>>,
    TError,
    { data: WoOrderPaymentConfirmFailPostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof confirmPaymentFail>>,
  TError,
  { data: WoOrderPaymentConfirmFailPostReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof confirmPaymentFail>>,
    { data: WoOrderPaymentConfirmFailPostReq }
  > = props => {
    const { data } = props ?? {};

    return confirmPaymentFail(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfirmPaymentFailMutationResult = NonNullable<
  Awaited<ReturnType<typeof confirmPaymentFail>>
>;
export type ConfirmPaymentFailMutationBody = WoOrderPaymentConfirmFailPostReq;
export type ConfirmPaymentFailMutationError = ErrorType<unknown>;

export const useConfirmPaymentFail = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmPaymentFail>>,
    TError,
    { data: WoOrderPaymentConfirmFailPostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof confirmPaymentFail>>,
  TError,
  { data: WoOrderPaymentConfirmFailPostReq },
  TContext
> => {
  const mutationOptions = getConfirmPaymentFailMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * #### Description
* 결제 진행 전 주문 등록

#### Required Privilege
* 로그인된 사용자

 */
export const beginOrder = (
  woOrderBeginPostReq: WoOrderBeginPostReq,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<WoOrderBeginPostRes>(
    {
      url: `/api/commerce/v1/orders/begin`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      data: woOrderBeginPostReq,
    },
    options,
  );
};

export const getBeginOrderMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof beginOrder>>,
    TError,
    { data: WoOrderBeginPostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof beginOrder>>,
  TError,
  { data: WoOrderBeginPostReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof beginOrder>>,
    { data: WoOrderBeginPostReq }
  > = props => {
    const { data } = props ?? {};

    return beginOrder(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BeginOrderMutationResult = NonNullable<Awaited<ReturnType<typeof beginOrder>>>;
export type BeginOrderMutationBody = WoOrderBeginPostReq;
export type BeginOrderMutationError = ErrorType<unknown>;

export const useBeginOrder = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof beginOrder>>,
    TError,
    { data: WoOrderBeginPostReq },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof beginOrder>>,
  TError,
  { data: WoOrderBeginPostReq },
  TContext
> => {
  const mutationOptions = getBeginOrderMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * #### Description
* 주문 목록 조회

#### Required Privilege
* 로그인된 사용자

 */
export const getOrders = (
  params: GetOrdersParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<WoOrdersGetRes>(
    { url: `/api/commerce/v1/orders`, method: 'GET', params, signal },
    options,
  );
};

export const getGetOrdersQueryKey = (params: GetOrdersParams) => {
  return [`/api/commerce/v1/orders`, ...(params ? [params] : [])] as const;
};

export const getGetOrdersQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrders>>,
  TError = ErrorType<unknown>,
>(
  params: GetOrdersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrders>>, TError, TData>>;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOrdersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrders>>> = ({ signal }) =>
    getOrders(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof getOrders>>>;
export type GetOrdersQueryError = ErrorType<unknown>;

export function useGetOrders<
  TData = Awaited<ReturnType<typeof getOrders>>,
  TError = ErrorType<unknown>,
>(
  params: GetOrdersParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrders>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getOrders>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOrders<
  TData = Awaited<ReturnType<typeof getOrders>>,
  TError = ErrorType<unknown>,
>(
  params: GetOrdersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrders>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getOrders>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOrders<
  TData = Awaited<ReturnType<typeof getOrders>>,
  TError = ErrorType<unknown>,
>(
  params: GetOrdersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrders>>, TError, TData>>;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetOrders<
  TData = Awaited<ReturnType<typeof getOrders>>,
  TError = ErrorType<unknown>,
>(
  params: GetOrdersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrders>>, TError, TData>>;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetOrdersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * #### Description
* 주문 옵션 상세 조회

#### Required Privilege
* 로그인된 사용자

 */
export const getOrderOptions = (
  orderIdx: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<WoOrderOptionsGetRes>(
    { url: `/api/commerce/v1/orders/${orderIdx}`, method: 'GET', signal },
    options,
  );
};

export const getGetOrderOptionsQueryKey = (orderIdx: number) => {
  return [`/api/commerce/v1/orders/${orderIdx}`] as const;
};

export const getGetOrderOptionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrderOptions>>,
  TError = ErrorType<unknown>,
>(
  orderIdx: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrderOptions>>, TError, TData>>;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOrderOptionsQueryKey(orderIdx);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrderOptions>>> = ({ signal }) =>
    getOrderOptions(orderIdx, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!orderIdx, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrderOptions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrderOptionsQueryResult = NonNullable<Awaited<ReturnType<typeof getOrderOptions>>>;
export type GetOrderOptionsQueryError = ErrorType<unknown>;

export function useGetOrderOptions<
  TData = Awaited<ReturnType<typeof getOrderOptions>>,
  TError = ErrorType<unknown>,
>(
  orderIdx: number,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrderOptions>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getOrderOptions>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOrderOptions<
  TData = Awaited<ReturnType<typeof getOrderOptions>>,
  TError = ErrorType<unknown>,
>(
  orderIdx: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrderOptions>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getOrderOptions>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOrderOptions<
  TData = Awaited<ReturnType<typeof getOrderOptions>>,
  TError = ErrorType<unknown>,
>(
  orderIdx: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrderOptions>>, TError, TData>>;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetOrderOptions<
  TData = Awaited<ReturnType<typeof getOrderOptions>>,
  TError = ErrorType<unknown>,
>(
  orderIdx: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrderOptions>>, TError, TData>>;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetOrderOptionsQueryOptions(orderIdx, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * #### Description
* 주문 별 배송, 결제 환불 정보 조회

#### Required Privilege
* 로그인된 사용자

 */
export const getOrderStatus1 = (
  orderIdx: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<WoOrderStatusGetRes>(
    { url: `/api/commerce/v1/orders/${orderIdx}/status`, method: 'GET', signal },
    options,
  );
};

export const getGetOrderStatus1QueryKey = (orderIdx: number) => {
  return [`/api/commerce/v1/orders/${orderIdx}/status`] as const;
};

export const getGetOrderStatus1QueryOptions = <
  TData = Awaited<ReturnType<typeof getOrderStatus1>>,
  TError = ErrorType<unknown>,
>(
  orderIdx: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrderStatus1>>, TError, TData>>;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOrderStatus1QueryKey(orderIdx);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrderStatus1>>> = ({ signal }) =>
    getOrderStatus1(orderIdx, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!orderIdx, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrderStatus1>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrderStatus1QueryResult = NonNullable<Awaited<ReturnType<typeof getOrderStatus1>>>;
export type GetOrderStatus1QueryError = ErrorType<unknown>;

export function useGetOrderStatus1<
  TData = Awaited<ReturnType<typeof getOrderStatus1>>,
  TError = ErrorType<unknown>,
>(
  orderIdx: number,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrderStatus1>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getOrderStatus1>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOrderStatus1<
  TData = Awaited<ReturnType<typeof getOrderStatus1>>,
  TError = ErrorType<unknown>,
>(
  orderIdx: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrderStatus1>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getOrderStatus1>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOrderStatus1<
  TData = Awaited<ReturnType<typeof getOrderStatus1>>,
  TError = ErrorType<unknown>,
>(
  orderIdx: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrderStatus1>>, TError, TData>>;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetOrderStatus1<
  TData = Awaited<ReturnType<typeof getOrderStatus1>>,
  TError = ErrorType<unknown>,
>(
  orderIdx: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrderStatus1>>, TError, TData>>;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetOrderStatus1QueryOptions(orderIdx, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
