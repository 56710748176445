import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

// 3. 비밀번호 초기화 - 새 비밀번호 설정
export function useMutationPasswordConfirm() {
  const request = async ({
    uuid,
    token,
    password,
  }: {
    uuid: string;
    token: string;
    password: string;
  }) => {
    const response = post('/v1/user/password/reset/confirm', { uuid, token, password });
    return response;
  };
  return useMutation({
    mutationFn: request,
  });
}
