interface BottomSheetModalProps {
  toggleModal: () => void;
  totalCount: number;
  totalPrice: string;
  discountPrice: string;
  totalDiscountedPrice: string;
  deliveryPrice: string;
  handleOrderClick: (cartOptionIdxes: number[]) => void;
  selected: number[];
}

export const BottomSheetModal = ({
  toggleModal,
  totalCount,
  totalPrice,
  discountPrice,
  totalDiscountedPrice,
  deliveryPrice,
  handleOrderClick,
  selected,
}: BottomSheetModalProps) => {
  const handleClickOrder = () => {
    handleOrderClick(selected);
    toggleModal();
  };
  return (
    <div>
      <div className="modal-background" onClick={toggleModal}>
        {/* <!-- 옵션을 선택한 바텀시트 --> */}
        <div className="modal-bottom for-payment" onClick={e => e.stopPropagation()}>
          <div className="handle" onClick={toggleModal} />
          <div className="modal-contents pricing-contents">
            <ul className="pricing-group-list">
              <li className="pricing-item">
                <div className="label">총 상품금액</div>
                <div className="price">
                  {totalPrice}
                  <span className="unit">원</span>
                </div>
              </li>
              <li className="pricing-item">
                <div className="label">총 할인금액</div>
                <div className="price">
                  {discountPrice}
                  <span className="unit">원</span>
                </div>
              </li>
              <li className="pricing-item">
                <div className="label">총 배송비</div>
                <div className="price">
                  {deliveryPrice}
                  <span className="unit">원</span>
                </div>
              </li>
            </ul>
          </div>
          <div className="mobile-bottom-nav">
            {/* <!-- 총 금액 --> */}
            <div className="total-price">
              <div className="label">결제 예정금액 : {totalCount}건</div>
              <div className="discount-percent">{totalDiscountedPrice}</div>
              <div className="unit">원</div>
            </div>
            <div className="btn-inline-group">
              <button
                type="button"
                className="btn btn-md btn-filled btn-block"
                onClick={handleClickOrder}
              >
                {totalDiscountedPrice}원 결제하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
