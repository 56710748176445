import { ChangeEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import { useFetchGetUserDetail } from '@/apis/commerce/self/useFetchGetUserDetail';
import { useMutationConnectCert } from '@/apis/friendly-pharmacist/auth/useMutationConnectCert';
import { useFetchGetUserInfo } from '@/apis/friendly-pharmacist/user/useFetchGetUserInfo';
import { useFetchPassUrl } from '@/apis/friendly-pharmacist/user/useFetchPassUrl';
import { useMutationLoginEmail } from '@/apis/friendly-pharmacist/user/useMutationLoginEmail';
import { useMutationPassValidation } from '@/apis/friendly-pharmacist/user/useMutationPassValidation';
import { useMutationPassValidationUpdate } from '@/apis/friendly-pharmacist/user/useMutationPassValidationUpdate';
import {
  ERROR_MESSAGE,
  RegisterEmailParams,
  useMutationRegisterEmail,
} from '@/apis/friendly-pharmacist/user/useMutationRegisterEmail';
import { useMutationRegisterSocial } from '@/apis/friendly-pharmacist/user/useMutationRegisterSocial';
import { getUserInfo, userLoginSocial } from '@/apis/friendly-pharmacist/user/user';
import {
  AUTH_KAKAO_ACCESS_TOKEN,
  AUTH_PASS_KEY,
  AUTH_REDIRECT_URL_KEY,
  AUTH_TOKEN_KEY,
} from '@/constants/auth';
import { PATH } from '@/constants/path';
import { ADS, ADS_TOTAL, TERMS_LIST } from '@/constants/terms';
import useMixpanel from '@/hooks/use-mixpanel';
import { useSearchUrl } from '@/hooks/use-search-url';
import {
  getSessionStorage,
  removeSessionStorage,
  setLocalStorage,
  setSessionStorage,
} from '@/utils';

import useLoginInfo from './use-login-info';

const Kakao = window.Kakao;

/**
 * kakao
    * login -> ci check -> redirect
    * login -> no ci -> PASS update -> redirect
    * login -> not registered -> terms -> register -> redirect

 * email
    * login -> ci checked -> redirect
    * login -> no ci -> PASS update -> redirect
    *
    *
  *
 */
export default function useAuth() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userToken } = useLoginInfo();

  const [keepLoginState, setKeepLoginState] = useState(true);
  const [loginValue, setLoginValue] = useState({ email: '', password: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const { handleMixpanelEvent } = useMixpanel();
  const [alertState, setAlertState] = useState<{
    isShow: boolean;
    message: string[];
    buttons: [{ title: string; handleClick: Function }];
  }>({ isShow: false, message: [''], buttons: [{ title: '', handleClick: () => {} }] });
  const recentViewedPrds = getSessionStorage('rct_p') || null;
  const [selectedItems, setSelectedItems] = useState({ sms: false, email: false, push: false });

  const [existingAccount, setExistingAccount] = useState<{
    email: string;
    id: string;
    sns_type: string;
  } | null>(null); // 새로운 상태 추가
  const { getStateBySearchParams, setStateBySearchParams, deleteBySearchParams } = useSearchUrl();
  const mdlTkn = getStateBySearchParams('mdl_tkn') || '';
  const agreedList = getStateBySearchParams('agreed')?.split(',') ?? [];
  const registerType = getStateBySearchParams('registerType') ?? '';
  const setRegisterType = (type: string) => {
    setStateBySearchParams([{ key: 'registerType', value: type }], location.state);
  };
  const deleteRegisterType = () => deleteBySearchParams('registerType');

  // 친한약사 api
  const { data: passUrlData } = useFetchPassUrl({
    redirectUrl: `${window.location.origin}${PATH.CALLBACK_PASS}`,
  });
  const { mutate: mutateConnectCert } = useMutationConnectCert();
  const { mutate: mutatePassValidation } = useMutationPassValidation();
  const { mutate: mutatePassValidationUpdate } = useMutationPassValidationUpdate();
  const { mutate: mutateLoginEmail } = useMutationLoginEmail();
  const { mutate: mutateRegisterSocial } = useMutationRegisterSocial({});
  const { mutate: mutateRegister } = useMutationRegisterEmail();
  const { data: userInfoData } = useFetchGetUserInfo({
    accessToken: userToken,
    options: { enabled: !!userToken },
  });

  // 스토어 api
  const { refetch: refetchUserDetail } = useFetchGetUserDetail({
    accessToken: userToken,
  });

  const initKakao = () => {
    if (!(Kakao?.isInitialized() as boolean)) {
      Kakao.init(`${process.env.REACT_APP_KAKAO_KEY as string}`);
    }
  };

  useEffect(() => {
    initKakao();
  }, []);

  /************************************
   * Login
   ************************************/
  const handleKeepLoginState = (e: ChangeEvent<HTMLInputElement>) => {
    setKeepLoginState(e.target.checked);
  };

  /**
   * 콜백페이지에서 인증완료후 전환될 페이지
   */
  const setAuthRedirectUrl = () => {
    sessionStorage.setItem(AUTH_REDIRECT_URL_KEY, `${location.pathname}${location.search || ''}`);
  };

  const kakaoLogin = () => {
    setAuthRedirectUrl();
    Kakao.Auth.authorize({
      redirectUri: `${window.location.origin}${PATH.CALLBACK_KAKAO}`,
      prompts: 'login',
    });
  };

  const onSuccessKakaoLoginToApiRequest = (accessToken?: string) => {
    //결과값에 따라 다시 카카오로그인 프로세스를 진행할 수 있게 카카오 억세스 토큰을 제거 해줍니다.
    sessionStorage.removeItem(AUTH_KAKAO_ACCESS_TOKEN);

    //카카오 계정 정보를 가져오기위해 카카오 억세스토큰을 카카오 sdk에 주입해줍니다.
    if (accessToken) {
      Kakao.Auth.setAccessToken(accessToken);
    }
    Kakao.API.request({
      url: '/v2/user/me',
      success: async (res: any) => {
        if (res.id !== undefined && res.id !== null) {
          await onSuccessKakaoLogin({ id: String(res.id) });
          setSessionStorage('kakao', { id: res.id, email: res.kakao_account.email });
        } else {
          removeSessionStorage('kakao');
        }
      },
      fail: (e: any) => {
        toast.error('카카오 연동이 실패하였습니다. 다시 시도해주세요.');
        removeSessionStorage('kakao');
      },
    });
  };

  const onSuccessKakaoLogin = async ({ id }: { id: string }) => {
    setIsLoading(true);

    try {
      const res = await userLoginSocial({
        sns_id: id,
      });

      await handleLoginSuccess(res?.data?.access_token);
    } catch (e: any) {
      setRegisterType('kakao');
      e?.response?.status === 401 && handleOpenTermsModal();
      setIsLoading(false);
    }
  };

  const handleLoginWithKakao = () => {
    kakaoLogin();
    handleMixpanelEvent('click_login_store', { type: 'kakao' });
  };

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setLoginValue({ ...loginValue, [name]: value });
  };

  const handleLoginWithEmail = (email: string, password: string) => {
    setIsLoading(true);
    handleMixpanelEvent('click_login_store', { type: 'email' });
    mutateLoginEmail(
      { email, password },
      {
        onSuccess: async ({ data }) => {
          if (data?.access_token) {
            await handleLoginSuccess(data.access_token);
          } else {
            setIsLoading(false);
            toast.error('로그인 정보를 다시 확인해주세요!');
          }
        },
        onError: (err: any) => {
          setIsLoading(false);
          toast.error(err?.response?.data?.message ?? '로그인 정보를 다시 확인해주세요!');
        },
      },
    );
  };

  const loginSuccessToMovePage = () => {
    const search = queryString.parse(location.search);
    navigate((search.before_url as string) || PATH.MYPAGE, { replace: true });
  };

  const handleLoginSuccess = async (access_token?: string) => {
    setIsLoading(true);
    if (access_token) {
      if (keepLoginState) {
        localStorage.setItem(AUTH_TOKEN_KEY, access_token);
      } else {
        sessionStorage.setItem(AUTH_TOKEN_KEY, access_token);
      }
    }

    keepLoginState && setLocalStorage('keep_tkn', true);

    try {
      /**
       * CI 판단을 위해 인증받은 토큰으로 가입유무를 판단합니다.
       * react-query 갱신 시간이 있으므로 별도 요청해 응답값을 판별합니다.
       */
      const { data } = await getUserInfo({ accessToken: access_token });
      if (data?.connect_accounts?.length) {
        loginSuccessToMovePage();
      } else {
        handleOpenTermsModal();
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleLogOut = async () => {
    const referrerIndex = getSessionStorage('REFERRER');
    const navigateUrl = recentViewedPrds?.[0]
      ? `${PATH.PRODUCT_DETAIL}/${recentViewedPrds[0]}${
          referrerIndex ? `?referrerIdx=${referrerIndex}` : ''
        }`
      : PATH.LOGIN;
    Kakao.Auth.logout();
    sessionStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem(AUTH_TOKEN_KEY);
    navigate(navigateUrl);
  };

  /*******************************************
   * useTerms
   ******************************************/

  const handleChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const removeEmptyStrings = (arr: string[]) => arr.filter(item => item.trim() !== '');
    const updateSearchParams = (newList: string[]) => {
      setStateBySearchParams(
        [
          {
            key: 'agreed',
            value: removeEmptyStrings(newList).join(','),
          },
        ],
        location.state,
      );
    };

    switch (value) {
      case String(ADS_TOTAL):
        agreedList.includes('3')
          ? setStateBySearchParams(
              [
                {
                  key: 'agreed',
                  value: agreedList.filter(id => !ADS.includes(Number(id))).join(','),
                },
              ],
              location.state,
            )
          : setStateBySearchParams(
              [{ key: 'agreed', value: [...agreedList, ...ADS].join(',') }],
              location.state,
            );
        break;

      case '4':
      case '5':
      case '6': {
        let newArray = agreedList.includes(value)
          ? agreedList.filter(id => id !== value)
          : [...agreedList, value];

        const hasIndividualAds = ADS.slice(1).some(adId => newArray.includes(String(adId)));
        const hasTotalAds = newArray.includes(String(ADS_TOTAL));

        if (hasIndividualAds && !hasTotalAds) {
          newArray.push(String(ADS_TOTAL));
        } else if (!hasIndividualAds && hasTotalAds) {
          newArray = newArray.filter(id => id !== String(ADS_TOTAL));
        }

        updateSearchParams(newArray);
        break;
      }

      default:
        updateSearchParams(
          agreedList.includes(value)
            ? agreedList.filter(id => id !== value)
            : [...agreedList, value],
        );
    }
  };

  const handleCheckAll = () => {
    agreedList.length > 5
      ? deleteBySearchParams('agreed')
      : setStateBySearchParams(
          [{ key: 'agreed', value: TERMS_LIST.map(item => item.id).join(',') }],
          location.state,
        );
  };

  const registerTypeInit = () => {
    deleteBySearchParams('registerType');
  };

  const handleOpenTermsModal = () => {
    setIsTermsModalOpen(true);
  };

  const handleCloseTermsModal = () => {
    if (isTermsModalOpen) {
      setIsTermsModalOpen(false);
    }
  };

  const handleCloseAlert = () => {
    setAlertState(pre => ({ ...pre, isShow: !pre.isShow }));
  };

  /**
   * 회원가입 시 패스인증
   * 리다이렉트 방식
   */
  const handlePASSCertification = () => {
    const passUrl = passUrlData?.data?.pass_auth_url;
    setAuthRedirectUrl();
    window.location.href = passUrl;
  };

  /**
   * 사용처에 필요한 패스인증 함수
   * - 회원가입 제외 (회원가입은 handlePASSCertification() 함수사용)
   * TODO: 현재 사용안함 필요없을 시 삭제
   * @param connectCertFn 후 처리 함수
   */
  const handlePassPopup = (connectCertFn: (tkn: string) => void) => {
    const passUrl = passUrlData?.data?.pass_auth_url;
    const popup = window.open(passUrl, 'popup', 'width=600,height=600');
    if (popup) {
      const interval = setInterval(() => {
        try {
          const href = popup.location.href;
          if (href.includes('mdl_tkn=')) {
            clearInterval(interval);
            const mdl_tkn = href.split('mdl_tkn=')[1]?.split('&')?.[0];
            setStateBySearchParams([{ key: 'mdl_tkn', value: mdl_tkn }]);
            connectCertFn(mdl_tkn);
          }
        } catch (e) {
          // ignore
        }
      }, 100);
    } else {
      alert('팝업이 차단되었습니다. 팝업 허용해주세요.');
    }
  };

  /**
   * 패스인증 토큰으로 계정 유효성 검사를 합니다.
   * 패스인증 성공 => mdl_tkn 전달 => 패스 인증 유무 API 호출 => CI 판단
   * @param mdl_tkn
   */
  const connectCert = (mdl_tkn: string) => {
    setIsLoading(true);
    sessionStorage.removeItem(AUTH_PASS_KEY);

    const selectedValue = TERMS_LIST.slice(3)
      .filter(item => agreedList.includes(`${item.id}`))
      .reduce((acc, curr) => {
        return { ...acc, [curr.name]: true };
      }, {});

    mutateConnectCert(
      { tkn: mdl_tkn },
      {
        onSuccess: () => {
          setSelectedItems(pre => ({ ...pre, ...selectedValue }));
          // 가입한 계정이 있고 CI 연동이 안되어있을 경우
          if (
            !!userToken &&
            userInfoData?.user_info?.id &&
            !userInfoData?.connect_accounts?.length
          ) {
            passValidationUpdate(mdl_tkn);
          } else {
            passValidation(mdl_tkn);
          }
        },
        onError: (err: any) => {
          setIsLoading(false);
        },
      },
    );
  };

  /**
   * 가입계정은 있지만 패스인증이 없는 계정일 경우
   * 패스인증 상태를 업데이트 합니다.
   * @param mdl_tkn
   */
  const passValidationUpdate = (mdl_tkn: string) => {
    mutatePassValidationUpdate(
      { tkn: mdl_tkn },
      {
        onSuccess: async () => {
          await handleLoginSuccess(userToken);
          handleCloseTermsModal();
        },
        onError: (err: any) => {
          setIsLoading(false);
        },
      },
    );
  };

  /**
   * 패스인증 유저 유효성 검사
   * @param tkn
   */
  const passValidation = (mdl_tkn: string) => {
    mutatePassValidation(mdl_tkn, {
      // 성공시 /register
      onSuccess: ({ data }) => {
        //계정값이 없을 경우 가입
        if (data?.is_valid && !data?.existing_accounts?.length) {
          if (registerType === 'kakao') {
            registerSocial(mdl_tkn);
          }
          handleCloseTermsModal();
          setIsLoading(false);
        }

        //이미 가입된 계정이 있을 경우
        if (data?.existing_accounts.length > 0) {
          setExistingAccount(data.existing_accounts[0]);
          setRegisterType('exist');
          handleCloseTermsModal();
          setIsLoading(false);
        }

        if (data.errorCode !== undefined) {
          alert(data.message);
          setIsLoading(false);
        }
      },
      onError: (err: any) => {
        setIsLoading(false);
      },
    });
  };

  const registerSocial = (mdl_tkn: string) => {
    const kakaoInfo = getSessionStorage('kakao');

    mutateRegisterSocial(
      {
        email: kakaoInfo?.email,
        snsId: String(kakaoInfo?.id),
        passToken: mdl_tkn,
        agreements: {
          sms: agreedList.includes('4'),
          email: agreedList.includes('5'),
          push: agreedList.includes('6'),
        },
      },
      {
        onSuccess: async () => {
          handleMixpanelEvent('complete_signup_store', { type: 'kakao' });
          await onSuccessKakaoLogin({ id: kakaoInfo?.id });
          setIsLoading(false);
        },
        onError: (err: any) => {
          setIsLoading(false);
        },
      },
    );
  };

  const handleRegisterWithEmail = (userValue: RegisterEmailParams) => {
    setIsLoading(true);

    mutateRegister(userValue, {
      onSuccess: async ({ data }) => {
        data?.access_token && sessionStorage.setItem(AUTH_TOKEN_KEY, data.access_token);
        handleMixpanelEvent('complete_signup_store', { type: 'email' });
        loginSuccessToMovePage();
        setIsLoading(false);
      },
      onError: (err: any) => {
        // TODO: 에러 메시지 처리
        const errorMsg =
          ERROR_MESSAGE[err?.response?.data?.errorCode as 'UNAUTHORIZED' | 'ALREADY_EXIST_EMAIL'] ||
          err?.response?.data?.message;
        setIsLoading(false);
        alert(errorMsg);
      },
    });
  };

  return {
    isLoading,
    mdlTkn,
    keepLoginState,
    agreedList,
    registerType,
    existingAccount,
    isTermsModalOpen,
    loginValue,
    selectedItems,
    alertState,
    handleRegisterWithEmail,
    registerTypeInit,
    handleKeepLoginState,
    deleteRegisterType,
    setRegisterType,
    handleCloseAlert,
    setSelectedItems,
    setExistingAccount,
    handleLogOut,
    refetchUserDetail,
    handleLoginWithKakao,
    handleLoginWithEmail,
    handleChangeValue,
    kakaoLogin,
    initKakao,
    onSuccessKakaoLoginToApiRequest,
    handleChangeCheckbox,
    handleCheckAll,
    handlePASSCertification,
    connectCert,
    handleOpenTermsModal,
    handleCloseTermsModal,
    handlePassPopup,
  };
}
