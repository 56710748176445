import { useNavigate } from 'react-router-dom';
import { Jumbotron } from '@components/common/molecules/jumbotron';
import { AlertModal } from '@components/common/molecules/modal/alert-modal';
import { Step1 } from '@components/domain/mypage/withdrawal/step-1';
import { Step2 } from '@components/domain/mypage/withdrawal/step-2';

import { PATH } from '@/constants/path';
import useWithdrawal from '@/hooks/use-withdrawal';

interface WithdrawalTemplateProps {}
export const WithdrawalTemplate = ({}: WithdrawalTemplateProps) => {
  const navigate = useNavigate();

  const {
    showAlert,
    currentStep,
    selectedReason,
    textareaValue,
    hasReadALL,
    handleChangeReason,
    handleChangeTextarea,
    navigateToStep2,
    hasReadAllToggle,
    handleWithdrawalBtnClick,
  } = useWithdrawal();

  return (
    <>
      <main className="my-main">
        <Jumbotron title="회원 탈퇴" />
        {currentStep === 1 ? (
          <Step1
            selectedReason={selectedReason}
            textareaValue={textareaValue}
            handleChangeReason={handleChangeReason}
            handleChangeTextarea={handleChangeTextarea}
            handleBtnClick={navigateToStep2}
          />
        ) : (
          <Step2
            hasReadALL={hasReadALL}
            hasReadAllToggle={hasReadAllToggle}
            handleBtnClick={handleWithdrawalBtnClick}
          />
        )}
        {showAlert && (
          <AlertModal
            isOpen={showAlert}
            body={['회원 탈퇴가', '정상적으로 처리 되었어요.']}
            buttons={[{ title: '확인', handleClick: () => navigate(PATH.LOGIN) }]}
          />
        )}
      </main>
      <div className="mobile-bottom-nav">
        <div className="btn-inline-group">
          {currentStep === 1 ? (
            <button
              type="button"
              className="btn btn-md btn-filled btn-block"
              onClick={navigateToStep2}
              disabled={
                (selectedReason === '기타' && textareaValue.length < 10) ||
                selectedReason.length === 0
              }
            >
              다음으로
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-md btn-filled btn-block"
              disabled={!hasReadALL}
              onClick={handleWithdrawalBtnClick}
            >
              회원 탈퇴하기
            </button>
          )}
        </div>
      </div>
    </>
  );
};
