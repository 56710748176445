import { AlertModal } from '@components/common/molecules/modal/alert-modal';
import { Toast } from '@components/common/molecules/toast';

import { useInfinitelyFetchMyReviews } from '@/apis/commerce/self/useInfinitelyFetchMyReviews';
import moreBtn from '@/assets/ic/chevron-down-bk.svg';
import { ReviewModal } from '@/components/domain/review/review-modal';
import useReviewList from '@/hooks/use-review-list';
import { numberFormat } from '@/utils/formats';

import { ReviewItem } from './review-item';

export const ReviewListMobile = () => {
  const {
    initialValue,
    offset,
    activeReview,
    reviewCreateModal,
    toastState,
    alertState,
    closeModal,
    toggleToast,
    handleClickCreateReview,
    handleClickDeleteBtn,
    handleClickShowReview,
  } = useReviewList();

  const {
    data: infiniteData,
    fetchNextPage,
    refetch: refetchReviewData,
  } = useInfinitelyFetchMyReviews({
    offset: 0,
    limit: initialValue.limit,
  });
  const total = infiniteData?.pages?.[0]?.total ?? 0;

  return (
    <>
      {(total ?? 0) > 0 && offset > 3 && (
        <div className="product-list-filter filter-sm">
          <div className="result-value">리뷰 {numberFormat(total)}</div>
          <div className="mobile-list-sorting">
            <button type="button" className="btn btn-subtle btn-sorting active">
              전체
            </button>
          </div>
          {/* <!-- desktop xlg sorting --> */}
          <ul className="desktop-list-sorting">
            <li>
              {/* <!-- 선택된 값 active 클래스로 조정 --> */}
              <button type="button" className="btn btn-subtle btn-sorting active">
                전체
              </button>
            </li>
            <li>
              <button type="button" className="btn btn-subtle btn-sorting">
                작성
              </button>
            </li>
            <li>
              <button type="button" className="btn btn-subtle btn-sorting">
                미작성
              </button>
            </li>
          </ul>
        </div>
      )}
      {!total && !infiniteData?.pages?.length ? (
        <div className="empty-list">
          <div className="lines">
            <span>"첫 리뷰를 남겨주세요"</span>
            <span className="line"></span>
          </div>
          <div className="sub-text">
            아직 작성된 리뷰가 없습니다.
            <br />
            다른 고객님의 선택에 도움을 주세요.
          </div>
        </div>
      ) : (
        <ul className="myReview-list">
          {infiniteData?.pages?.map(page =>
            page.data?.reviews?.map(review => (
              <ReviewItem
                key={`review_${review?.review_idx}`}
                activeClassName={review?.review_idx === activeReview ? 'active' : 'close'}
                hasReviewed={!!review?.review_text_auto}
                review={review}
                activeReview={activeReview}
                handleClickCreateReview={handleClickCreateReview}
                handleClickDeleteBtn={handleClickDeleteBtn}
                toggleReview={handleClickShowReview}
                refetchReviewData={refetchReviewData}
              />
            )),
          )}
        </ul>
      )}

      {infiniteData?.pages?.[(infiniteData?.pages?.length ?? 0) - 1].isLast ? (
        <></>
      ) : (
        <div id="load-pagenation">
          <div className="pagenation-group" id="pagenation">
            <button
              type="button"
              className="btn btn-md btn-block btn-outline pagenation-mobile"
              onClick={() => fetchNextPage()}
            >
              <img src={moreBtn} alt="more" />
              상품 더보기
            </button>
          </div>
        </div>
      )}
      {alertState.isShow && (
        <AlertModal
          isOpen={alertState.isShow}
          header={alertState.header}
          body={alertState.body}
          buttons={alertState.buttons}
        />
      )}
      {reviewCreateModal !== 0 && (
        <ReviewModal isOpen={true} toggleModal={closeModal} prdId={String(reviewCreateModal)} />
      )}
      {toastState.isShow && <Toast message={toastState.message} handleClickClose={toggleToast} />}
    </>
  );
};
