import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

export function useMutationCartDelete() {
  const updateCartItemDelete = async ({ optionIdx }: { optionIdx: number[] }) => {
    await post('/api/commerce/v1/cart/items/delete', {
      user_cart_option_idxes: optionIdx,
    });
  };
  return useMutation({ mutationFn: updateCartItemDelete });
}
