/**
 * 숫자를 3자리 단위로 콤마(,)를 찍어주는 함수
 * @param number 1234567
 * @returns {string} 1,234,567
 */
export const numberFormat = (number?: number) => {
  return number !== undefined ? new Intl.NumberFormat('ko-KR').format(number) : '';
};

/**
 * 폰번호 포맷 함수
 * @param {string} phone 01012345678
 * @returns {string} 010-1234-5678
 */
export const phoneFormat = (phone: string) => {
  return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
};

/**
 * 숫자가 한 자리일때 앞에 0을 붙여주는 함수
 * @param {number} num 3
 * @returns {string} '03'
 */
export const numPadFormat = (num: number) => `${num}`.padStart(2, '0');

/**
 * 날짜 포맷 함수
 * @param {Date} date : Date
 * @param {string} type : 'dot' | 'dash'
 * @returns 'YY.MM.DD' | 'YYYY-MM-DD'
 */
export const dateToStringFormat = (date: Date, type: 'dot' | 'dash') => {
  const year = date.getFullYear().toString().slice(-2); // 연도 두 자리
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월 두 자리 (01-12)
  const day = date.getDate().toString().padStart(2, '0'); // 일 두 자리 (01-31)
  return type === 'dot' ? `${year}.${month}.${day}` : `${date.getFullYear()}-${month}-${day}`;
};

/**
 * 생년월일 포맷 함수
 * @param {string} date : string (YYYYMMDD)
 * @returns {string} 'YYYY.MM.DD'
 */
export const stringDateToStringFormat = (date: string) =>
  date.replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3');
