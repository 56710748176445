import { MouseEvent, useEffect, useState } from 'react';

import { useFetchInquiryThread } from '@/apis/commerce/boards/useFetchInquiryThread';
import { useMutationInquiryDelete } from '@/apis/commerce/boards/useMutationInquiryDelete';
import { useFetchBoardSpecs } from '@/apis/commerce/specs/useFetchBoardSpecs';
import { WoBoardThreadArticle } from '@/generated/api/type/data-contracts';
import { useSearchUrl } from '@/hooks/use-search-url';

export default function useInquiryList() {
  const { getStateBySearchParams, setStateBySearchParams } = useSearchUrl();
  const currentTab = getStateBySearchParams('currentTab') ?? 'one_on_one';
  const currentPage = Number(getStateBySearchParams('currentPage')) ?? 1;
  const offset = Number(getStateBySearchParams('offset')) ?? 0;
  const [activeQuestion, setActiveQuestion] = useState<number | 0>(0);
  const [modalState, setModalState] = useState({
    text: '',
    isShow: false,
    qnaBoardTypeCd: '',
    threadData: {},
  });
  const initialValue = {
    limit: 10,
    offset: 0,
    currentPage: 1,
    currentTab: 'one_on_one',
  };
  useEffect(() => {
    setStateBySearchParams([
      { key: 'currentTab', value: initialValue.currentTab },
      { key: 'offset', value: String(initialValue.offset) },
      { key: 'currentPage', value: String(initialValue.currentPage) },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { data: specData } = useFetchBoardSpecs({});
  const { data: threadData } = useFetchInquiryThread({
    qnaBoardTypeCd: currentTab,
    qnaIdx: activeQuestion,
    options: { enabled: !!activeQuestion },
  });
  const { mutate: mutateDelete } = useMutationInquiryDelete();

  const handleClickTab = (e: MouseEvent<HTMLAnchorElement>, tab: string) => {
    e.preventDefault();

    setStateBySearchParams([
      { key: 'currentTab', value: tab },
      { key: 'offset', value: String(initialValue.offset) },
      { key: 'currentPage', value: String(initialValue.currentPage) },
    ]);
    setActiveQuestion(0);
  };

  const handleClickQuestion = (idx: number) =>
    idx === activeQuestion ? setActiveQuestion(0) : setActiveQuestion(idx);

  const handleClickDelete = ({ qnaIdx, onSuccess }: { qnaIdx: number; onSuccess: () => void }) => {
    mutateDelete(
      {
        qnaBoardTypeCd: currentTab,
        qnaIdx,
      },
      {
        onSuccess,
      },
    );
  };
  const setValue = (key: string, value: number) => {
    setStateBySearchParams([{ key, value: String(value) }]);
  };

  const handleClickModify = (threadData: WoBoardThreadArticle) => {
    setModalState({
      text: threadData?.qna_text ?? '',
      qnaBoardTypeCd: threadData?.ord_order_option_name ?? '',
      threadData,
      isShow: true,
    });
  };

  const toggleModifyModal = () => {
    setModalState({
      ...modalState,
      isShow: false,
    });
  };
  return {
    currentPage,
    offset,
    activeQuestion,
    currentTab,
    modalState,
    specData,
    threadData,
    setValue,
    handleClickTab,
    handleClickQuestion,
    handleClickDelete,
    handleClickModify,
    toggleModifyModal,
  };
}
