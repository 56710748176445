import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

/**
 * 주문서 배송지 변경
 */
export function useMutationPreorderAddressUpdate() {
  const updateAddress = async ({
    preorderIdx,
    addressId,
  }: {
    preorderIdx: string;
    addressId: string;
  }) => {
    return await post(`/api/commerce/v1/preorders/${preorderIdx}/user-address/update`, {
      address_id: addressId,
    }).then(res => res.data);
  };

  return useMutation({ mutationFn: updateAddress });
}
