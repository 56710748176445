import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { type QueryOptionsType } from '@/generated/api/type/api';
import { WoMenusGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  options?: QueryOptionsType<WoMenusGetRes>;
}
export function useFetchMenu({ options }: Props) {
  const getCategories = async () => {
    return await get('/api/commerce/v1/menus').then(res => res.data);
  };

  return useQuery({ queryKey: ['useFetchMenu'], queryFn: getCategories, ...options });
}
