import { useInfiniteQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { WoOrdersGetReq, WoOrdersGetRes } from '@/generated/api/type/data-contracts';

interface InfiniteQueryProps extends Props {
  staleTime?: number;
}

interface Props {
  created_at_begin: string;
  created_at_end: string;
  limit: number;
  offset: number;
}

const fetchProjects = async (pageParam: WoOrdersGetReq) => {
  const res = await get(
    '/api/commerce/v1/orders' +
      `?created_at_begin=${pageParam.created_at_begin}&created_at_end=${pageParam.created_at_end}&limit=${pageParam.limit}&offset=${pageParam.offset}`,
  );
  const data = res.data as WoOrdersGetRes;
  return {
    data,
    nextPageOffset: (pageParam?.offset ?? 0) + (pageParam?.limit ?? 10),
    isLast: (pageParam?.offset ?? 0) + (pageParam?.limit ?? 10) >= (data?.total_count as number),
    total: data.total_count,
  };
};
export const useInfinitelyFetchOrderList = ({
  created_at_begin,
  created_at_end,
  limit,
  offset,
  staleTime,
}: InfiniteQueryProps) => {
  return useInfiniteQuery({
    queryKey: ['useInfinitelyFetchOrderList', created_at_begin, created_at_end, limit, offset],
    queryFn: ({ pageParam }) => fetchProjects(pageParam),
    initialPageParam: { created_at_begin, created_at_end, limit, offset },
    getNextPageParam: lastPage => {
      if (lastPage.isLast) return undefined;
      return {
        created_at_begin,
        created_at_end,
        limit,
        offset: lastPage.nextPageOffset,
      };
    },
    staleTime,
  });
};
