import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';
import { MutationOptionsType } from '@/generated/api/type/api';
import {
  type WoSelfAddressCreatePostReq,
  type WoSelfAddressCreatePostRes,
  type WoUserAddressInputForm,
} from '@/generated/api/type/data-contracts';

interface Props {
  addressInfo: WoUserAddressInputForm;
  options?: MutationOptionsType<WoSelfAddressCreatePostRes, WoSelfAddressCreatePostReq>;
}

export function useMutatationCreateAddress({ addressInfo, options }: Props) {
  const createAddress = async () => {
    return await post('/api/commerce/v1/self/user-addresses/create', {
      user_address: addressInfo,
    }).then(res => res.data);
  };

  return useMutation({ mutationFn: createAddress, ...options });
}
