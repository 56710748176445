import emailIc from '@/assets/ic/email.svg';

interface UserInfoItemProps {
  name: string;
  value: string;
}
export const UserInfoItem = ({ name, value }: UserInfoItemProps) => {
  return (
    <li className="myInfo-forms-item">
      <div className="label">{name}</div>
      <div className={`input-group${name === '이메일' ? '' : ' inline nickname'}`}>
        {name === '이메일' && <img src={emailIc} alt="email" />}
        <div className="text no-link">{value}</div>
      </div>
    </li>
  );
};
