import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { QueryOptionsType } from '@/generated/api/type/api';
import { WoCartPaymentAmountsGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  optionIdxes: number[];
  options?: QueryOptionsType<WoCartPaymentAmountsGetRes>;
}

export default function useFetchCartAmount({ optionIdxes, options }: Props) {
  let optionIdxesStr = '';
  if (optionIdxes !== undefined) {
    for (let i = 0; i < optionIdxes.length; i++) {
      if (optionIdxes[i] !== undefined) {
        optionIdxesStr =
          optionIdxesStr + `${i !== 0 ? '&' : ''}user_cart_option_idxes=${optionIdxes[i]}`;
      }
    }
  }
  const getCartAmount = async () => {
    const response = await get(`/api/commerce/v1/cart/payment-amounts?${optionIdxesStr}`);
    return response.data;
  };
  return useQuery({
    ...options,
    queryKey: ['cartAmount', optionIdxes],
    queryFn: getCartAmount,
  });
}
