import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { QueryOptionsType } from '@/generated/api/type/api';
import { WoPreorderGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  preorder_idx: string;
  options?: QueryOptionsType<WoPreorderGetRes>;
}

export function useFetchOrderForm({ preorder_idx }: Props) {
  const getOrderForm = async () => {
    return await get(`/api/commerce/v1/preorders/${preorder_idx}`).then(
      ({ data }: { data: WoPreorderGetRes }) => data,
    );
  };

  return useQuery({
    queryKey: ['useFetchOrderForm', preorder_idx],
    queryFn: getOrderForm,
  });
}
