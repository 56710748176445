import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PATH } from '@/constants/path';
import { useSearchUrl } from '@/hooks/use-search-url';
import { dateToStringFormat } from '@/utils/formats';

export default function useOrderList() {
  const newDate = new Date(); // 변경될 date 값
  const today = new Date();
  const filterInitialData = {
    // getMonth시 현재 달 -1 (index 값으로 들어옴)
    beginDate: dateToStringFormat(new Date(newDate.setMonth(newDate.getMonth() - 5)), 'dash'), // index 값이라 -1 생략
    endDate: dateToStringFormat(today, 'dash'),
    currentPage: 1,
    limit: 10,
    offset: 0,
  };
  const { pathname } = useLocation();
  const [clickedPeriod, setClickedPeriod] = useState('1개월');
  const { getStateBySearchParams, setStateBySearchParams } = useSearchUrl();
  const offset = getStateBySearchParams('offset') ?? 0;
  const limit = 10;
  const currentPage = getStateBySearchParams('currentPage') ?? 1;
  const beginDate = getStateBySearchParams('beginDate') ?? filterInitialData.beginDate;
  const endDate = getStateBySearchParams('endDate') ?? filterInitialData.endDate;
  const filterInitialise = () => {
    setStateBySearchParams([
      {
        key: 'beginDate',
        value: dateToStringFormat(new Date(newDate.setMonth(newDate.getMonth() - 1)), 'dash'),
      },
      { key: 'endDate', value: dateToStringFormat(today, 'dash') },
    ]);
    toTheFirstPage();
  };

  const toTheFirstPage = () => {
    setStateBySearchParams([
      { key: 'limit', value: String(limit) },
      { key: 'offset', value: String(offset) },
      { key: 'currentPage', value: String(currentPage) },
    ]);
  };

  useEffect(() => {
    pathname !== PATH.MYPAGE && filterInitialise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setValue = (key: string, value: number) => {
    setStateBySearchParams([{ key, value: String(value) }]);
  };

  const handleClickPeriodBtn = (period: string) => {
    setClickedPeriod(period);
    const today = new Date();
    const beginDate = new Date(today); // 새 Date 객체 생성
    const monthsToSubtract = 0;

    switch (period) {
      case '1개월':
        beginDate.setMonth(today.getMonth() - 1);
        break;
      case '6개월':
        beginDate.setMonth(today.getMonth() - 6);
        break;
      case '1년':
        beginDate.setFullYear(today.getFullYear() - 1);
        break;
      case '기간설정':
        return;
      default:
        console.warn('Unknown period:', period);
        return;
    }
    const originalDay = beginDate.getDate();
    beginDate.setMonth(beginDate.getMonth() - monthsToSubtract);

    // 월 조정 후 날짜가 바뀌었다면 (즉, 원래 날짜가 해당 월에 존재하지 않는다면)
    // 해당 월의 마지막 날로 설정
    if (beginDate.getDate() !== originalDay) {
      beginDate.setDate(0); // 이전 달의 마지막 날로 설정
    }

    setStateBySearchParams([
      {
        key: 'beginDate',
        value: dateToStringFormat(beginDate, 'dash'),
      },
      {
        key: 'endDate',
        value: dateToStringFormat(today, 'dash'),
      },
    ]);
  };

  function checkDateDifference(beginDate: string, endDate: string) {
    // 시작일과 종료일을 Date 객체로 변환
    const start = new Date(beginDate);
    const end = new Date(endDate);

    // 년도와 월 차이 계산
    const yearDiff = end.getFullYear() - start.getFullYear();
    const monthDiff = end.getMonth() - start.getMonth();

    // 총 월 차이 계산
    const totalMonthDiff = yearDiff * 12 + monthDiff;

    // 일(day) 차이가 정확한지 확인
    const dayDiff = end.getDate() - start.getDate();
    if (dayDiff === 0) {
      // 정확히 1개월
      if (totalMonthDiff === 1) return '1개월';
      // 정확히 6개월
      if (totalMonthDiff === 6) return '6개월';
      // 정확히 1년
      if (totalMonthDiff === 12) return '1년';
    }

    // 정확한 기간이 아닌 경우
    return '기간설정';
  }
  const periodGap = checkDateDifference(beginDate, endDate);

  return {
    clickedPeriod,
    beginDate,
    endDate,
    currentPage,
    limit,
    offset,
    periodGap,
    checkDateDifference,
    setValue,
    toTheFirstPage,
    handleClickPeriodBtn,
  };
}
