import React, { ChangeEvent, useEffect, useState } from 'react';

import useAuth from '@/hooks/use-auth';
import { validation } from '@/utils/validations';

interface RegisterFormProps {
  onToggleTermsModal: (isTermsModalOpen: boolean) => void;
  selectedItems: { sms: boolean; email: boolean; push: boolean };
}

export const RegisterForm = ({ onToggleTermsModal, selectedItems }: RegisterFormProps) => {
  const [userValue, setUserValue] = useState({ email: '', password: '', passwordConfirm: '' });
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isConfirmPasswordSame, setIsConfirmPasswordSame] = useState(true);
  const { handleRegisterWithEmail, mdlTkn } = useAuth();
  useEffect(() => {
    onToggleTermsModal(!mdlTkn);
  }, [mdlTkn]);

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setUserValue({ ...userValue, [name]: value });
  };

  const isValidData = isEmailValid && isPasswordValid && isConfirmPasswordSame;

  return (
    <>
      <section className="desktop-jumbotron desktop-divider">
        <span className="text">회원가입</span>
        <span className="sub-text">친한스토어 가입하고 다양한 혜택을 받으세요!</span>
      </section>
      <div className="login-contents-wrap">
        {/* <!-- 이메일 입력 기본 --> */}
        <div className="input-group">
          <div className="form-label">
            이메일<sup>*</sup>
          </div>
          <input
            type="email"
            name="email"
            className={`form-control${isEmailValid ? '' : ' has-validation'}`}
            placeholder="이메일 입력"
            onChange={handleChangeValue}
            onBlur={() => setIsEmailValid(validation('email', userValue.email))}
          />
          {/* {!isEmailValid && <div className="form-text">이미 사용 중이에요.</div>} */}
          {!isEmailValid && <div className="form-text">이메일을 확인해주세요.</div>}
        </div>
        {/* <!-- 이메일 입력 실패 --> */}
        {/* <div style="display: block;" className="input-group">
        <div className="form-label">이메일</div>
        <input type="text" className="form-control has-validation" placeholder="Standard" value="a@phama-bros.com"/>
        <div className="form-text">이미 사용 중이에요.</div>
        </div>*/}
        {/* <!-- 비밀번호 입력 기본 --> */}
        <div className="input-group">
          <div className="form-label">
            비밀번호<sup>*</sup>
          </div>
          <input
            type="password"
            name="password"
            className={`form-control${isPasswordValid ? '' : ' has-validation'}`}
            placeholder="비밀번호 입력"
            onChange={handleChangeValue}
            onBlur={() =>
              setIsPasswordValid(
                validation('eng', userValue.password) &&
                  validation('num', userValue.password) &&
                  userValue.password.length >= 8,
              )
            }
          />
          {!isPasswordValid && <div className="form-text">비밀번호를 확인하세요.</div>}
          <div className="form-text">
            <span
              className={`password-check ${
                validation('eng', userValue.password) ? 'is-valid' : ''
              }`}
            >
              영문
            </span>
            <span
              className={`password-check ${
                validation('num', userValue.password) ? 'is-valid' : ''
              }`}
            >
              숫자
            </span>
            <span className={`password-check ${userValue.password.length >= 8 ? 'is-valid' : ''}`}>
              8-20자
            </span>
          </div>
        </div>
        <div className="input-group">
          <div className="form-label">
            비밀번호 확인<sup>*</sup>
          </div>
          <input
            type="password"
            name="passwordConfirm"
            className={`form-control${isConfirmPasswordSame ? '' : ' has-validation'}`}
            placeholder="비밀번호 재입력"
            onChange={handleChangeValue}
            onBlur={() =>
              setIsConfirmPasswordSame(userValue.password === userValue.passwordConfirm)
            }
          />
          {!isConfirmPasswordSame && <div className="form-text">비밀번호를 확인하세요.</div>}
        </div>
        <div className="action-group inline">
          <button type="button" className="btn btn-md btn-outline btn-block">
            취소하기
          </button>
          <button
            type="button"
            className="btn btn-md btn-filled btn-block"
            onClick={() =>
              handleRegisterWithEmail({
                email: userValue.email,
                password: userValue.password,
                passToken: mdlTkn,
                agreements: selectedItems,
              })
            }
            disabled={!isValidData}
          >
            가입하기
          </button>
        </div>
      </div>
    </>
  );
};
