import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import qs from 'qs';

const paramsSerializer = (params: any) => qs.stringify(params);
export const AXIOS_INSTANCE_EXTERNAL = Axios.create({
  baseURL: '',
  paramsSerializer,
});

interface Options {
  accessToken?: string;
  headers?: Record<string, any>;
}

export const axiosInstanceToExternal = async <T>(
  config: AxiosRequestConfig,
  options?: Options,
): Promise<T> => {
  config.headers = {
    ...config.headers,
    ...options?.headers,
    ...(options?.accessToken && { Authorization: `Bearer ${options?.accessToken}` }),
  };

  const { data } = await AXIOS_INSTANCE_EXTERNAL(config);
  return data;
};

export default AXIOS_INSTANCE_EXTERNAL;

export type ErrorType<Error> = AxiosError<Error>;
export type AwaitedInput<T> = PromiseLike<T> | T;
export type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;
export type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];
