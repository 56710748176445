import { MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';

interface PaginationProps {
  total: number;
  limit: number;
  setValue: (key: string, value: number) => void;
  currentPage: number;
}

export const Pagination = ({ total, limit, setValue, currentPage }: PaginationProps) => {
  const [pageGroup, setPageGroup] = useState(0);
  const totalPages = Math.ceil(total / limit);

  const pages = Array(totalPages)
    .fill(false)
    .map((_, idx) => idx + 1);

  const handlePageClick = (page: number) => {
    setValue('currentPage', page);
    setValue('offset', (page - 1) * limit);
  };

  const handleNextGroup = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if ((pageGroup + 1) * 10 < totalPages) {
      setPageGroup(pageGroup + 1);
      handlePageClick((pageGroup + 1) * 10 + 1);
    } else {
      handlePageClick(totalPages);
    }
  };

  const handlePrevGroup = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (pageGroup > 0) {
      setPageGroup(pageGroup - 1);
      handlePageClick(pageGroup * 10);
    } else {
      handlePageClick(1);
    }
  };

  return (
    <div id="load-pagenation">
      <div className="pagenation-group" id="pagenation">
        <ul className="pagenation">
          <li>
            <Link to="#" className="btn btn-outline btn-rounded" onClick={handlePrevGroup} />
          </li>
          {pages.slice(pageGroup * 10, (pageGroup + 1) * 10).map(page => (
            <li
              key={`order_list_${page}`}
              onClick={() => {
                handlePageClick(page);
              }}
            >
              <Link to="#" className={`page${currentPage === page ? ' active' : ''}`}>
                {page}
              </Link>
            </li>
          ))}
          <li>
            <Link to="#" className="btn btn-outline btn-rounded right" onClick={handleNextGroup} />
          </li>
        </ul>
      </div>
    </div>
  );
};
