import { useState } from 'react';

import { useMutationDeleteReview } from '@/apis/commerce/reviews/useMutationDeleteReview';
import useMixpanel from '@/hooks/use-mixpanel';
import { useSearchUrl } from '@/hooks/use-search-url';

export default function useReviewList() {
  const { getStateBySearchParams, setStateBySearchParams } = useSearchUrl();
  const { handleMixpanelEvent } = useMixpanel();
  const currentPage = getStateBySearchParams('currentPage') ?? 1;
  const offset = Number(getStateBySearchParams('offset') ?? 0);
  const [reviewCreateModal, setReviewCreateModal] = useState(0);
  const [activeReview, setActiveReview] = useState<number | 0>(0);
  const [alertState, setAlertState] = useState({
    header: [''],
    body: [''],
    buttons: [{ title: '', handleClick: () => {}, style: '' }],
    isShow: false,
  });

  const initialValue = {
    limit: 10,
    offset: 0,
    currentPage: 1,
  };

  const initialise = () => {
    setStateBySearchParams([
      { key: 'limit', value: String(initialValue.limit) },
      { key: 'offset', value: String(initialValue.offset) },
      { key: 'currentPage', value: String(initialValue.currentPage) },
    ]);
  };

  const [toastState, setToastState] = useState({
    message: '',
    handleClickClose: () => {},
    isShow: false,
  });

  const setValue = (key: string, value: number) => {
    setStateBySearchParams([{ key, value: String(value) }]);
  };
  const { mutate: mutateDeleteReview } = useMutationDeleteReview();

  const handleClickShowReview = (reviewItem: number) => {
    setActiveReview(activeReview === reviewItem ? 0 : reviewItem);
  };
  const toggleAlert = () => {
    setAlertState(prev => ({ ...prev, isShow: !prev.isShow }));
  };

  const closeModal = (success?: boolean) => {
    setReviewCreateModal(0);
    // success && refetchReviewData();
  };
  const handleClickCreateReview = (prdId: number) => {
    handleMixpanelEvent('click_review_write', { from: 'product', product_id: prdId });
    setReviewCreateModal(prdId);
  };

  const handleDeleteReviewSuccess = () => {
    toggleAlert();
    setToastState({
      message: '리뷰가 삭제되었습니다.',
      handleClickClose: () => setToastState(prev => ({ ...prev, isShow: false })),
      isShow: true,
    });
  };

  const handleClickDeleteBtn = (refetch: () => void, reviewIdx: number) => {
    setAlertState({
      header: ['리뷰를 삭제하시겠어요?'],
      body: ['리뷰를 삭제하면', '다시 작성할 수 없어요.', '정말 삭제하시겠어요?'],
      buttons: [
        { title: '이전으로', handleClick: toggleAlert, style: 'outline' },
        {
          title: '삭제',
          handleClick: () => {
            mutateDeleteReview(
              { reviewIdx },
              {
                onSuccess: () => {
                  handleDeleteReviewSuccess();
                  refetch();
                },
              },
            );
          },
          style: '',
        },
      ],
      isShow: true,
    });
  };
  const toggleToast = () => {
    setToastState(prev => ({ ...prev, isShow: !prev.isShow }));
  };
  return {
    offset,
    currentPage,
    reviewCreateModal,
    activeReview,
    alertState,
    toastState,
    initialValue,
    setValue,
    initialise,
    closeModal,
    handleClickCreateReview,
    handleClickDeleteBtn,
    handleClickShowReview,
    toggleToast,
  };
}
