import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { QueryOptionsType } from '@/generated/api/type/api';
import { WoPreorderOptionsGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  preorder_idx: string;
  options?: QueryOptionsType<WoPreorderOptionsGetRes>;
}

export function useFetchOrderOptions({ preorder_idx }: Props) {
  const getOrderOptions = async () => {
    return await get(`/api/commerce/v1/preorders/${preorder_idx}/options`).then(({ data }) => data);
  };

  return useQuery({
    queryKey: ['useFetchOrderOptions', preorder_idx],
    queryFn: getOrderOptions,
  });
}
