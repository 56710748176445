import { ChangeEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '@components/common/molecules/modal/index';
import { Toast } from '@components/common/molecules/toast';

import { useMutationInquiryCreate } from '@/apis/commerce/boards/useMutationInquiryCreate';
import { useFetchBoardSpecs } from '@/apis/commerce/specs/useFetchBoardSpecs';
import { DISABLED_STATUS } from '@/constants/order';
import { PATH } from '@/constants/path';
import { WoDetailedProduct, WoOrderOption } from '@/generated/api/type/data-contracts';
import { numberFormat } from '@/utils/formats';

interface InquiryModalProps {
  toggleModal: () => void;
  optionItem?: WoOrderOption;
  productItem?: WoDetailedProduct;
  qnaBoardTypeCd: 'order' | 'product';
  isOpen: boolean;
}
export const InquiryModal = ({
  toggleModal,
  optionItem,
  productItem,
  qnaBoardTypeCd,
  isOpen,
}: InquiryModalProps) => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownSelected, setDropdownSelected] = useState('');
  const [textBox, setTextBox] = useState('');
  const { data: specData } = useFetchBoardSpecs({ options: { staleTime: 1000 * 60 * 60 } });
  const { mutate: mutateCreate } = useMutationInquiryCreate({ qnaBoardTypeCd });

  const handleChangeTextBox = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.currentTarget.value.length <= 1000) {
      setTextBox(e.currentTarget.value);
    }
  };

  const qnaTypeCd =
    specData?.board_qna_type_codes?.find(item => item.qna_type_name === dropdownSelected)
      ?.qna_type_cd ?? '';

  const toggleDropdown = () => {
    setIsDropdownOpen(pre => !pre);
  };

  const handleClickSubmit = () => {
    mutateCreate(
      {
        qnaTypeCd,
        qnaText: textBox,
        openYn: 'N', // Y: 공개, N: 비공개 -> 1:1 게시판은 N으로 보냄
        notiAnswerYn: 'N',
      },
      { onSuccess: () => setIsSubmitSuccess(true), onError: e => console.log(e) },
    );
  };

  return isSubmitSuccess ? (
    <div>
      <Toast
        message="문의가 성공적으로 등록되었습니다."
        duration={5000}
        handleClickClose={toggleModal}
      />
    </div>
  ) : (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={qnaBoardTypeCd === 'order' ? '주문/배송 문의' : '상품 문의'}
      btns={[
        {
          title: '문의하기',
          handleClick: () => handleClickSubmit(),
          disabled: textBox.length < 10 || !dropdownSelected,
        },
      ]}
    >
      <div className="row main">
        {qnaBoardTypeCd === 'order' && optionItem ? (
          <div className="product-item horizontal">
            <div className="thumbnail">
              <img src={optionItem?.main_image_url} alt="제품이미지" />
            </div>
            <div className="info-wrap">
              {optionItem?.order_status_name && (
                <span
                  className={`badge-payment${
                    DISABLED_STATUS.includes(optionItem?.order_status_cd ?? '') ? ' dark' : ''
                  }`}
                >
                  {optionItem?.order_status_name}
                </span>
              )}
              <p className="title">{optionItem?.product_name}</p>
              <p className="option">{optionItem?.order_option_name}</p>
              <div className="payment-price-amount-col">
                <div className="pricing">
                  {numberFormat(optionItem?.order_option_tot_amount)}
                  <span className="unit">원</span>
                </div>
                <div className="amount">
                  {optionItem?.quantity}
                  <span className="unit">개</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          productItem && (
            <div className="product-item horizontal">
              <div className="thumbnail">
                <img src={productItem?.main_image_url} alt="제품이미지" />
              </div>
              <div className="info-wrap">
                <div className="pricing">
                  <span className="discount-percent">{productItem?.discount_rate}%</span>
                  <span>
                    {numberFormat(productItem?.discounted_price ?? productItem?.sale_price)}
                  </span>
                  <span className="unit">원</span>
                </div>
                <p className="title">{productItem?.product_name}</p>
                <div className="badge-group">
                  {productItem?.free_shipping_limit && <span className="badge">무료배송</span>}
                  {/* {!productItem?.free_shipping_limit && <span className="badge">사은품</span>} */}
                </div>
              </div>
            </div>
          )
        )}
        <div className="dropdown">
          <button
            className={`btn btn-outline btn-md dropdown-toggle${
              isDropdownOpen ? ' menu-open' : ''
            }`}
            onClick={toggleDropdown}
          >
            {dropdownSelected || (
              <>
                {dropdownSelected || '문의 유형'}
                <sup>*</sup>
              </>
            )}
          </button>
          <ul className={`dropdown-menu${isDropdownOpen ? ' show' : ''}`}>
            {specData?.board_qna_type_codes?.map(item => (
              <li key={`dropdown_${item?.qna_type_name}`}>
                <Link
                  className={`dropdown-item${
                    dropdownSelected === item?.qna_type_name ? ' active' : ''
                  }`}
                  to="#"
                  onClick={() => {
                    setDropdownSelected(item?.qna_type_name ?? '');
                    toggleDropdown();
                  }}
                >
                  {item?.qna_type_name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="input-group">
          <textarea
            className="form-control"
            placeholder="최소 10자 이상 작성"
            onChange={handleChangeTextBox}
          />
          <div className="view-words">
            <span className="value">{textBox?.length}</span>/1000
          </div>
        </div>
      </div>
      <div className="row caution">
        <span className="with-icon">
          문의하신 내용에 대한 답변은 내 정보 &gt; <Link to={PATH?.MY_INQUIRY}>나의 문의 관리</Link>
          에서 확인하실 수 있습니다.
        </span>
      </div>
    </Modal>
  );
};
