import Lottie from 'react-lottie';

import loadingImg from '@/assets/img/loading-light-green.json';

export const PassSuccessTemplate = () => {
  const defaultOptions = {
    loop: true,
    Autoplay: true,
    animationData: loadingImg,
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80vh',
      }}
    >
      <Lottie options={defaultOptions} width={400} height={400} />
    </div>
  );
};
