import { MouseEvent } from 'react';

import useMyInfo from '@/hooks/use-my-info';
import { ToastData } from '@/types/toast';
import { UpdateUserDataParams } from '@/types/user';

interface UserNicknameProps {
  nickname: string;
  modifyStatus: string;
  handleUpdateUserData: (data: UpdateUserDataParams, toastData: ToastData) => void;
  handleModifyNicknameBtnClick: (e: MouseEvent<HTMLButtonElement>) => void;
}
export const UserNickname = ({
  nickname,
  modifyStatus,
  handleUpdateUserData,
  handleModifyNicknameBtnClick,
}: UserNicknameProps) => {
  const { isNicknameValid, handleChange, values } = useMyInfo();

  return (
    <li className="myInfo-forms-item">
      <div className="label">닉네임</div>
      {modifyStatus === 'nickname' ? (
        <div className="forms-group">
          <div className="input-group">
            <input
              type="text"
              name="nickname"
              className={`form-control${isNicknameValid ? '' : ' has-validation'}`}
              placeholder="2-10자"
              onChange={handleChange}
              value={values.nickname}
            />
            {values.nickname.length > 0 && (
              <div className="form-text">띄어쓰기, 특수문자, 이모지, 한자 불가능</div>
            )}
          </div>

          <button
            type="button"
            className="btn btn-md btn-filled"
            name="nickname"
            onClick={() =>
              handleUpdateUserData(
                { nickname: values.nickname },
                { message: '닉네임을 수정했어요', toastType: 'checked' },
              )
            }
            disabled={!isNicknameValid && values.nickname.length > 2}
          >
            수정
          </button>
        </div>
      ) : (
        <div className="input-group inline nickname">
          <div className="text">{nickname}</div>
          <button
            type="button"
            className="btn btn-sm btn-outline"
            name="nickname"
            onClick={handleModifyNicknameBtnClick}
          >
            수정
          </button>
        </div>
      )}
    </li>
  );
};
