import { useParams } from 'react-router-dom';
import useFetchShowcase from '@/apis/commerce/showcases/useFetchShowcase';
import { Link } from 'react-router-dom';
import { useFetchProductList } from '@/apis/commerce/products/useFetchProductList';
import { ProductList } from '@/components/domain/main/product-list';

export default function ShowcaseTemplate() {
  const { id } = useParams();
  const { data: showcaseData } = useFetchShowcase({ csShowcaseIdx: Number(id) });
  const showcaseName = 'SHOWCASE';
  const { data: productListData } = useFetchProductList({
    offset: 0,
    limit: 100,
    value: { orderColumn: 'created_at', order: '' },
    type: 'special',
    csShowcaseIdx: Number(id),
  });

  return (
    <>
      <section className="special-event-wrap">
        <div className="jumbotron">{showcaseName}</div>
        {showcaseData?.showcase_details?.map(
          (
            item: {
              cs_showcase_detail_idx: number;
              showcase_image_url: string;
              showcase_url: string;
            },
            idx: number,
          ) =>
            item?.showcase_url ? (
              <Link className="link-img" to={item?.showcase_url} key={idx}>
                <img src={item?.showcase_image_url} alt="showcase" />
              </Link>
            ) : (
              <img className="default-img" src={item?.showcase_image_url} alt="" />
            ),
        )}

        <div className="product-wrap">
          <div className="product-list-header" id="list-header-event">
            <div>{showcaseName}</div>
          </div>

          <ProductList list={productListData?.products ?? []} type="vertical" />
        </div>
      </section>
    </>
  );
}
