import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PrdListPaginationDesktop } from '@components/domain/main/prd-list-pagination-desktop';
import { PrdListPaginationMobile } from '@components/domain/main/prd-list-pagination-mobile';
import { Sort } from '@components/domain/main/sort';
import { KeywordList } from '@components/domain/search/keyword-list';
import { SearchBox } from '@components/domain/search/search-box';

import { useFetchBestKeywords } from '@/apis/commerce/kwords/useFetchBestKeywords';
import { useFetchSearch } from '@/apis/commerce/products/useFetchSearch';
import { useInfinitelyFetchSearch } from '@/apis/commerce/products/useInfinitelyFetchSearch';
import { useFetchRecentlySearchedList } from '@/apis/commerce/self/useFetchRecentlySearchedList';
import { useMutationDeleteSearchedKeywords } from '@/apis/commerce/self/useMutationDeleteSearchedKeywords';
import { useMutationDeleteSingleKeyword } from '@/apis/commerce/self/useMutationDeleteSingleKeyword';
import { PATH } from '@/constants/path';
import { ProductList } from '@/components/domain/main/product-list';

export const SearchTemplate = () => {
  const navigate = useNavigate();
  const { keyword } = useParams();
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({
    title: '판매인기순',
    value: { orderColumn: 'sale_count', order: 'desc' },
  });
  const isMobile = window.innerWidth < 905;
  const limit = isMobile ? 12 : 20;

  const { data: bestKeywordData } = useFetchBestKeywords({});
  const { data: recentlySearchedData, refetch: refetchRecentlySearched } =
    useFetchRecentlySearchedList({
      options: {
        staleTime: 0,
      },
    });
  const { mutate: mutateDeleteSearchedList } = useMutationDeleteSearchedKeywords({
    options: { onSuccess: async () => await refetchRecentlySearched() },
  });
  const { mutate: mutateDeleteSingleKeyword } = useMutationDeleteSingleKeyword();
  const { data: searchData, isFetched: isSearched } = useFetchSearch({
    keyword: keyword ?? '',
    offset,
    limit,
    orders: order.value,
    options: { enabled: !!keyword?.length && !isMobile },
  });

  const {
    data: infinitePrdList,
    hasNextPage,
    fetchNextPage,
  } = useInfinitelyFetchSearch({
    keyword: keyword || '',
    offset: 0,
    limit,
    orders: order.value,
  });

  const total = (isMobile ? infinitePrdList?.pages?.[0]?.total : searchData?.total_count) ?? 0;

  const handleSearchAction = (keyword: string) => {
    navigate(`${PATH.SEARCH}/${keyword}`);
  };

  const handleDelete = () =>
    mutateDeleteSearchedList({}, { onError: err => console.log(err.message) });
  const handleClickDeleteSingleKeyword = (keyword: string) =>
    mutateDeleteSingleKeyword(keyword, { onSuccess: () => refetchRecentlySearched() });

  const handleChangeSorting = (value: {
    title: string;
    value: { orderColumn: string; order: string };
  }) => {
    setOrder(value);
  };

  const hasData =
    (infinitePrdList?.pages?.[0]?.total ?? 0) > 0 ||
    (isSearched && searchData?.products && searchData?.products?.length > 0);

  useEffect(() => {
    (!searchData || !infinitePrdList) && refetchRecentlySearched();
  }, [searchData]);
  return (
    <>
      {keyword && (
        <section className="desktop-jumbotron">
          <span className="text">“{keyword}” 에 대한 검색 결과</span>
        </section>
      )}
      {/* 모바일용 검색 바 */}
      <section className="mobile-search-bar">
        <SearchBox />
      </section>
      {hasData && (
        <section style={{ display: 'block' }}>
          <Sort total={total ?? 0} selected={order.title} handleClickSort={handleChangeSorting} />
          {isMobile ? (
            <PrdListPaginationMobile
              infinitePrdList={infinitePrdList}
              hasNextPage={hasNextPage}
              handleClickNextPage={fetchNextPage}
            />
          ) : (
            <>
              {searchData?.products && (
                <>
                  <ProductList list={searchData?.products} type="vertical" />
                  <PrdListPaginationDesktop
                    // products={searchData?.products}
                    setOffset={setOffset}
                    limit={limit}
                    total={total}
                  />
                </>
              )}
            </>
          )}
        </section>
      )}
      {keyword && ((isSearched && !searchData) || !hasData) && (
        <div className="empty-list search">
          <div className="lines">
            <span>“검색 결과가 없어요”</span>
            <span className="line"></span>
          </div>
          <div className="sub-text">
            일치하는 내용이 없습니다.
            <br />
            다시 검색해 보세요
          </div>
        </div>
      )}
      {!searchData && !hasData && (
        <>
          <section>
            <div className="product-list-header">
              <div>최근 검색어</div>
              {recentlySearchedData?.input_kwords !== undefined &&
                recentlySearchedData?.input_kwords?.length > 0 && (
                  <Link className="btn-delete-all" to="#" onClick={handleDelete}>
                    전체삭제
                  </Link>
                )}
            </div>

            {recentlySearchedData?.input_kwords !== undefined &&
            recentlySearchedData?.input_kwords?.length > 0 ? (
              <KeywordList
                title="최근 검색어"
                list={recentlySearchedData?.input_kwords}
                handleSearchAction={handleSearchAction}
                type="recent"
                handleDeleteSingleKeyword={handleClickDeleteSingleKeyword}
              />
            ) : (
              <div className="recent-search-none">최근 검색어가 없어요.</div>
            )}
          </section>
          <section>
            <div className="product-list-header">
              <div>인기 검색어</div>
            </div>
            {bestKeywordData?.kwords !== undefined && (
              <KeywordList
                title="인기 검색어"
                list={bestKeywordData?.kwords}
                handleSearchAction={handleSearchAction}
                type="popular"
              />
            )}
          </section>
          {/* <div className="mobile-divider"></div>

          <section className="mobile-mg-r-0">
            <div className="product-list-header">
              <div>요즘 인기있는 상품</div>
            </div>
            <div id="load-horizontal-prd"></div>
          </section> */}
        </>
      )}
    </>
  );
};
