import { MouseEvent, useState } from 'react';

import { UserInfoRes } from '@/apis/friendly-pharmacist/user/useFetchGetUserInfo';

interface AdsAgreementProps {
  userInfo?: UserInfoRes;
  handleClickCheckbox: (e: MouseEvent<HTMLInputElement>) => void;
}
/**
 * 혜택 정보 수신 동의
 */
export const AdsAgreement = ({ userInfo, handleClickCheckbox }: AdsAgreementProps) => {
  const valuesArr = Object.values(userInfo?.user_info?.agreements ?? '');
  const booleanValues = valuesArr?.reduce((acc, curr) => {
    return [...acc, curr.value];
  }, []);
  const isCheckAny = booleanValues.some((i: boolean) => i); // 하나라도 true면 true
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const toggleTerms = () => {
    setIsTermsOpen(pre => !pre);
  };

  return (
    <div className="section-contents">
      <article className={`myInfo-terms${isTermsOpen ? ' active' : ''}`}>
        <div className="title-wrap" onClick={toggleTerms}>
          <span>혜택 정보 수신 동의</span>
          <img src="/assets/ic/SelectiveTCBadge.svg" alt="" />
        </div>
        <div className="terms-wrap">
          <ul className="outer-list">
            <li className="list-item">
              <label className="checkbox-group green-box sm-box">
                <input
                  type="checkbox"
                  name="all"
                  checked={isCheckAny}
                  readOnly
                  onClick={handleClickCheckbox}
                />
                <span className="label">[선택] 광고성 정보 수신 동의</span>
                <span className="checkmark"></span>
              </label>
              <ul className="inner-list">
                <li className="list-item">
                  <label className="checkbox-group green-box sm-box">
                    <input
                      type="checkbox"
                      name="sms"
                      checked={userInfo?.user_info?.agreements?.sms?.value}
                      readOnly
                      onClick={handleClickCheckbox}
                    />
                    <span className="label">SMS</span>
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li className="list-item">
                  <label className="checkbox-group green-box sm-box">
                    <input
                      type="checkbox"
                      name="email"
                      checked={userInfo?.user_info?.agreements?.email?.value}
                      readOnly
                      onClick={handleClickCheckbox}
                    />
                    <span className="label">이메일</span>
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li className="list-item">
                  <label className="checkbox-group green-box sm-box">
                    <input
                      type="checkbox"
                      name="push"
                      checked={userInfo?.user_info?.agreements?.push?.value}
                      readOnly
                      onClick={handleClickCheckbox}
                    />
                    <span className="label">푸시</span>
                    <span className="checkmark"></span>
                  </label>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </article>
    </div>
  );
};
