import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

export function useMutationLoginEmail() {
  const login = async ({ email, password }: { email: string; password: string }) => {
    const response = await post('/user/login/email', {
      email,
      password,
    });
    return response.data;
  };

  return useMutation({
    mutationFn: login,
    onError: error => {
      console.log('useMutationLoginEmail Error >> ', error);
    },
  });
}
