import { Link } from 'react-router-dom';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { type WoProduct } from '@/generated/api/type/data-contracts';

import { ProductItem } from './product-item';

interface ProductListProps {
  list: WoProduct[];
  type: 'vertical' | 'horizontal' | 'mixed';
  isSwiper?: boolean;
}
export const ProductList = ({ list, type, isSwiper = false }: ProductListProps) => {
  if (list.length <= 0) return <></>;

  const isWide = window.innerWidth >= 1200;
  const perSlide = isWide ? 6 : 4;

  return (
    <>
      {isSwiper ? (
        <Swiper
          slidesPerView={perSlide}
          slidesPerGroup={perSlide}
          spaceBetween={20}
          navigation={{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
          }}
          className="swiper-wrapper"
          modules={[Navigation]}
          pagination={{
            clickable: true,
          }}
        >
          {type === 'horizontal' && (
            <>
              {/* <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: 2 }}> */}
              <Link to="#" className="swiper-button-prev btn btn-outline btn-rounded" />
              <Link to="#" className="swiper-button-next btn btn-outline btn-rounded right" />
              {/* </div> */}
            </>
          )}
          <ul id={type === 'vertical' ? `vertical-prd` : ''} className={`product-list ${type}`}>
            {list.map((item, prdIdx) => (
              <SwiperSlide key={`product_${prdIdx}_${item.product_name ?? ''}`}>
                <ProductItem item={item} />
              </SwiperSlide>
            ))}
          </ul>
        </Swiper>
      ) : (
        <ul id={type === 'vertical' ? `vertical-prd` : ''} className={`product-list ${type}`}>
          {list.map((item, prdIdx) => (
            <ProductItem item={item} key={`verticalPrd_${prdIdx}`} />
          ))}
        </ul>
      )}
    </>
  );
};
