import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertModal } from '@components/common/molecules/modal/alert-modal';
import { InquiryModal } from '@components/common/molecules/modal/inquiry-modal';
import { OrderCancellationModal } from '@components/common/molecules/modal/order-cancellation-modal';
import { Toast } from '@components/common/molecules/toast';
import { OrderDetailInfo } from '@components/domain/mypage/orders/order-detail-info';
import { OrderDetailItem } from '@components/domain/mypage/orders/order-detail-item';

import { useMutationAddCart } from '@/apis/commerce/cart/useMutationAddCart';
import { useFetchOrderDetail } from '@/apis/commerce/orders/useFetchOrderDetail';
import { useMutationOrderConfirm } from '@/apis/commerce/orders/useMutationOrderConfirm';
import { useFetchGetUserDetail } from '@/apis/commerce/self/useFetchGetUserDetail';
import { ReviewModal } from '@/components/domain/review/review-modal';
import { PATH } from '@/constants/path';
import { WoCartItemCreateForm, WoOrderOption } from '@/generated/api/type/data-contracts';
import useLoginInfo from '@/hooks/use-login-info';
import useMixpanel from '@/hooks/use-mixpanel';
import { dateToStringFormat } from '@/utils/formats';

// const ORDER_STATUS = {
//   T0: '결제대기',
//   Y0: '결제완료',
//   Y2: '배송준비',
//   Y4: '배송시작',
//   Y6: '배송중',
//   Y7: '도착예정',
//   Y8: '배송완료',
//   Y9: '구매확정',
//   C1: '취소요청',
//   C2: '취소중',
//   C3: '취소실패',
//   C4: '취소완료',
//   R1: '환불요청',
//   R2: '환불중',
//   R3: '환불취소',
//   R4: '환불완료',
// };

interface MypageOrdersDetailTemplateProps {}
export const MypageOrdersDetailTemplate = ({}: MypageOrdersDetailTemplateProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [toastState, setToastState] = useState({ title: '', duration: 500, isShow: false });
  const [modalState, setModalState] = useState({ title: '', isShow: false });
  const [selectedCompanyName, setSelectedCompanyName] = useState('');
  const { data: orderData, refetch: refetchOrderData } = useFetchOrderDetail({
    orderIdx: id ?? '',
  });
  const [interactItem, setInteractItem] = useState<WoOrderOption | undefined>(undefined);
  // const everyOptions = orderData?.seller_sections
  //   ?.map(item => item?.order_options?.map(option => option.order_option_idx))
  //   ?.flat() as number[];
  // const selectedAll = everyOptions && everyOptions?.length === selectedOptions.length;
  const { userToken } = useLoginInfo();
  const { mutate: mutateAddToCart } = useMutationAddCart();
  const { mutate: mutateOrderConfirm } = useMutationOrderConfirm();
  const { handleMixpanelEvent } = useMixpanel();
  const { refetch: refetchUserDetail } = useFetchGetUserDetail({
    accessToken: userToken,
  });

  const handleAddingCart = (param: WoCartItemCreateForm) => {
    handleMixpanelEvent('click_add_cart', {
      from: 'order_detail',
      option_name:
        orderData?.seller_sections
          ?.flatMap(seller => seller?.order_options || []) // order_options 배열을 평탄화
          .find(option => option?.product_option_idx === param?.product_option_idx)
          ?.order_option_name ?? '',
      option_id: param?.product_idx,
      option_amount: 1,
    });
    mutateAddToCart([param], {
      onSuccess: () => {
        setToastState({ title: '장바구니에 담았어요', duration: 1000, isShow: true });
        refetchUserDetail();
      },
      onError: err => console.log(err.message),
    });
  };

  const handleCloseToast = () => {
    setToastState({ title: '', duration: 300, isShow: false });
  };

  const handleOpenModal = (title: string) => {
    setModalState({ title, isShow: true });
  };

  const handleClickReview = (option: WoOrderOption) => {
    handleOpenModal('review');
    setInteractItem(option);
  };

  const toggleModal = (isSuccess?: boolean) => {
    setModalState({ title: '', isShow: false });
    isSuccess && refetchOrderData();
  };
  const handleSelectCompany = (companyName: string) => {
    setSelectedCompanyName(companyName);
  };

  // const handleSelectAll = () => {
  //   selectedAll ? setSelectedOptions([]) : setSelectedOptions(everyOptions);
  // };

  const handleClickOrderConfirm = (option: number) => {
    mutateOrderConfirm(
      {
        orderOptions: option,
        orderIdx: id ?? '',
      },
      { onSuccess: () => refetchOrderData() },
    );
  };
  const createdTime = orderData?.seller_sections?.[0]?.order_options?.[0]?.created_at ?? '';
  const createdTimeFormat = `${dateToStringFormat(new Date(createdTime), 'dot')} ${createdTime
    .split('T')[1]
    ?.slice(0, 8)}`;

  return (
    <main className="my-main">
      {toastState.isShow && (
        <Toast
          message={toastState.title}
          duration={toastState.duration}
          handleClickClose={handleCloseToast}
        />
      )}
      {modalState.isShow &&
        (modalState.title === 'inquiry' && interactItem ? (
          <InquiryModal
            isOpen={true}
            toggleModal={toggleModal}
            optionItem={interactItem}
            qnaBoardTypeCd={'order'}
          />
        ) : ['refund', 'cancel'].includes(modalState.title) ? (
          <OrderCancellationModal
            isOpen={true}
            optionList={
              orderData?.seller_sections?.find(item => item.company_name === selectedCompanyName)
                ?.order_options
            }
            toggleModal={toggleModal}
            type={modalState.title as 'cancel' | 'refund'}
          />
        ) : modalState.title === 'review' ? (
          <ReviewModal
            isOpen={true}
            toggleModal={toggleModal}
            prdId={String(interactItem?.product_idx)}
          />
        ) : (
          <></>
        ))}
      {modalState.isShow && modalState.title === 'exchange' && (
        <AlertModal
          isOpen={true}
          header={['문의하기를 이용해주세요.']}
          body={['교환 접수는 문의하기를 통해서', '접수를 부탁드려요.']}
          buttons={[
            {
              title: '확인',
              handleClick: () => toggleModal(),
            },
          ]}
        />
      )}
      {/* <!-- 주문 번호 --> */}
      <div className="section-contents payment-complete-header">
        <ul className="section-title">
          <li className="desktop-title">
            주문상품 <span>({orderData?.seller_sections?.length ?? 0}건)</span>
          </li>
          <li className="prd-info">{createdTimeFormat}</li>
          <li className="prd-number">주문번호 {id}</li>
        </ul>
        {/* <label className="checkbox-group green-box sm-box">
          <input type="checkbox" name="test-1" onChange={handleSelectAll} checked={selectedAll} />
          <span className="label">전체선택</span>
          <span className="checkmark"></span>
        </label> */}
      </div>
      {/* <!-- 주문상품 --> */}
      <div className="section-contents">
        {/* <!-- 모바일에서만 표시되는 상품 건 수 --> */}
        <div className="section-title mobile-title">
          <div className="label">
            주문상품<span className="value">({orderData?.seller_sections?.length ?? 0}건)</span>
          </div>
        </div>
        {/* <!-- NOTI: 주문/결제(Payment)에서 사용하는 리스트와 동일한 모듈이나 다른 부분은 FIXME: 주석 달아놓습니다 --> */}
        <ul className="selected-payment-list">
          {orderData?.seller_sections?.map(seller => (
            <li className="payment-item" key={seller?.seller_idx}>
              <article className="border-wrap">
                <div className="title-col">
                  <div className="title">{seller?.company_name}</div>
                </div>
                <ul className="selected-store-list">
                  {/* <!-- 제품 1 : 결제완료 --> */}
                  {seller?.order_options?.map(option => (
                    <OrderDetailItem
                      key={`order_option_${option?.order_option_idx}`}
                      option={option}
                      handleAddingCart={handleAddingCart}
                      handleClickOrderConfirm={handleClickOrderConfirm}
                      handleClickReview={handleClickReview}
                      handleInquiryClick={() => {
                        handleOpenModal('inquiry');
                        setInteractItem(option);
                      }}
                      handleOpenModal={handleOpenModal}
                      handleSelectCompany={() => handleSelectCompany(seller.company_name ?? '')}
                    />
                  ))}
                </ul>
              </article>
            </li>
          ))}
        </ul>
        {/* <div className="btn-inline-group mobile-fluid">
          {orderData?.seller_sections?.[0]?.order_options?.[0]?.order_status_name === '결제완료' ? (
            <button
              type="button"
              className="btn btn-md btn-tonal"
              onClick={() => handleOpenModal('cancel')}
              disabled={selectedOptions.length === 0}
            >
              주문 취소하기
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-md btn-tonal"
                onClick={() => handleOpenModal('refund')}
              >
                반품 요청
              </button>
              <button
                type="button"
                className="btn btn-md btn-tonal"
                onClick={() => handleOpenModal('exchange')}
              >
                교환 요청
              </button>
            </>
          )}
        </div> */}
      </div>
      <OrderDetailInfo />
      <div className="section-contents border-top-0">
        <div className="payment-btn-wrap">
          <button
            type="button"
            className="btn btn-lg btn-outline btn-block"
            onClick={() => navigate(PATH.ORDER_LIST)}
          >
            주문/배송 내역보기
          </button>
        </div>
      </div>
    </main>
  );
};
