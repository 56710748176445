import { useInfiniteQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { WoSelfReviewsGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  offset: number;
  limit: number;
}

const getMyReviews = async (pageParam: Props) => {
  const res = await get(
    `/api/commerce/v1/self/reviews?offset=${pageParam.offset}&limit=${pageParam.limit}`,
  );
  const data = res.data as WoSelfReviewsGetRes;
  return {
    data,
    nextPageOffset: pageParam.offset + pageParam.limit,
    isLast: pageParam.offset + pageParam.limit >= (data?.total_count as number),
    total: data.total_count,
  };
};

export function useInfinitelyFetchMyReviews({ offset, limit }: Props) {
  return useInfiniteQuery({
    queryKey: ['useInfinitelyFetchMyReviews', { offset, limit }],
    queryFn: ({ pageParam }) => getMyReviews(pageParam),
    initialPageParam: { offset: 0, limit },
    getNextPageParam: lastPage => {
      if (lastPage.isLast) return undefined;
      return {
        limit,
        offset: lastPage.nextPageOffset,
      };
    },
  });
}
