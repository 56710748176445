import { useFetchMenu } from '@/apis/commerce/menus/useFetchMenu';
import { Fragment, MouseEvent, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@/constants/path';
import useLoginInfo from '@/hooks/use-login-info';
import redDot from '@/assets/ic/reddot.svg';
import rightArrow from '@/assets/ic/chevron-right.svg';
import useAuth from '@/hooks/use-auth';
import useMixpanel from '@/hooks/use-mixpanel';

export const GnbWhiteBar = ({ isExpanded }: { isExpanded: boolean }) => {
  const { isLogin, logout } = useLoginInfo();
  const { handleMixpanelEvent } = useMixpanel();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const [selected, setSelected] = useState('');
  const { data: menuData } = useFetchMenu({});
  const navigate = useNavigate();
  const { refetchUserDetail } = useAuth();
  const navigateWithFromPath = (e: MouseEvent<HTMLAnchorElement>, toUrl: string) => {
    e.preventDefault();
    navigate(toUrl, { state: { from: `${location.pathname}${location.search ?? ''}` } });
  };

  const handleTracking = (type: string) => {
    handleMixpanelEvent('click_gnb_menu', { type });
  };

  const tabRef = useRef<HTMLDivElement>(null);
  const handleCloseSubmenu = (e: Event) => {
    if (tabRef.current && !tabRef.current.contains(e.target as Node)) {
      setIsSubMenuOpen(false);
      setSelected('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleCloseSubmenu);
    return () => document.removeEventListener('mousedown', handleCloseSubmenu);
  }, []);

  return (
    <div className={`fluid-sub${isExpanded ? '' : ' desktop-bar'}`}>
      <div className="contents-wrap">
        {/* <!-- 탭 --> */}
        <div className="tabs" ref={tabRef}>
          {menuData?.menus?.map(tab => (
            <Link
              key={tab?.menu1_name}
              className={`tab-item${selected === tab?.menu1_name ? ' active' : ''}`}
              to="#"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setIsSubMenuOpen(true);
                setSelected(tab?.menu1_name ?? '');
                handleTracking('tab?.menu1_name');
              }}
            >
              {tab?.menu1_name}
              {tab?.new_yn === 'Y' && <img className="new" src={redDot} alt="new" />}
            </Link>
          ))}
          {menuData?.menus?.map(tab => (
            <Fragment key={`subtab_${tab?.menu1_name}`}>
              {isSubMenuOpen && selected === tab?.menu1_name && (
                <div
                  onClick={e => e.stopPropagation()}
                  style={{ display: 'block' }}
                  className={`navbar-tabs-menu${
                    tab?.menu1_name === '브랜드'
                      ? ' brand'
                      : tab?.collection_type_code === 'special'
                      ? ' special-event'
                      : ''
                  }`}
                >
                  <ul className="menu-list">
                    {tab?.sub_menus?.map(sub => (
                      <li
                        key={`${sub?.menu2_name}${sub?.menu_id}`}
                        className="menu-item"
                        onClick={() => setSelected('')}
                      >
                        <Link
                          to={`${
                            tab?.collection_type_code === 'special'
                              ? `${PATH.SHOWCASE}/`
                              : `/${tab?.collection_type_code}?${sub?.menu_key}=`
                          }${sub?.menu_id}`}
                          onClick={() => handleTracking('home')}
                        >
                          {sub?.menu2_name}
                          {tab?.collection_type_code === 'special' && (
                            <img src={rightArrow} alt="" />
                          )}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </Fragment>
          ))}
        </div>
        <div className="navbar-collapse">
          {!isLogin ? (
            <>
              <Link
                to={PATH.LOGIN}
                className="nav-link"
                onClick={e => {
                  navigateWithFromPath(e, PATH.LOGIN);
                  handleTracking('login');
                }}
              >
                로그인
              </Link>
              <Link
                to={PATH.REGISTER}
                className="nav-link"
                onClick={e => {
                  navigateWithFromPath(e, PATH.REGISTER);
                  handleTracking('register');
                }}
              >
                회원가입
              </Link>
            </>
          ) : (
            <Link
              to="#"
              className="nav-link"
              onClick={e => {
                e.preventDefault();
                logout();
                refetchUserDetail();
                handleTracking('logout');
              }}
            >
              로그아웃
            </Link>
          )}
          <Link to={PATH.HELP_FAQ} className="nav-link" onClick={() => handleTracking('cs_center')}>
            고객센터
          </Link>
        </div>
      </div>
    </div>
  );
};
