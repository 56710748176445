import { Fragment } from 'react';

import { useLockBodyScroll } from '@/hooks/use-lock-body-scroll';

export interface AlertModalProps {
  header?: string[];
  body?: string[];
  buttons: { title: string; handleClick: () => void; style?: string }[];
  closeAlert?: () => void;
  isOpen: boolean;
}

export const AlertModal = ({ header, body, buttons, closeAlert, isOpen }: AlertModalProps) => {
  //TODO: 공통모달컴포넌트로 수정되면 훅 삭제
  useLockBodyScroll(isOpen);

  const handleBtnClick = (fn: any) => {
    fn();
    closeAlert && closeAlert();
  };
  return (
    <div>
      <div className="modal-background" id="payment-alert" onClick={closeAlert && closeAlert}>
        <div className="modal-center" onClick={e => e.stopPropagation()}>
          {header && (
            <div className="modal-header">
              {header?.map((h, i, arr) => (
                <Fragment key={`${h}_${i}`}>
                  {h}
                  {arr.length - 1 > i && <br />}
                </Fragment>
              ))}
            </div>
          )}
          <div className="modal-contents">
            {body?.map((b, i, arr) => (
              <Fragment key={`${i}${b}`}>
                {b}
                {arr.length - 1 > i && <br />}
              </Fragment>
            ))}
          </div>
          <div className="btn-inline-group">
            {buttons.map(btn => (
              <button
                key={btn.title}
                type="button"
                className={`btn btn-md btn-${
                  btn?.style === 'outline' ? 'outline' : `filled`
                } btn-block`}
                onClick={() => handleBtnClick(btn.handleClick)}
              >
                {btn.title}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
