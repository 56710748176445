import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { type QueryOptionsType } from '@/generated/api/type/api';
import { WoSelfReviewsGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  offset: number;
  limit?: number;
  options?: QueryOptionsType<WoSelfReviewsGetRes>;
}

export function useFetchMyReviews({ offset, limit, options }: Props) {
  const getMyReviews = async () => {
    return await get(`/api/commerce/v1/self/reviews?offset=${offset}&limit=${limit}`).then(
      res => res.data as WoSelfReviewsGetRes,
    );
  };

  return useQuery({
    queryKey: ['useFetchMyReviews'],
    queryFn: getMyReviews,
    ...options,
  });
}
