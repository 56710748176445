import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

interface Props {
  productIdx: number;
  reviewScore: number;
  gender: string;
  age: string;
  reviewText: string;
  file?: File;
}

export function useMutationCreateReview() {
  const createReview = async ({
    productIdx,
    reviewScore,
    gender,
    age,
    reviewText,
    file,
  }: Props) => {
    const formData = new FormData();
    file && formData.append('files', file);
    formData.append('review_rating_score', String(reviewScore));
    formData.append('gender_mf', gender);
    formData.append('age_group_cd', age);
    formData.append('review_text', reviewText);

    return await post(`/api/commerce/v1/products/${productIdx}/reviews/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(res => res.data)
      .catch(e => console.log(e));
  };

  return useMutation({ mutationFn: createReview });
}
