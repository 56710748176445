import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import { getKakaoOauthToken } from '@/apis/external/kakao';
import { AUTH_KAKAO_ACCESS_TOKEN, AUTH_REDIRECT_URL_KEY } from '@/constants/auth';
import { PATH } from '@/constants/path';

export const CallbackKakaoPage = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const redirectUrl = sessionStorage.getItem(AUTH_REDIRECT_URL_KEY);
  const { code } = queryString.parse(search);

  useEffect(() => {
    if (code) {
      getKaKaoToken(code as string);
    }
  }, [code]);

  const getKaKaoToken = async (code: string) => {
    try {
      //TODO: 환경변수에 작업필요
      const res = await getKakaoOauthToken({
        grant_type: 'authorization_code',
        client_id: '4548b51fe56aae96d612139756fbc634',
        client_secret: 'EEn5PbCOZVWujIq7gO6DLpWAYEKRP0pG',
        redirect_uri: `${window.location.origin}${PATH.CALLBACK_KAKAO}`,
        code,
      });

      if (res.access_token) {
        sessionStorage.setItem(AUTH_KAKAO_ACCESS_TOKEN, res.access_token);
      }

      if (redirectUrl) {
        navigate(redirectUrl);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return <></>;
};
