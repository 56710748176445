import { HelpFaqTemplate } from '@components/template/help/faq';

/**
 * FAQs(Frequently Asked Questions)
 * 자주하는 질문 페이지 (/help/faqs)
 *
 * TODO: pagination
 */
export const HelpFaqPage = () => {
  return <HelpFaqTemplate />;
};
