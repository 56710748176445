import Lottie from 'react-lottie';

import loadingImg from '@/assets/img/loading-light-green.json';

export const LoadingEntire = () => {
  const defaultOptions = {
    loop: true,
    Autoplay: true,
    animationData: loadingImg,
  };

  return (
    <div>
      <div className="modal-background">
        <Lottie options={defaultOptions} width={400} height={400} />
      </div>
    </div>
  );
};
