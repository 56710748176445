import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { QueryOptionsType } from '@/generated/api/type/api';
import { WoNoticesGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  options?: QueryOptionsType<WoNoticesGetRes>;
}

export function useFetchNotice({ options }: Props) {
  const getNotice = async () => {
    return await get('/api/commerce/v1/notices').then(res => res.data as WoNoticesGetRes);
  };

  return useQuery({
    queryKey: ['useFetchNotice'],
    queryFn: getNotice,
    staleTime: 0,
    ...options,
  });
}
