/**
 * 유효성 검사 함수
 * @param {string} type 'email' | 'emoji' | 'special' | 'eng' | 'num
 * @param {string} value
 * @returns
 */
export const validation = (type: string, value: string) => {
  switch (type) {
    case 'email': {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return regex.test(value);
    }
    case 'emoji': {
      const regex = /[\uD83C-\uDBFF\uDC00-\uDFFF]+/g;
      return regex.test(value);
    }
    case 'special': {
      const regex = /[^\w\s가-힣ㄱ-ㅎㅏ-ㅣ]/g;
      return regex.test(value);
    }

    case 'eng': {
      const regex = /[a-zA-Z]/;
      return regex.test(value);
    }
    case 'num': {
      const regex = /\d/;
      return regex.test(value);
    }
    default:
      return false;
  }
};
