import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import UAParser from 'ua-parser-js';

import useLoginInfo from '@/hooks/use-login-info';

export default function useMixpanel() {
  const { isLogin } = useLoginInfo();
  const location = useLocation();
  const userAgent = UAParser();
  const locationStateFrom = location?.state?.from ?? '';
  const handleMixpanelEvent = (eventName: string, properties: any) => {
    const tracking = () =>
      mixpanel?.track(eventName, {
        login: isLogin,
        device: userAgent.device.model,
        device_type: userAgent.device.type,
        browser: userAgent.browser.name,
        from: properties?.from ?? locationStateFrom,
        ua: userAgent.ua,
        ...properties,
      });

    if (!mixpanel) return;
    if (!mixpanel?.has_opted_in_tracking()) {
      mixpanel?.opt_in_tracking();
      setTimeout(() => {
        if (mixpanel === undefined) return;
        else tracking();
      }, 500);
    } else {
      tracking();
    }
  };
  return { handleMixpanelEvent };
}
