import React from 'react';
import { Link } from 'react-router-dom';

export const AgreementTermsTemplate = () => {
  return (
    <>
      <section className="desktop-jumbotron">
        <span className="text">이용약관</span>
      </section>
      <div className="jumbotron divider">
        <span className="sub-text">
          이 약관은 (주)파마브로스에서 온라인에서 약사 상담과 맞춤 제품을 추천하는 서비스
          ‘친한약사’를 이용함에 있어 회사와 이용자 사이의 권리 · 의무 및 책임사항을 규정함을
          목적으로 합니다.
        </span>
      </div>
      <article>
        <ul className="terms-list-group">
          <li className="list-item">
            <p className="title">총칙</p>
          </li>
          <li className="list-item">
            <p className="title">제 1조 (목적)</p>
            <div>
              본 약관은 회사가 제공하는서비스를 이용함에 있어, ‘회사’와 ‘이용자’ 사이의 권리 · 의무
              및 책임사항을 규정하는데 그 목적이 있습니다. 회사의 서비스를 이용하거나 회원으로
              가입할 경우 이용자는 본 이용 약관 및 관련 운영 정책을 확인, 동의하게 됩니다.
            </div>
          </li>
          <li className="list-item">
            <p className="title">제 2조 (정의)</p>
            <div>
              본 약관에서 사용하는 용어의 정의는 아래와 같습니다. 이 약관에서 규정하지 아니한 용어는
              관계 법령 및 별도의 이용약관 및 정책(이하 ‘서비스 별 안내 등’이라 합니다)에서 정하는
              바에 따르며, 그 외에는 일반 관례에 따릅니다.
            </div>
            <ul className="number-list-group">
              <li className="list-item">
                ‘사이트’란 구현되는 재화 또는 용역을 이용자에게 제공하기 위하여 단말기(PC,
                휴대형단말기 등의 유무선 장치를 포함)를 이용하여 재화 등을 거래할 수 있도록 하는
                온라인 약사상담 서비스 이용 및 이에 부수하는 관련 제반 서비스를 말합니다.
              </li>
              <li className="list-item">
                ‘이용자’라 함은 ‘회사’에서 제공하는 ‘사이트’에 접속하여, 본 약관에 따라 또는 관련
                제반 서비스를 이용하는 ‘회원’과 ‘비회원’을 말합니다.
              </li>
              <li className="list-item">
                ‘회원’이라 함은 ’회사’의 ’친한약사’에 접속하여 이 약관에 따라 ’회사’와 ’이용계약’을
                체결하고, 이용약관에 따라 ‘친한약사’ 서비스를 이용할 수 있는 자를 말합니다.
              </li>
              <li className="list-item">
                ‘비회원’이라 함은 회원에 가입 하지 않고 ‘회사’가 제공하는 서비스를 이용하는 자를
                말합니다.
              </li>
              <li className="list-item">
                '전문가’란 회사와 계약을 체결하고 회사의 서비스를 통해 용역을 제공하는 약사 상담,
                영양제 분석 등을 제공하는 사람들을 말합니다.
              </li>
              <li className="list-item">
                ‘이용계약’이라 함은 이 약관을 포함한 ‘서비스’ 이용과 관련하여 ‘회사’와 ‘회원’간에
                체결하는 모든 계약을 말합니다.
              </li>
              <li className="list-item">
                ‘게시물’이란 회사가 제작 또는 회원이 업로드 하거나, 제휴사가 제공하는 동영상,
                이미지, 텍스트 등 전부를 말합니다.
              </li>
            </ul>
          </li>
          <li className="list-item">
            <p className="title">제 5조 (회원가입)</p>
            <ul className="number-list-group">
              <li className="list-item">
                회원’이 되고자 하는 이용자는 ‘회사’가 정한 절차에 따라 이 약관과 개인정보처리방침,
                개인정보의 수집, 제공 및 활용 동의서에 동의함으로써 회원 가입을 신청합니다.
              </li>
              <li className="list-item"></li>
              <li className="list-item"></li>
              <li className="list-item"></li>
              <li className="list-item"></li>
              <li className="list-item"></li>
              <li className="list-item"></li>
              <li className="list-item">
                ‘회사’는 아래 사항에 해당하는 경우에 대하여 회원 가입 신청을 거부할 수 있으며,
                가입이 된 이후에도 아래의 사유가 확인된 경우에는 승낙을 취소할 수 있습니다
                <ul className="number-list-group sm-list">
                  <li className="list-item">
                    (1) 타인의 정보를 이용하거나 허위의 내용으로 신청한 경우
                  </li>
                  <li className="list-item">(2) 사회의 질서를 저해할 목적으로 신청한 경우</li>
                  <li className="list-item">
                    (3) 영리를 추구할 목적으로 본 서비스를 이용하고자 하는 경우
                  </li>
                  <li className="list-item">(4) 부정한 용도로 본 서비스를 이용하고자 하는 경우</li>
                  <li className="list-item">
                    (5) 회원이 서비스 이용정지 등 이용제한을 받은 후 그 이용제한 기간 중에
                    이용계약을 임의해지하고 다시 가입 신청을 하는 경우
                  </li>
                  <li className="list-item">
                    (6) 기술상 또는 업무상 장애 등 회사의 사유로 가입 신청의 승낙이 어려운 경우
                  </li>
                  <li className="list-item">
                    (7) 기타 회원으로서 가입 신청이 본 약관 또는 관련 법령을 위반하거나 부당하여
                    승낙 거부가 필요하다고 회사가 판단하는 경우
                  </li>
                </ul>
              </li>
              <li className="list-item">
                회원은 가입 시 회사에 제공한 정보에 변경이 있을 경우 즉시 전자우편, 기타 방법으로
                회사에 그 변경사항을 알려야 하며, 알림 지연으로 인하여 발생한 불이익에 대해서 회사는
                회사의 고의 또는 과실이 없는 한 책임지지 않습니다.
              </li>
            </ul>
          </li>
          <li className="list-item">
            <p className="title">서비스 이용</p>
          </li>
          <li className="list-item">
            <p className="title">제 13조 (서비스의 이용)</p>
            <ul className="number-list-group">
              <li className="list-item">
                ‘회사’는 ‘친한약사’ 응용프로그램(어플리케이션, 앱) 과{' '}
                <Link className="link" to="http://www.pharma-bros.com" target="_blank">
                  www.pharma-bros.com
                </Link>
                을 비롯한 웹사이트를 통해 커뮤니티, 영양제 상담, 분석 등 이용자에게 도움이 될 수
                있는 다양한 서비스를 제공하고 있으며 구체적인 내용은 아래와 같습니다. ‘회사’의
                서비스에는 기본적으로 본 약관이 적용되지만, 다양한 서비스를 제공하는 과정에서 부득이
                본 약관 외 별도의 약관, 운영정책등을 적용할 수 있습니다. 각각의 내용은 해당 서비스나
                웹사이트 등에서 확인할 수 있습니다.
              </li>
              <li className="list-item">
                ‘이용자’라 함은 ‘회사’에서 제공하는 ‘사이트’에 접속하여, 본 약관에 따라 또는 관련
                제반 서비스를 이용하는 ‘회원’과 ‘비회원’을 말합니다.
              </li>
              <li className="list-item"></li>
              <li className="list-item"></li>
              <li className="list-item"></li>
              <li className="list-item"></li>
              <li className="list-item"></li>
              <li className="list-item">
                회사는 회원에게 본 약관에 따라 아래와 같이 친한약사 서비스를 제공할 수 있으며,
                필요할 경우 제 3자에게 위탁하여 제공할 수 있습니다.
                <ul className="number-list-group sm-list">
                  <li className="list-item">
                    (1) 전문가의 영양제 상담을 위한 텍스트/음성 등의 기능 제공, 연결, 결과에 대한
                    정보 제공 기능 등
                  </li>
                  <li className="list-item">(2) 회원 문의에 대한 전문가 답변 서비스</li>
                  <li className="list-item">(3) 서비스와 관련된 알림 기능</li>
                  <li className="list-item">(4) 후기 및 평가를 작성하고 공유하는 서비스</li>
                </ul>
              </li>
            </ul>
          </li>
          <li className="list-item">
            <p className="title">기타</p>
          </li>
          <li className="list-item">
            <p className="title">제 19조 (저작권)</p>
            <ul className="number-list-group">
              <li className="list-item">
                ‘회사’는 지적재산권을 존중하며, 본 서비스 이용자들이 타인의 지적재산권 존중을
                기대합니다.
              </li>
              <li className="list-item">
                ‘회원’이 서비스 내에 게시한 게시물의 저작권은 해당 게시물의 저작자에게 귀속됩니다.
                단, ‘회사’는 서비스의 운영, 전시, 전송, 배포, 홍보의 목적으로 별도의 회원 허락 없이
                무상으로 저작권법에 규정하는 공정한 관행에 합치되는 합리적인 범위 내에서 회원이
                등록한 게시물을 사용할 수 있습니다.
              </li>
              <li className="list-item">
                ‘회원’이 작성한 게시물에 대한 상담 답변, 영양제 분석 등 모든 저작물에 대한 저작권은
                ‘회사’에 귀속됩니다.
              </li>
              <li className="list-item">
                ‘회원’은 ‘서비스’를 이용함으로써 얻은 정보 중 ‘회사’에게 지적재산권에 귀속된 정보를
                ‘회사’의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로
                이용하거나 제 3자에게 이용하게 하여서는 안됩니다.
              </li>
              <li className="list-item">
                ‘회사는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게
                통보하여야 합니다.
              </li>
              <li className="list-item">
                ‘회원’과 전문가가 상담과정에서 작성하는 상담내용과 게시하는 글 또는 회사가 운영하는
                플랫폼에 게시하는 글 등 저작물의 저작권 또는 저작물의 이용권은 회사에 무상으로
                영구적으로 귀속되며, ‘회사’는 모든 자료와 정보를 익명화하여 수집하여 건강기능식품
                상담과 관련한 연구 및 개발, 통계, 마케팅에 활용할 수 있습니다. 또한, 빅데이터 활용
                또는 연구를 위해 제 3자에게 재이용권을 허락할 수 있습니다. 이때 ‘회원’의 정보는
                암호화하여 식별 불가능하게 처리합니다.
              </li>
            </ul>
          </li>
          <li className="list-item">
            <p className="title">부칙</p>
            <p>이 약관은 2023년 4월 11일부터 시행합니다</p>
            <ul className="dot-list-group">
              <li className="list-item">공고일자 : 2024년 4월 11일</li>
              <li className="list-item">시행일자 : 2024년 4월 11일</li>
              <li className="list-item">약관 버전 1.0</li>
            </ul>
          </li>
        </ul>
      </article>
    </>
  );
};
