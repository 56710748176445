import { useInfiniteQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { WoProductsSearchGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  keyword: string;
  offset: number;
  limit: number;
  orders: { orderColumn: string; order: string };
}

const getSearch = async (pageParam: Props) => {
  const res = await get(
    `/api/commerce/v1/products/search?input_kword=${pageParam.keyword}&offset=${pageParam.offset}&limit=${pageParam.limit}&order_column=${pageParam.orders.orderColumn}&order=${pageParam.orders.order}`,
  );
  const data = res.data as WoProductsSearchGetRes;
  return {
    data: data?.products,
    nextPageOffset: pageParam.offset + pageParam.limit,
    isLast: pageParam.offset + pageParam.limit >= (data?.total_count as number),
    total: data.total_count,
  };
};

export function useInfinitelyFetchSearch({ keyword, limit, offset, orders }: Props) {
  return useInfiniteQuery({
    queryKey: ['useInfinitelyFetchSearch', { keyword, offset, limit, orders }],
    queryFn: ({ pageParam }) => {
      return getSearch(pageParam);
    },
    initialPageParam: { keyword, offset, limit, orders },
    getNextPageParam: lastPage => {
      if (lastPage.isLast) return undefined;
      return {
        keyword,
        limit,
        orders,
        offset: lastPage.nextPageOffset,
      };
    },
    enabled: !!keyword.length,
  });
}
