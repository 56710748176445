import { ChangeEvent, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Toast } from '@components/common/molecules/toast';

import { useMutationCreateReview } from '@/apis/commerce/products/useMutationCreateReview';
import { useFetchReviewSpecs } from '@/apis/commerce/specs/useFetchReviewSpecs';
import cameraIc from '@/assets/ic/camera.svg';
import starIc from '@/assets/ic/star.svg';
import emptyStarIc from '@/assets/ic/star-gr.svg';
import closeIc from '@/assets/ic/x-lg.svg';
import deleteIc from '@/assets/ic/x-sm.svg';
import { useLockBodyScroll } from '@/hooks/use-lock-body-scroll';

const GENDER_LIST = [
  { name: '여성', value: 'F' },
  { name: '남성', value: 'M' },
  { name: '선택 안함', value: null },
];

interface ReviewModalProps {
  createSuccess?: () => void;
  toggleModal: (success?: boolean, toastOpen?: boolean, message?: string) => void;
  prdId: string;
  isOpen: boolean;
}

export const ReviewModal = ({ createSuccess, toggleModal, prdId, isOpen }: ReviewModalProps) => {
  const [dropdownSelected, setDropdownSelected] = useState({
    open: '',
    gender: '성별',
    age: '연령대',
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [reviewText, setReviewText] = useState('');
  const [selectedScore, setSelectedScore] = useState(5);
  const [inputFile, setInputFile] = useState<File | undefined>(undefined);
  const [isSuccess, setIsSuccess] = useState(false);
  const { mutate: mutateCreateReview } = useMutationCreateReview();
  const { data: reviewSpecsData } = useFetchReviewSpecs();
  const ageSpecData = reviewSpecsData?.review_age_group_codes;
  const selectedAge = ageSpecData?.find(
    age => age.age_group_cd === dropdownSelected.age,
  )?.age_group_name;

  //TODO: 공통모달컴포넌트로 수정되면 훅 삭제
  useLockBodyScroll(isSuccess && isOpen);

  const onClickDropdown = (type: 'gender' | 'age', value?: string) => {
    if (type && !value) {
      dropdownSelected.open === type
        ? setDropdownSelected(pre => ({ ...pre, open: '' }))
        : setDropdownSelected(pre => ({ ...pre, open: type }));
    }
    if (value) {
      setDropdownSelected(pre => ({ ...pre, [type]: value, open: '' }));
    }
  };

  const handleChangeTextBox = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.currentTarget.value.length <= 1000) {
      setReviewText(e.currentTarget.value);
    }
  };
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setInputFile(event.target.files[0]);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  const getGenderValue = (selectedName: string) => {
    return GENDER_LIST.find(item => item.name === selectedName)?.value ?? '';
  };

  const handleSubmit = () => {
    mutateCreateReview(
      {
        productIdx: Number(prdId ?? 0),
        reviewScore: selectedScore,
        gender: getGenderValue(dropdownSelected.gender),
        age: dropdownSelected.age,
        reviewText: reviewText,
        file: inputFile,
      },
      {
        onSuccess: () => {
          setIsSuccess(true);
          createSuccess && createSuccess();
        },
      },
    );
  };

  const previewUrl = inputFile && URL.createObjectURL(inputFile);

  return isSuccess ? (
    <Toast
      message="리뷰 작성 완료했어요"
      duration={500}
      handleClickClose={() => toggleModal(true)}
    />
  ) : (
    <div className="modal-background" onClick={() => toggleModal()}>
      <div className="modal-fluid" onClick={e => e.stopPropagation()}>
        {/* <!-- 상단 네비게이션 --> */}
        <div className="modal-fluid-nav">
          <div className="title">리뷰 작성</div>
          <Link to="#" className="btn-close" onClick={() => toggleModal()}>
            <img src={closeIc} alt="modal close" />
          </Link>
        </div>
        {/* <!-- long text test --> */}
        <div className="modal-contents">
          <div className="row main">
            {/* <!-- 리뷰 점수 --> */}
            <div className="reviews">
              <div className="review-point">
                {selectedScore}점 <span>(매우 만족해요)</span>
              </div>
              <div className="stars">
                {new Array(5).fill(false).map((_, idx) => (
                  <img
                    src={idx < selectedScore ? starIc : emptyStarIc}
                    alt="star"
                    key={`review_select_start${idx}`}
                    onClick={() => setSelectedScore(idx + 1)}
                  />
                ))}
              </div>
            </div>
            {/* <!-- 리뷰 작성 --> */}
            <div className="input-group inline">
              <div className="dropdown">
                <button
                  className="btn btn-outline btn-md dropdown-toggle"
                  type="button"
                  onClick={() => onClickDropdown('gender')}
                >
                  {dropdownSelected.gender}
                </button>
                <ul className={`dropdown-menu${dropdownSelected.open === 'gender' ? ' show' : ''}`}>
                  {GENDER_LIST.map(gender => (
                    <li key={gender.name}>
                      <Link
                        className={`dropdown-item${
                          dropdownSelected.gender === gender.name ? ' active' : ''
                        }`}
                        to="#"
                        onClick={() => onClickDropdown('gender', gender.name)}
                      >
                        {gender.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-outline btn-md dropdown-toggle"
                  type="button"
                  onClick={() => onClickDropdown('age')}
                >
                  {selectedAge || '연령대'}
                </button>
                <ul className={`dropdown-menu${dropdownSelected.open === 'age' ? ' show' : ''}`}>
                  {ageSpecData?.map(age => (
                    <li key={age?.age_group_name}>
                      <Link
                        className={`dropdown-item${
                          dropdownSelected.age === age?.age_group_cd ? ' active' : ''
                        }`}
                        to="#"
                        onClick={() => onClickDropdown('age', age?.age_group_cd)}
                      >
                        {age?.age_group_name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="input-group">
              <textarea
                className="form-control"
                placeholder="최소 10자 이상 작성"
                onChange={handleChangeTextBox}
              />
              <div className="view-words">
                <span className="value">{reviewText.length}</span>/1000
              </div>
            </div>
            {/* <!-- 이미지 업로드 --> */}
            <div className="input-group">
              {inputFile && previewUrl ? (
                <div className="img-uploaded">
                  <img src={previewUrl} alt="" />
                  <Link className="btn-delete" to="#" onClick={() => setInputFile(undefined)}>
                    <img src={deleteIc} alt="" />
                  </Link>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn btn-md btn-outline btn-icon-only"
                  onClick={triggerFileInput}
                >
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  <img src={cameraIc} alt="" />
                </button>
              )}
            </div>
          </div>
          <div className="row caution">
            <span className="with-icon">사진은 최대 1장만 가능합니다.</span>
          </div>
        </div>
        <div className="mobile-bottom-nav">
          <div className="btn-inline-group">
            <button
              type="button"
              className="btn btn-md btn-filled btn-block"
              disabled={
                reviewText.length < 10 ||
                dropdownSelected.age === '연령대' ||
                dropdownSelected.gender === '성별'
              }
              onClick={handleSubmit}
            >
              작성하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
