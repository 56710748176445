import {
  axiosInstanceToExternal,
  SecondParameter,
} from '@/generated/api/config/axios-instance-external';

export interface PostKakaoOauthTokenInBody {
  grant_type: string;
  client_id: string;
  client_secret?: string;
  redirect_uri: string;
  code: string;
}
export interface PostKakaoOauthTokenIn200 {
  token_type: string;
  access_token: string;
  id_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_token_expires_in: number;
  scope: string;
}

export const POST_KAKAO_OAUTH_TOKEN_KEY = 'https://kauth.kakao.com/oauth/token';
export const getKakaoOauthToken = (
  postKakaoOauthTokenInBody: PostKakaoOauthTokenInBody,
  options?: SecondParameter<typeof axiosInstanceToExternal>,
) => {
  return axiosInstanceToExternal<PostKakaoOauthTokenIn200>(
    {
      url: POST_KAKAO_OAUTH_TOKEN_KEY,
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' },
      data: postKakaoOauthTokenInBody,
    },
    options,
  );
};
