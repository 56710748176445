import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { type QueryOptionsType } from '@/generated/api/type/api';
import { WoBoardFaqArticlesGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  limit: number;
  offset: number;
  options?: QueryOptionsType<WoBoardFaqArticlesGetRes>;
}

export function useFetchFaqs({ limit, offset, options }: Props) {
  const getInquiries = async () => {
    return await get(`/api/commerce/v1/boards/faq/articles?offset=${offset}&limit=${limit}`).then(
      res => res.data as WoBoardFaqArticlesGetRes,
    );
  };

  return useQuery({
    queryKey: ['useFetchFaqs'],
    queryFn: getInquiries,
    staleTime: 0,
    ...options,
  });
}
