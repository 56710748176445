import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useFetchGetUserDetail } from '@/apis/commerce/self/useFetchGetUserDetail';
import arrowLeftIc from '@/assets/ic/arrow-left.svg';
import brandIc from '@/assets/ic/brand-logo-wt.svg';
import mypageIcBk from '@/assets/ic/mypage-bk.svg';
import mypageIc from '@/assets/ic/mypage-wt.svg';
import productBoxIc from '@/assets/ic/product-box.svg';
import cartIc from '@/assets/ic/product-box-wt.svg';
import truckIc from '@/assets/ic/truck-wt.svg';
import { PATH, ROUTES } from '@/constants/path';
import useAuth from '@/hooks/use-auth';
import useLoginInfo from '@/hooks/use-login-info';
import useMixpanel from '@/hooks/use-mixpanel';

/**
 * 폐쇄몰 공통 네비게이션
 */
export const ClosureGnb = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { userToken } = useLoginInfo();
  const { data: userDetailData } = useFetchGetUserDetail({
    accessToken: userToken,
  });

  const { handleMixpanelEvent } = useMixpanel();

  const isExpanded = [PATH.HOME, PATH.FRIENDLY, `${PATH.PRODUCT_DETAIL}/`].includes(
    location.pathname.split(id ?? ' ')[0],
  );

  const handlePreviousBtn = () => {
    navigate(-1);
  };

  const title = ROUTES.find(
    item => item.path === (id ? location.pathname.split(id ?? '')[0] : location.pathname),
  )?.title;

  const handleTracking = (type: string) => {
    handleMixpanelEvent('click_gnb_menu', { type });
  };
  const isOrderCompletePage = location.pathname.includes(PATH.ORDER_COMPLETE);

  return (
    <header>
      {!isExpanded ? (
        <nav id="nav-closure-sub" className="navbar closure">
          {/* <!-- 모바일 타이틀 네비게이션 --> */}
          <div className="title-bar">
            <div className="navbar-collapse">
              {isOrderCompletePage ? (
                <></>
              ) : (
                <Link className="nav-link link-mobile" to="#" onClick={handlePreviousBtn}>
                  <img src={arrowLeftIc} alt="back" />
                </Link>
              )}
            </div>
            <div className="title">{title ?? ''}</div>
            <div className="navbar-collapse">
              <Link
                className="nav-link link-mobile"
                to={PATH.MYPAGE}
                onClick={() => handleTracking('my')}
              >
                <img src={mypageIcBk} alt="" />
                {/* <span className="badge-amount">{userDetail?.self_order_status?.order_cnt}</span> */}
              </Link>
              <Link
                className="nav-link link-mobile"
                to={PATH.CART}
                state={{ from: 'gnb' }}
                onClick={() => handleTracking('cart')}
              >
                <img src={productBoxIc} alt="" />
                <span className="badge-amount">
                  {userDetailData?.self_order_status?.cart_cnt ?? 0}
                </span>
              </Link>
            </div>
          </div>
          <div className="desktop-bar">
            <DesktopNavigation
              handleTracking={handleTracking}
              cartCount={userDetailData?.self_order_status?.cart_cnt ?? 0}
              orderCount={userDetailData?.self_order_status?.order_cnt ?? 0}
            />
          </div>
        </nav>
      ) : (
        <nav className="navbar closure">
          <DesktopNavigation
            handleTracking={handleTracking}
            cartCount={userDetailData?.self_order_status?.cart_cnt ?? 0}
            orderCount={userDetailData?.self_order_status?.order_cnt ?? 0}
          />
        </nav>
      )}
    </header>
  );
};

/**
 * 데스크탑 공통 네비게이션
 */
const DesktopNavigation = ({
  cartCount,
  orderCount,
  handleTracking,
}: {
  cartCount: number;
  orderCount: number;
  handleTracking: (type: string) => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLogin } = useLoginInfo();
  const { handleLogOut } = useAuth();

  const navigateWithFromPath = (toUrl: string) => {
    const beforeUrl = location.pathname + location.search;
    const url = `${toUrl}?before_url=${encodeURIComponent(beforeUrl)}`;
    navigate(url);
  };

  return (
    <div className="fluid-main">
      <div className="contents-wrap">
        <button
          className="navbar-brand"
          onClick={e => {
            e.preventDefault();
            handleTracking('my');
          }}
        >
          <img src={brandIc} alt="친한약사" />
        </button>
        <div className="navbar-collapse">
          <Link
            className="nav-link"
            to="#"
            onClick={e => {
              e.preventDefault();
              navigateWithFromPath(isLogin ? PATH.MYPAGE : PATH.LOGIN);
              handleTracking('my');
            }}
          >
            <img src={mypageIc} alt="mypage" />
            <span
              className={`nav-desktop-label${location.pathname === PATH.MYPAGE ? ' active' : ''}`}
            >
              마이
            </span>
          </Link>
          <Link
            className="nav-link link-desktop"
            to="#"
            onClick={e => {
              e.preventDefault();
              navigateWithFromPath(isLogin ? PATH.ORDER_LIST : PATH.LOGIN);
              handleTracking('order');
            }}
          >
            <img src={truckIc} alt="orders,tracking" />
            <span className="badge-amount">{orderCount}</span>
            <span
              className={`nav-desktop-label${
                location.pathname === PATH.ORDER_LIST ? ' active' : ''
              }`}
            >
              주문/배송
            </span>
          </Link>
          <Link
            className="nav-link"
            to={PATH.CART}
            state={{ from: 'gnb' }}
            onClick={e => handleTracking('cart')}
          >
            <img src={cartIc} alt="cart" />
            <span className="badge-amount">{cartCount}</span>
            <span
              className={`nav-desktop-label${location.pathname === PATH.CART ? ' active' : ''}`}
            >
              장바구니
            </span>
          </Link>
          <div className="link-sm-group">
            {isLogin ? (
              <Link
                to="#"
                className="nav-link"
                onClick={e => {
                  e.preventDefault();
                  handleLogOut();
                  handleTracking('logout');
                }}
              >
                로그아웃
              </Link>
            ) : (
              <>
                <Link
                  to="#"
                  className="nav-link"
                  onClick={e => {
                    e.preventDefault();
                    navigateWithFromPath(PATH.LOGIN);
                    handleTracking('login');
                  }}
                >
                  로그인
                </Link>
                <Link
                  to="#"
                  className="nav-link"
                  onClick={e => {
                    e.preventDefault();
                    navigateWithFromPath(PATH.REGISTER);
                    handleTracking('register');
                  }}
                >
                  회원가입
                </Link>
              </>
            )}

            <Link
              to={PATH.HELP_FAQ}
              className="nav-link"
              onClick={() => handleTracking('cs_center')}
            >
              고객센터
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
