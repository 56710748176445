import { useState } from 'react';

import leftArrowIc from '@/assets/ic/chevron-left.svg';
import rightArrowIC from '@/assets/ic/chevron-right.svg';
import leftDoubleArrowIc from '@/assets/ic/double-chevron-left.svg';
import rightDoubleArrowIc from '@/assets/ic/double-chevron-right.svg';
import { useLockBodyScroll } from '@/hooks/use-lock-body-scroll';

interface CalendarModalProps {
  toggleCalendar: () => void;
  type: string;
  selectedDate: Date;
  handleDatePicker: (type: string, date: Date) => void;
  isOpen: boolean;
}
export const CalendarModal = ({
  toggleCalendar,
  type,
  selectedDate,
  handleDatePicker,
  isOpen,
}: CalendarModalProps) => {
  //TODO: 공통모달컴포넌트로 수정되면 훅 삭제
  useLockBodyScroll(isOpen);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const thisYear = today.getFullYear();
  const thisMonth = today.getMonth();
  const [viewData, setViewData] = useState({
    year: selectedDate.getFullYear() ?? thisYear,
    month: selectedDate.getMonth() + 1 ?? thisMonth + 1,
  });

  const firstDay = new Date(viewData.year, viewData.month - 1, 1).getDay() - 1;
  const lastDayOfTheMonth = new Date(viewData.year, viewData.month, 0).getDate();

  const days = Array.from({ length: firstDay + lastDayOfTheMonth }, (_, i) =>
    i < firstDay ? '' : i - firstDay + 1,
  );
  // 날짜 배열의 길이가 7의 배수가 되도록 빈 문자열 추가
  while (days.length % 7 !== 0) days.push('');
  // 주 단위로 날짜 배열을 분할
  const weeks = Array.from({ length: Math.ceil(days.length / 7) }, (_, i) =>
    days.slice(i * 7, i * 7 + 7),
  );

  // 화살표 클릭 핸들러: 월 또는 연도 변경, 다음 달이 오늘 이후면 무시
  const handleClickArrow = (type: 'month' | 'year', direction: 'pre' | 'next') => {
    setViewData(prev => {
      let newYear = prev.year;
      let newMonth = prev.month;

      if (type === 'month') {
        if (direction === 'pre') {
          // 이전 달로 이동
          if (newMonth === 1) {
            newMonth = 12;
            newYear -= 1;
          } else {
            newMonth -= 1;
          }
        } else {
          // 다음 달로 이동
          if (newMonth === 12) {
            newMonth = 1;
            newYear += 1;
          } else {
            newMonth += 1;
          }
        }
      } else {
        // 연도 변경
        newYear = direction === 'pre' ? newYear - 1 : newYear + 1;
      }

      // 새로운 연도와 월이 오늘 이후면 이동하지 않음
      if (newYear > thisYear || (newYear === thisYear && newMonth > thisMonth + 1)) {
        return prev;
      }

      return { year: newYear, month: newMonth };
    });
  };

  return (
    <div className="modal-background calendar-modal" onClick={toggleCalendar}>
      {/* <!-- 옵션을 선택한 바텀시트 --> */}
      <div className="modal-bottom desktop-modal-center">
        <div className="handle"></div>
        <div className="modal-contents calendar-contents" onClick={e => e.stopPropagation()}>
          <div className="contents-header">
            <button className="btn" onClick={() => handleClickArrow('year', 'pre')}>
              <img src={leftDoubleArrowIc} alt="double left arrow" />
            </button>
            <button className="btn" onClick={() => handleClickArrow('month', 'pre')}>
              <img src={leftArrowIc} alt="left arrow" />
            </button>
            <span className="date">
              {viewData.year}년 {viewData.month}월
            </span>
            <button className="btn" onClick={() => handleClickArrow('month', 'next')}>
              <img src={rightArrowIC} alt="right arrow" />
            </button>
            <button className="btn" onClick={() => handleClickArrow('year', 'next')}>
              <img src={rightDoubleArrowIc} alt="double right arrow" />
            </button>
          </div>
          <table className="calendar-table-wrap">
            <thead>
              <tr>
                <th>월</th>
                <th>화</th>
                <th>수</th>
                <th>목</th>
                <th>금</th>
                <th>토</th>
                <th>일</th>
              </tr>
            </thead>
            <tbody>
              {weeks.map((week, weekIdx) => (
                <tr key={`week_${weekIdx}`}>
                  {week.map((day, idx) => {
                    const todayActive =
                      viewData.year === thisYear &&
                      viewData.month === thisMonth + 1 &&
                      today.getDate() === day;

                    const currentDate =
                      typeof day === 'number' && new Date(viewData.year, viewData.month - 1, day);
                    const disabled = currentDate > today;
                    const selected =
                      currentDate && currentDate.toDateString() === selectedDate.toDateString();
                    const newDate = new Date();
                    return (
                      <td key={`${weekIdx}day_${idx}`}>
                        <span
                          className={`date${todayActive ? ' today' : ''}${
                            disabled ? ' disabled' : ''
                          }${selected ? ' selected' : ''}`}
                          onClick={() => {
                            !disabled &&
                              typeof day === 'number' &&
                              handleDatePicker(
                                type,
                                new Date(
                                  viewData.year,
                                  viewData.month - 1,
                                  day,
                                  newDate.getHours(),
                                  newDate.getMinutes(),
                                  newDate.getSeconds(),
                                ),
                              );
                            toggleCalendar();
                          }}
                        >
                          {day}
                        </span>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
