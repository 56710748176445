import { FC, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { DeliveryAddressModal } from '@components/common/molecules/modal/delivery-address-modal';

import { MySidebarDesktop } from '@/layouts/mypage-layout/my-sidebar/my-sidebar-desktop';

export const MypageLayout: FC = () => {
  const { pathname, search } = useLocation();
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

  const handleClickAddressModalToggle = () => {
    setIsAddressModalOpen(!isAddressModalOpen);
  };
  const handleClickAddressManage = () => {
    handleClickAddressModalToggle();
  };

  return (
    <>
      <MySidebarDesktop pathname={pathname} handleClickAddressManage={handleClickAddressManage} />
      <Outlet />
      {isAddressModalOpen && (
        <DeliveryAddressModal
          isOpen={isAddressModalOpen}
          handleClickAddressModalToggle={handleClickAddressModalToggle}
          from="mypage"
        />
      )}
    </>
  );
};
