import { axiosInstanceToFp, SecondParameter } from '@/generated/api/config/axios-instance';

export interface PostUserLoginSocialInBody {
  sns_id: string;
}
export interface PostUserLoginSocialIn200 {
  data: {
    access_token: string;
    refresh_token: string;
  };
  message: string;
}

export const POST_USER_LOGIN_SOCIAL_KEY = '/user/login/social';
export const userLoginSocial = (
  postUserLoginSocialInBody: PostUserLoginSocialInBody,
  options?: SecondParameter<typeof axiosInstanceToFp>,
) => {
  return axiosInstanceToFp<PostUserLoginSocialIn200>(
    {
      url: POST_USER_LOGIN_SOCIAL_KEY,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postUserLoginSocialInBody,
    },
    options,
  );
};

export interface UserInfo {
  id: string;
  account_id?: string;
  account_sns_type?: string;
  created_at?: string;
  nickname?: string;
  phone?: string;
  email?: string;
  name?: string;
  gender: string;
  has_health_info: boolean;
  birthdate: string;
  filter_option: {
    gender: string;
    age_group: string;
    age_group_supplement: string;
  };
  agreements: {
    sms: {
      value: boolean;
      updated_at: string;
    };
    email: {
      value: boolean;
      updated_at: string;
    };
    push: {
      value: boolean;
      updated_at: string;
    };
  };
}

interface ConnectAccount {
  sns_type: string;
  email: string;
}

export interface GetUserInfoIn200 {
  data: {
    user_info: UserInfo;
    connect_accounts: ConnectAccount[];
  };
  message: string;
}

export const GET_USER_INFO_KEY = '/user/info';
export const getUserInfo = (options?: SecondParameter<typeof axiosInstanceToFp>) => {
  return axiosInstanceToFp<GetUserInfoIn200>(
    {
      url: GET_USER_INFO_KEY,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
    options,
  );
};
