import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

export function useMutationOrderConfirm() {
  const orderConfirm = async ({
    orderOptions,
    orderIdx,
  }: {
    orderOptions: number;
    orderIdx: string;
  }) => {
    const res = await post(`/api/commerce/v1/orders/${orderIdx}/complete`, {
      order_option_idxes: [orderOptions],
    });
    return res?.data;
  };
  return useMutation({ mutationFn: orderConfirm });
}
