import React, { ReactNode } from 'react';
import { Toaster } from 'react-hot-toast';
import { Toast } from '@components/common/molecules/toast';

import '@/assets/scss/main.scss';

interface StyleProviderProps {
  children?: ReactNode;
}

export const StyleProvider = ({ children }: StyleProviderProps) => {
  return (
    <>
      {children}
      <Toaster
        position="top-center"
        reverseOrder={false}
        children={toast => {
          return <Toast message={toast.message as string} />;
        }}
      />
    </>
  );
};
