import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

export function useMutationUserUpdate() {
  const updateUserData = async (data: {
    nickname?: string;
    email?: string;
    password?: string;
    agreements?: {
      sms?: boolean;
      email?: boolean;
      push?: boolean;
    };
  }) => {
    const response = await post('/user/update', data);
    return response.data;
  };

  return useMutation({
    mutationFn: updateUserData,
  });
}
