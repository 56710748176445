import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

export function useMutationDeleteSingleKeyword() {
  const deleteSingleKeyword = async (keyword: string) => {
    const response = await post('/api/commerce/v1/self/input_kwords/delete-matched', {
      match: keyword,
    });
    return response.data;
  };

  return useMutation({ mutationFn: deleteSingleKeyword });
}
