import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

interface Props {
  qnaBoardTypeCd: string;
  qnaIdx: number;
  form: {
    prdIdx?: number;
    orderOptionIdx?: number;
    qnaTypeCd?: string;
    qnaText?: string;
    openYn?: string;
    notiAnswerYn?: string;
    file?: File;
    removeImgUrl?: string;
  };
}

export function useMutationInquiryModify() {
  const modifyInquiry = async ({ qnaBoardTypeCd, qnaIdx, form }: Props) => {
    const formData = new FormData();
    form?.prdIdx && formData.append('product_idx', String(form?.prdIdx));
    form?.orderOptionIdx && formData.append('order_option_idx', String(form?.orderOptionIdx));
    form?.qnaTypeCd && formData.append('qna_type_cd', form?.qnaTypeCd);
    form?.qnaText && formData.append('qna_text', form?.qnaText);
    form?.openYn && formData.append('open_yn', form?.openYn);
    form?.notiAnswerYn && formData.append('noti_answer_yn', form?.notiAnswerYn);
    form?.file && formData.append('files', form?.file);
    form?.removeImgUrl && formData.append('image_urls_removed', form?.removeImgUrl);

    const response = await post(
      `/api/commerce/v1/boards/${qnaBoardTypeCd}/articles/${qnaIdx}/update`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  };

  return useMutation({ mutationFn: modifyInquiry });
}
