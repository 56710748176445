import { useNavigate } from 'react-router-dom';

import { PATH } from '@/constants/path';

export const NotFoundTemplate = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="empty-list">
        <div className="lines">
          <span>"페이지를 찾을 수 없어요"</span>
          <span className="line"></span>
        </div>
        <div className="sub-text">
          원하시는 페이지를 찾을 수 없어요.
          <br />첫 화면으로 이동해 인기 상품을 만나 보세요!
        </div>
      </div>
      <div className="section-contents">
        <div className="payment-btn-wrap">
          <button
            type="button"
            className="btn btn-lg btn-outline btn-block"
            onClick={() => navigate(PATH.HOME)}
          >
            홈으로 가기
          </button>
        </div>
      </div>
    </div>
  );
};
