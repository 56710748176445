import { ProductList } from '@components/domain/main/product-list';
import { InfiniteData } from '@tanstack/react-query';

import moreBtn from '@/assets/ic/chevron-down-bk.svg';
import { WoProduct } from '@/generated/api/type/data-contracts';

interface PrdListPaginationMobileProps {
  infinitePrdList:
    | InfiniteData<
        {
          data: WoProduct[] | undefined;
          nextPageOffset: number;
          isLast: boolean;
          total: any;
        },
        unknown
      >
    | undefined;
  hasNextPage: boolean;
  handleClickNextPage: () => void;
}
export const PrdListPaginationMobile = ({
  infinitePrdList,
  hasNextPage,
  handleClickNextPage,
}: PrdListPaginationMobileProps) => {
  return (
    <>
      <div id="load-vertical-prd">
        {infinitePrdList?.pages.map((page, pageIdx) => {
          return (
            <ProductList
              key={`mainProductPage${pageIdx}`}
              list={page?.data as WoProduct[]}
              type="vertical"
            />
          );
        })}
      </div>
      <div id="load-pagenation">
        <div className="pagenation-group" id="pagenation">
          {hasNextPage && (
            <button
              type="button"
              className="btn btn-md btn-block btn-outline pagenation-mobile"
              onClick={handleClickNextPage}
            >
              <img src={moreBtn} alt="" />
              상품 더보기
            </button>
          )}
        </div>
      </div>
    </>
  );
};
