import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';

export interface RegisterEmailParams {
  email: string;
  password: string;
  passToken: string;
  agreements: { sms: boolean; email: boolean; push: boolean };
}

export function useMutationRegisterEmail() {
  const signUp = async ({ email, password, passToken, agreements }: RegisterEmailParams) => {
    const response = await post('/user/signup/email', {
      email,
      password,
      pass_token: passToken,
      agreements,
    });
    return response.data;
  };

  return useMutation({
    mutationFn: signUp,
  });
}

export const ERROR_MESSAGE = {
  UNAUTHORIZED: 'PASS 인증이 만료되었습니다.',
  ALREADY_EXIST_EMAIL: '이미 가입된 이메일입니다.',
  INTERNAL_SERVER_ERROR: '서버 오류가 발생했습니다.',
};
