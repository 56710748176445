import { useEffect, useState } from 'react';
import { Sort } from '@components/domain/main/sort';
import { keepPreviousData } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { useFetchProductList } from '@/apis/commerce/products/useFetchProductList';
import { useInfinitelyFetchProductList } from '@/apis/commerce/products/useInfinitelyFetchProductList';

import { PrdListPaginationDesktop } from './prd-list-pagination-desktop';
import { PrdListPaginationMobile } from './prd-list-pagination-mobile';
import { ProductList } from './product-list';

export const MainProductList = () => {
  const isDesktop = window.innerWidth >= 1200;

  const [offset, setOffset] = useState(0); // index
  const [order, setOrder] = useState({
    title: '판매인기순',
    value: { orderColumn: 'sale_count', order: 'desc' },
  });

  const limit = window.innerWidth >= 905 ? 20 : 12;
  const initParams = {
    type: 'group_buying',
    value: order.value,
    offset: 0,
    limit,
  };

  const { data: productList, isFetched: isPrdListFetched } = useFetchProductList({
    ...initParams,
    value: order.value,
    offset,
    options: { enabled: !!initParams, placeholderData: keepPreviousData },
  });

  const {
    data: infinitePrdList,
    fetchNextPage,
    hasNextPage,
  } = useInfinitelyFetchProductList({
    initialPageParam: 0,
    limit,
    value: order.value,
    collectionTypeCode: initParams.type,
  });

  if ((!productList || productList?.products === undefined) && isDesktop) return <></>;

  const handleChange = (value: {
    title: string;
    value: { orderColumn: string; order: string };
  }) => {
    setOrder(value);
  };

  const totalCount = isDesktop
    ? productList?.total_count ?? 0
    : infinitePrdList?.pages[0]?.total ?? 0;

  const handleClickNextPage = async () => {
    await fetchNextPage();
  };

  return (
    <section>
      {(productList || infinitePrdList) && (
        <Sort total={totalCount} selected={order.title} handleClickSort={handleChange} />
      )}
      {/* <Title title="전체 제품" /> */}
      {isDesktop ? (
        productList?.products !== undefined && (
          <>
            <ProductList list={productList?.products} type="vertical" />
            <PrdListPaginationDesktop
              setOffset={setOffset}
              limit={limit}
              total={productList?.total_count ?? 0}
            />
          </>
        )
      ) : (
        <PrdListPaginationMobile
          infinitePrdList={infinitePrdList}
          hasNextPage={hasNextPage}
          handleClickNextPage={handleClickNextPage}
        />
      )}
    </section>
  );
};
