import React, { ChangeEvent } from 'react';

import { WITHDRAWAL_REASONS } from '@/constants/withdrawal';

interface Step1Props {
  selectedReason: string;
  textareaValue: string;
  handleChangeReason: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangeTextarea: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  handleBtnClick: () => void;
}
export const Step1 = ({
  selectedReason,
  textareaValue,
  handleChangeReason,
  handleChangeTextarea,
  handleBtnClick,
}: Step1Props) => {
  return (
    <div className="section-contents">
      <div className="form-title-wrap">
        <div className="form-title">
          서비스를 이용하면서
          <br />
          불편한 점이 있었나요?
        </div>
        <div className="form-sub-text">
          서비스를 떠나는 이유를 알려주시겠어요?
          <br />
          회원님의 소중한 의견을 반영하여 더 나은 서비스를 위해 노력하겠습니다.
        </div>
      </div>
      <ul>
        {WITHDRAWAL_REASONS.map(reason => (
          <li className="list-item" key={reason}>
            <label className="radio-group">
              <input
                type="radio"
                value={reason}
                onChange={handleChangeReason}
                checked={selectedReason === reason}
              />
              <span className="label">{reason}</span>
              <span className="checkmark" />
            </label>
          </li>
        ))}
      </ul>
      {/* <!-- 기타 눌렀을 때 textarea 추가됨 --> */}
      {selectedReason === '기타' && (
        <div className="input-group">
          <textarea
            className="form-control"
            placeholder="최소 10자 이상 작성"
            onChange={handleChangeTextarea}
            value={textareaValue}
          />
          <div className="view-words">
            <span className="value">{textareaValue.length}</span>/1000
          </div>
        </div>
      )}
      {/* <!-- 데스크탑에서만 나오는 버튼 --> */}
      <div className="centered-desktop-action">
        <button
          type="button"
          className="btn btn-lg btn-filled"
          onClick={handleBtnClick}
          disabled={
            (selectedReason === '기타' && textareaValue.length < 10) || selectedReason.length === 0
          }
        >
          다음으로
        </button>
      </div>
    </div>
  );
};
