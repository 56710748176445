interface Step2Props {
  hasReadALL: boolean;
  hasReadAllToggle: (checked: boolean) => void;
  handleBtnClick: () => void;
}
export const Step2 = ({ hasReadALL, hasReadAllToggle, handleBtnClick }: Step2Props) => {
  return (
    <div className="section-contents">
      <div className="form-title-wrap">
        <div className="form-title">안내 사항을 확인해주세요!</div>
        <div className="form-sub-text">
          <ul className="info-list-group">
            <li className="list-item">
              계정을 삭제하면 친한스토어 서비스의 모든 내역이 삭제됩니다.
            </li>
            <li className="list-item">삭제된 정보는 복구할 수 없으니 신중하게 결정해주세요</li>
            <li className="list-item">
              <span>탈퇴 후 30일</span> 내에 동일 아이디와 동일 휴대폰 번호로는{' '}
              <span>재가입이 불가</span> 합니다.
            </li>
            <li className="list-item">
              회원 탈퇴 시, 보유하고 계신 <span>포인트와 쿠폰 등은 소멸</span> 되며 복원되지
              않습니다.
            </li>
            <li>
              진행 중인 전자상거래 이용 내역이 있는 경우 또는 고객 상담 및 이용하신 서비스가
              완료되지 않은 경우 탈퇴가 불가능합니다.
            </li>
          </ul>
        </div>
        <div>
          <label className="checkbox-group green-box">
            <input
              type="checkbox"
              name="test-1"
              checked={hasReadALL}
              onChange={e => hasReadAllToggle(e.target.checked)}
            />
            <span className="label">모두 확인했어요.</span>
            <span className="checkmark" />
          </label>
        </div>
        <button
          type="button"
          className="btn btn-lg btn-filled"
          disabled={!hasReadALL}
          onClick={handleBtnClick}
        >
          회원 탈퇴하기
        </button>
        {/* <!-- 데스크탑에서만 나오는 버튼 --> */}
        <div className="centered-desktop-action">
          <button
            type="button"
            className="btn btn-lg btn-filled"
            disabled={!hasReadALL}
            onClick={handleBtnClick}
          >
            회원 탈퇴하기
          </button>
        </div>
      </div>
    </div>
  );
};
