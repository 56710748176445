import { ReactNode } from 'react';

import { MixpanelProvider } from '@/providers/analytics-provider/mixpanel-provider';

interface AnalyticsProviderProps {
  children: ReactNode;
}
export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  return <MixpanelProvider>{children}</MixpanelProvider>;
};
