import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AUTH_KAKAO_ACCESS_TOKEN, AUTH_PASS_KEY } from '@/constants/auth';
import { useSearchUrl } from '@/hooks/use-search-url';

interface UseAuthCallbackProps {
  onSuccessKakaoLoginToApiRequest: (accessToken?: string) => void;
  connectCert: (mdl_tkn: string) => void;
}

/**
 * 카카오, 패스 인증을 하고 콜백페이지에서 전달된 성공데이터를 가지고
 * 로직을 수행한다.
 * 사용처에서 useAuth 훅에 있는 파라미터를 그대로 전달합니다.
 *
 * @param onSuccessKakaoLoginToApiRequest
 * @param connectCert
 */
export const useAuthCallback = ({
  onSuccessKakaoLoginToApiRequest,
  connectCert,
}: UseAuthCallbackProps) => {
  const location = useLocation();
  const { setStateBySearchParams } = useSearchUrl();
  const mdl_tkn = sessionStorage.getItem(AUTH_PASS_KEY);

  useEffect(() => {
    if (mdl_tkn) {
      setStateBySearchParams([{ key: 'mdl_tkn', value: mdl_tkn }], location.state);
      connectCert(mdl_tkn);
    }
  }, [mdl_tkn]);

  const kakao_access_token = sessionStorage.getItem(AUTH_KAKAO_ACCESS_TOKEN);
  useEffect(() => {
    if (kakao_access_token) {
      onSuccessKakaoLoginToApiRequest(kakao_access_token);
    }
  }, [kakao_access_token]);
};
