/**
 * 트래킹 조회 팝업
 * @param companyCode
 * @param trackingNum
 */
export const handleClickTracking = (companyCode: string, trackingNum: string) => {
  const trackingForm = document.createElement('form');
  trackingForm.setAttribute('action', 'https://info.sweettracker.co.kr/tracking/5');
  trackingForm.setAttribute('method', 'post');

  const inputApiKey = document.createElement('input');
  inputApiKey.setAttribute('type', 'text');
  inputApiKey.setAttribute('name', 't_key');
  inputApiKey.value = process.env.REACT_APP_TRACKING_API_KEY ?? '';
  trackingForm.appendChild(inputApiKey);

  const inputCompanyCode = document.createElement('input');
  inputCompanyCode.setAttribute('type', 'text');
  inputCompanyCode.setAttribute('name', 't_code');
  inputCompanyCode.value = companyCode;
  trackingForm.appendChild(inputCompanyCode);

  const inputInvoiceNumber = document.createElement('input');
  inputInvoiceNumber.setAttribute('type', 'text');
  inputInvoiceNumber.setAttribute('name', 't_invoice');
  inputInvoiceNumber.value = trackingNum;
  trackingForm.appendChild(inputInvoiceNumber);

  document.body.appendChild(trackingForm);

  const newWindow = window.open(
    '',
    'auth-form',
    `width=${window.innerWidth * 0.5},height=${
      window.innerHeight * 0.5
    },toolbar=0,menubar=0,location=0,scrollbar=1,resizable=0,left=0,top=0`,
  );

  (newWindow as Window).document.body.appendChild(trackingForm);
  trackingForm.submit();
};
/**
 * isEmpty
 * @param {mixed} input
 * @return {bool}
 */
const isEmpty = (input: any) => !input || Object.keys(input).length === 0;

/**
 * getStorage
 * @param {*} storage
 * @param {*} key
 */
export const getStorage = (storage: Storage, key: string) => {
  if (isEmpty(key)) {
    return null;
  }
  const returnValue = storage.getItem(key);

  if (isEmpty(returnValue)) {
    return null;
  }

  const { data, expire } = JSON.parse(returnValue as any);

  if (expire !== 0 && expire < Date.now()) {
    storage.removeItem(key);
    return null;
  }

  return data;
};

/**
 * get storage
 * @param {string} key
 * @return {string || any}
 */
export const getLocalStorage = (key: string) => {
  return getStorage(localStorage, key);
};
export const getSessionStorage = (key: string) => {
  return getStorage(sessionStorage, key);
};

/**
 * setStorage
 * @param {*} storage
 * @param {*} key
 * @param {*} value
 * @param {number} expireSeconds (0 = 만료 체크를 하지 않음)
 */
export const setStorage = (storage: Storage, key: string, value: any, expireSeconds = 0) => {
  if (isEmpty(key) || isEmpty(value)) {
    return false;
  }

  const data = {
    data: value,
    expire: expireSeconds === 0 ? 0 : Date.now() + expireSeconds * 1000,
  };

  storage.setItem(key, JSON.stringify(data));
  return true;
};

/**
 * set localstorage
 * @param {string} key
 * @param {string || any} value
 * @param {number} expireSeconds (0 = 만료 체크를 하지 않음)
 * @return {bool}
 */
export const setLocalStorage = (key: string, value: any, expireSeconds = 0) => {
  return setStorage(localStorage, key, value, expireSeconds);
};
/**
 * set sessionstorage
 * @param {string} key
 * @param {string || any} value
 * @param {number} expireSeconds (0 = 만료 체크를 하지 않음)
 * @return {bool}
 */
export const setSessionStorage = (key: string, value: any, expireSeconds = 0) => {
  return setStorage(sessionStorage, key, value, expireSeconds);
};

/**
 * 만료된 localStorage 삭제
 */
export const removeExpiredLocalStorage = () => {
  if (!localStorage || !localStorage.length) {
    return false;
  }

  Object.keys(localStorage).forEach(key => {
    const value = localStorage.getItem(key);
    if (isEmpty(value)) {
      // skip item
      return;
    }

    try {
      const { expire } = JSON.parse(value as any);
      if (expire !== 0 && expire < Date.now()) {
        localStorage.removeItem(key);
      }
    } catch (e) {}
  });
};

/**
 * remove localstorage
 * @param {string} key
 * @return {bool}
 */
export const removeLocalStorage = (key: string) => {
  if (isEmpty(key)) {
    return false;
  }
  localStorage.removeItem(key);
  return true;
};

/**
 * remove sessionstorage
 * @param {string} key
 * @return {bool}
 */
export const removeSessionStorage = (key: string) => {
  if (isEmpty(key)) {
    return false;
  }
  sessionStorage.removeItem(key);
  return true;
};

export const isMobile = window.innerWidth < 905;

export const returnVoid = () => {};

export const storeProductionHost = 'store.friendly-pharmacist.com';
const closureProductionHost = 'closure.store.friendly-pharmacist.com';

export const isProduction =
  (process.env.REACT_APP_PATH === 'closure' && window.location.host === closureProductionHost) ||
  window.location.host === storeProductionHost;
