import { MouseEvent } from 'react';

import useMyInfo from '@/hooks/use-my-info';
import { ToastData } from '@/types/toast';
import { UpdateUserDataParams } from '@/types/user';

interface UserEmailProps {
  email: string;
  modifyStatus: string;
  handleUpdateUserData: (data: UpdateUserDataParams, toastData: ToastData) => void;
  handleModifyNicknameBtnClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const UserEmail = ({
  email,
  modifyStatus,
  handleUpdateUserData,
  handleModifyNicknameBtnClick,
}: UserEmailProps) => {
  const { isEmailValid, handleChange, values } = useMyInfo();
  return (
    <li className="myInfo-forms-item">
      <div className="label">이메일</div>
      {modifyStatus === 'email' ? (
        <div className="forms-group">
          <div className="input-group">
            <input
              type="email"
              className={`form-control${isEmailValid ? '' : ' has-validation'}`}
              name="email"
              onChange={handleChange}
              value={values.email}
            />
            {!isEmailValid && <div className="form-text">이메일을 확인하세요.</div>}
          </div>
          <button
            name="email"
            type="button"
            className="btn btn-md btn-filled"
            disabled={!isEmailValid}
            onClick={() =>
              handleUpdateUserData(
                { email: values.email },
                { message: '이메일을 수정했어요', toastType: 'checked' },
              )
            }
          >
            수정
          </button>
        </div>
      ) : (
        <div className="input-group inline nickname">
          <div className="text no-link">{email}</div>
          <button
            type="button"
            className="btn btn-sm btn-outline"
            name="email"
            onClick={handleModifyNicknameBtnClick}
          >
            수정
          </button>
        </div>
      )}
    </li>
  );
};
