import { createContext, FC, ReactNode, useCallback, useEffect, useReducer } from 'react';

import { type WoProductOption } from '@/generated/api/type/data-contracts';

interface ProductDispatch {
  addOption: (selectedOption: SelectedOption) => void;
  removeOption: ({ optionIdx }: { optionIdx: number }) => void;
  optionEmpty: () => void;
  optionCounter: (selectedOption: SelectedOption, type: 'increase' | 'decrease') => void;
  setToastState: (message: string) => void;
  closeToast: () => void;
  totalSelectedPrice: number;
}

type Action =
  | { type: 'OPTION_EMPTY'; payload: ProductState }
  | { type: 'OPTION_ADD'; payload?: SelectedOption }
  | { type: 'OPTION_REMOVE'; payload?: { optionIdx: number } }
  | {
      type: 'OPTION_COUNTER';
      payload?: { selectedOption: SelectedOption; type: 'increase' | 'decrease' };
    }
  | {
      type: 'UPDATE_TOTAL_PRICE';
    }
  | {
      type: 'TOAST_UPDATE';
      payload: string;
    }
  | {
      type: 'TOAST_CLOSE';
    };

export interface SelectedOption extends WoProductOption {
  optionCount: number;
}
interface ProductState {
  selectedOptions: SelectedOption[] | [];
  totalSelectedPrice: number;
  toastState: { message: string; isShow: boolean };
}

export const ProductStateContext = createContext<ProductState | undefined>(undefined);
export const ProductDispatchContext = createContext<ProductDispatch | undefined>(undefined);

function reducer(state: ProductState, action: Action): ProductState {
  switch (action.type) {
    case 'OPTION_EMPTY':
      return action.payload;

    case 'OPTION_ADD': {
      if (!action?.payload?.product_option_idx) {
        return state;
      }
      const newList = state?.selectedOptions.filter(
        option => option?.product_option_idx !== action?.payload?.product_option_idx,
      );
      newList.push(action.payload);

      return { ...state, selectedOptions: newList };
    }

    case 'OPTION_REMOVE':
      state.selectedOptions = state.selectedOptions.filter(
        option => option.product_option_idx !== action.payload?.optionIdx,
      );
      return { ...state };

    case 'OPTION_COUNTER': {
      const counter = (curr: number) => (action.payload?.type === 'increase' ? curr + 1 : curr - 1);

      const updatedOptions = state.selectedOptions.map(option =>
        option.product_option_idx === action.payload?.selectedOption.product_option_idx
          ? { ...option, optionCount: counter(option.optionCount) }
          : option,
      );

      return {
        ...state,
        selectedOptions: updatedOptions,
      };
    }

    case 'UPDATE_TOTAL_PRICE': {
      const totalSelectedPrice = (state.selectedOptions as SelectedOption[]).reduce(
        (acc: number, curr) => {
          return curr.sale_price !== undefined
            ? acc +
                (curr.discounted_influencer_price ?? curr.discounted_price ?? curr.sale_price) *
                  curr.optionCount
            : acc;
        },
        0,
      );

      return {
        ...state,
        totalSelectedPrice,
      };
    }
    case 'TOAST_UPDATE': {
      return {
        ...state,
        toastState: { message: action.payload, isShow: true },
      };
    }
    case 'TOAST_CLOSE': {
      return {
        ...state,
        toastState: { message: '', isShow: false },
      };
    }

    default:
      throw new Error('No action specified');
  }
}

export const ProductProvider: FC<{
  children?: ReactNode;
  id?: string;
}> = ({ children }) => {
  const initialState: ProductState = {
    selectedOptions: [],
    totalSelectedPrice: 0,
    toastState: { message: '', isShow: false },
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const optionEmpty = useCallback(() => {
    dispatch({ type: 'OPTION_EMPTY', payload: initialState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addOption = useCallback((selectedOption: SelectedOption) => {
    dispatch({ type: 'OPTION_ADD', payload: selectedOption });
  }, []);

  const removeOption = useCallback(({ optionIdx }: { optionIdx: number }) => {
    dispatch({ type: 'OPTION_REMOVE', payload: { optionIdx } });
  }, []);
  const optionCounter = useCallback(
    (selectedOption: SelectedOption, type: 'increase' | 'decrease') => {
      dispatch({ type: 'OPTION_COUNTER', payload: { selectedOption, type } });
    },
    [],
  );
  const setToastState = useCallback((message: string) => {
    dispatch({ type: 'TOAST_UPDATE', payload: message });
  }, []);
  const closeToast = useCallback(() => {
    dispatch({ type: 'TOAST_CLOSE' });
  }, []);

  useEffect(() => {
    dispatch({ type: 'UPDATE_TOTAL_PRICE' });
  }, [state.selectedOptions]);

  return (
    <ProductStateContext.Provider value={state}>
      <ProductDispatchContext.Provider
        value={{
          addOption,
          removeOption,
          optionCounter,
          optionEmpty,
          setToastState,
          closeToast,
          totalSelectedPrice: state.totalSelectedPrice,
        }}
      >
        {children}
      </ProductDispatchContext.Provider>
    </ProductStateContext.Provider>
  );
};
