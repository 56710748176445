import { ChangeEvent } from 'react';

interface LoginFormProps {
  keepLoginState: boolean;
  loginValue: { email: string; password: string };
  handleLoginWithEmail: (email: string, password: string) => void;
  handleChangeValue: (e: ChangeEvent<HTMLInputElement>) => void;
  handleKeepLoginState: (e: ChangeEvent<HTMLInputElement>) => void;
}
export const LoginForm = ({
  keepLoginState,
  loginValue,
  handleLoginWithEmail,
  handleChangeValue,
  handleKeepLoginState,
}: LoginFormProps) => {
  return (
    <>
      <div className="input-group">
        <input
          type="email"
          name="email"
          className="form-control"
          placeholder="이메일"
          onChange={handleChangeValue}
        />
      </div>
      <div className="input-group">
        <input
          type="password"
          name="password"
          className="form-control"
          placeholder="비밀번호"
          onChange={handleChangeValue}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleLoginWithEmail(loginValue.email, loginValue.password);
            }
          }}
        />
      </div>
      <div className="input-group checkbox-input-group">
        <label className="checkbox-group green-box sm-box">
          <input
            type="checkbox"
            name="test-1"
            onChange={handleKeepLoginState}
            checked={keepLoginState}
          />
          <span className="label">로그인 유지</span>
          <span className="checkmark"></span>
        </label>
      </div>
      <div className="action-group">
        <button
          type="button"
          className="btn btn-md btn-filled btn-block"
          onClick={() => handleLoginWithEmail(loginValue.email, loginValue.password)}
        >
          로그인
        </button>
      </div>
    </>
  );
};
