import { MouseEvent, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { InquiryModal } from '@components/common/molecules/modal/inquiry-modal';
import { Toast } from '@components/common/molecules/toast';
import { OptionDropdown } from '@components/domain/product/common/option-dropdown';
import { OrderBtnBox } from '@components/domain/product/common/order-btn-box';
import { SelectedOptionItem } from '@components/domain/product/common/selected-option-item';

import chatIc from '@/assets/ic/chat.svg';
import shareIc from '@/assets/ic/share.svg';
import { PATH } from '@/constants/path';
import { type WoDetailedProduct } from '@/generated/api/type/data-contracts';
import useLoginInfo from '@/hooks/use-login-info';
import { useProduct } from '@/hooks/use-product';
import { isMobile } from '@/utils';
import { numberFormat } from '@/utils/formats';

interface ProductTopProps {
  productDetail?: WoDetailedProduct;
  handleCopyUrl: (e: MouseEvent<HTMLAnchorElement>) => void;
  onSuccessAddingCart: (title: string, duration: number) => void;
  handleClickTab: (tab: string) => void;
}
/**
 * 상품 상세 상단
 */
export const ProductTop = ({
  productDetail,
  handleCopyUrl,
  onSuccessAddingCart,
  handleClickTab,
}: ProductTopProps) => {
  const topRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { userToken } = useLoginInfo();
  const { productContext, productDispatch } = useProduct();
  const [isInquiryModalOpen, setIsInquiryModalOpen] = useState(false);
  const handleClickInquiry = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    userToken ? setIsInquiryModalOpen(true) : navigate(PATH.LOGIN);
  };
  const [mainThumbnail, setMainThumbnail] = useState(0);
  const handleClickThumbnail = (index: number) => {
    setMainThumbnail(index);
  };

  const scrollToReview = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    handleClickTab('리뷰');
    const refHeight = topRef.current?.offsetHeight;
    window.scrollTo({ top: refHeight, behavior: 'smooth' });
  };

  return (
    <>
      <div className="product-detail-top" ref={topRef}>
        <div className="thumbnail-col">
          <div className="thumbnail">
            {isMobile ? (
              <Swiper
                slidesPerView={1}
                loop={true}
                direction="horizontal"
                modules={[Pagination]}
                className="swiper-container"
                pagination={{ type: 'fraction', el: '.thumbnail-mobile-pagenation' }}
              >
                {productDetail?.product_images?.map((img, i) => (
                  <SwiperSlide key={`thumbnameImg${i}`}>
                    <img src={img} alt="main" />
                  </SwiperSlide>
                ))}
                <div className="thumbnail-mobile-pagenation">
                  <span className="fraction" />
                </div>
              </Swiper>
            ) : (
              <img src={productDetail?.product_images?.[mainThumbnail]} alt="main" />
            )}
            {productDetail?.sold_out_yn === 'Y' && <div className="thumbnail-dim" />}
          </div>
          <ul className="thumbnail-desktop-group">
            {productDetail?.product_images?.map((img, i) => (
              <li
                className={`list-item${mainThumbnail === i ? ' active' : ''}`}
                key={`thumbnameImg${i}`}
                onClick={() => handleClickThumbnail(i)}
              >
                <Link to="#">
                  <img src={img} alt="sub" />
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="information">
          <div className="title-wrap">
            <Link to="#" className="store" onClick={e => e.preventDefault()}>
              {productDetail?.brand_name}
            </Link>
            <div className="title">{productDetail?.product_name}</div>
            <div className="badge-group">
              {productDetail?.shipping_fee_text === '무료배송' && (
                <span className="badge">무료배송</span>
              )}
              {productDetail?.point_earning_rate !== undefined &&
                productDetail?.point_earning_rate > 0 && <span className="badge">적립금</span>}
              {/* <span className="badge">사은품</span> */}
            </div>
            <div className="review-group">
              <div className="review-point">{productDetail?.review_score_average ?? 0}</div>
              <Link className="reviews" to="#" onClick={scrollToReview}>
                {numberFormat(productDetail?.review_count ?? 0)}
              </Link>
            </div>
          </div>

          {productDetail?.product_options?.[0]?.discount_influencer_rate ? (
            // influencer price
            <ul className="with-code">
              <li className="pricing-wrap with-avatar">
                <div className="discount-percent">
                  {productDetail?.product_options?.[0]?.discount_influencer_rate}%
                </div>
                <div className="pricing">
                  {numberFormat(productDetail?.product_options?.[0]?.discounted_influencer_price)}
                  <span className="unit">원</span>
                  <span
                    style={{
                      fontWeight: 700,
                      color: 'var(--gray-400)',
                      textDecoration: 'line-through',
                    }}
                  >
                    {numberFormat(productDetail?.sale_price ?? 0)}
                    <span className="unit">원</span>
                  </span>
                </div>
              </li>
            </ul>
          ) : (
            // normal price
            <div className="pricing-wrap">
              {productDetail?.discount_rate !== undefined && productDetail?.discount_rate > 0 && (
                <div className="discount-percent">{productDetail?.discount_rate}%</div>
              )}
              <div className="pricing">
                {numberFormat(productDetail?.discounted_price ?? productDetail?.sale_price ?? 0)}
                <span className="unit">원</span>
              </div>
              <div className="prev-price">
                {/* 정가 */}
                {numberFormat(productDetail?.sale_price ?? 0)}
                <span className="unit">원</span>
              </div>
            </div>
          )}
          {/*  데스크탑에서만 노출됨  */}
          {productDetail && (
            <div className="product-option-wrap">
              <OptionDropdown productDetail={productDetail} located="web_top" />
              {/* //FIXME: 옵션 리스트 클래스명 변경 --> */}
              <SelectedOptionItem />
              <OrderBtnBox
                options={productDetail?.product_options}
                isSoldOut={productDetail?.sold_out_yn === 'Y'}
                onSuccessAddingCart={onSuccessAddingCart}
                located="web_top"
              />
              <ShippingInfo productDetail={productDetail} />
            </div>
          )}
          {/* <!-- 이벤트 문구 --> */}
          {/* <div className="callout"> 가정의 달 기획전! 지금 YDY 전 제품 최대 50% 할인</div> */}
        </div>
        {/* // TODO  */}
        <div className="interaction">
          <Link to="#" onClick={handleClickInquiry}>
            <img src={chatIc} alt="문의" />
            <span>문의</span>
          </Link>
          <Link to="#" onClick={handleCopyUrl}>
            <img src={shareIc} alt="공유" />
            <span>공유</span>
          </Link>
        </div>
        {productContext?.toastState?.isShow && (
          <Toast
            message={productContext?.toastState?.message}
            handleClickClose={() => productDispatch?.closeToast()}
          />
        )}
      </div>
      {isInquiryModalOpen && (
        <div>
          <InquiryModal
            isOpen={isInquiryModalOpen}
            toggleModal={() => setIsInquiryModalOpen(false)}
            productItem={productDetail}
            qnaBoardTypeCd="product"
          />
        </div>
      )}
    </>
  );
};

interface ShippingInfoProps {
  productDetail: WoDetailedProduct;
}
const ShippingInfo = ({ productDetail }: ShippingInfoProps) => {
  return (
    <div className="shipping-info">
      <div className="row">
        <div className="header">배송정보</div>
        <div className="contents">
          <div>{productDetail?.shipping_info}</div>
          <div>
            {productDetail?.shipping_fee_text}
            <br />※ 제주/도서 산간 지역 추가 배송비 (
            {numberFormat(productDetail?.shipping_additional_fee_jeju)}원)
          </div>
          <div>{productDetail?.shipping_comcode_name}</div>
        </div>
      </div>
    </div>
  );
};
