import { HelpNoticeTemplate } from '@components/template/help/notice';

/**
 * 공지사항 페이지 (/help/notice)
 *
 * ? TODO: Index ?? parameter 안받음
 */
export const HelpNoticePage = () => {
  return <HelpNoticeTemplate />;
};
